const fr = {
  language: 'fr',
  userRoles: {
    admin: 'Administrateur',
    planner: 'Planificateur',
    read_only_planner: 'Planificateur en lecture seule',
    plus_planner: 'Rôle Planificateur avancé',
    partner: 'Partenaire',
    customer: 'Client',
    customs_agent: 'Agent des douanes',
    handler: 'Manipulateur'
  },
  emptyStates: { notFoundTitle: 'Aucun résultat trouvé !' },
  application: {
    requiredLocation: 'Le lieu est obligatoire',
    recipient: 'Destinataire',
    save: 'Enregistrer',
    update: 'Mettre à jour',
    required: '* Champ obligatoire',
    requiredShort: '* Obligatoire',
    search: 'Recherche en cours...',
    lastUpdated: 'Dernière mise à jour',
    logOut: 'Déconnexion',
    proceed: 'Continuer',
    courier: 'Coursier',
    couriers: 'Coursiers',
    consignments: 'Expéditions',
    status: 'Statut',
    address: 'Adresse',
    estimated: 'Estimé(e)',
    adjusted: 'Ajusté(e)',
    actual: 'Réel(le)',
    funeral: 'Funérailles',
    tracking: 'Suivi',
    trackingPage: 'Page de suivi de destinataire',
    delivery: 'Livraison',
    phoneNumberShort: 'Téléphone',
    yes: 'Oui',
    no: 'Non',
    notSelected: 'Non sélectionné',
    httpErrorToString: 'Erreur HTTP : code {{status}} {{statusText}}',
    anErrorOccurred: "Une erreur s'est produite !",
    noAccessTitle: "Pas d'accès",
    noAccessMessage: "Vous n'avez pas le droit d'utiliser cette fonctionnalité",
    autoComplete: 'Rechercher des lieux...',
    autoCompleteWithoutDots: 'Rechercher des lieux',
    totalWeight: 'Poids total',
    totalVolume: 'Volume total',
    validDeviationCode: 'Veuillez entrer une valeur valide',
    backToNewSite: 'Retour au nouveau site',
    title: {
      root: 'Bienvenue',
      planner: 'Planificateur',
      plannerLive: 'Planificateur - En direct',
      plannerLiveSlot: 'Planificateur - En direct - Tournée',
      plannerCouriers: 'Planificateur - Coursiers',
      plannerUnits: 'Planificateur - Unités',
      plannerImport: 'Planificateur - Importation',
      plannerUnresolved: 'Planificateur - Non résolu(e)',
      plannerConsignments: 'Planificateur - Livraisons',
      plannerSearch: 'Planificateur - Recherche',
      plannerPlanning: 'Planificateur - Planification',
      plannerInvoicing: 'Planificateur - Facturation',
      plannerVehicle: 'Planificateur - Vehicles',
      report: 'Rapports',
      customerDepartmentLive: 'Affichage en direct',
      recipient: 'Suivi de colis - suivi - Bring',
      instantBooking: 'Réservation',
      h2Booking: 'Réservation H2',
      orderConfirmation: 'Confirmation de commande',
      customerPortal: 'Portail Clients',
      instantMap: 'Carte instantanée',
      recurringOrder: 'Commandes récurrentes',
      corrections: 'Corrections de commande',
      jobs: 'Surveillance des tâches',
      holidays: 'Jours fériés',
      shipmentLabels: "Étiquettes d'expédition",
      addresses: 'Adresses',
      airexpressBooking: 'Réservation Air Express',
      airExpressBookingConfirmation: 'Confirmation de commande',
      airexpressImportData: 'Importation SSIM',
      airexpressExportData: 'Exporter les données AirExpress',
      airexpressAirports: 'Aéroports',
      airexpressAirlines: 'Compagnies aériennes',
      airexpressPreadvice: 'Avis préliminaire',
      airexpressShipments: 'Expéditions Air Express',
      airexpressManifest: 'Manifestes Air Express',
      airexpressAdminHandlerUsers: 'Utilisateurs manutentionnaire Air Express',
      airexpressLocallyAddedFlightSchedules: 'Modifier les horaires de vol',
      airexpressAdminSearchFlights: 'Recherche de vol',
      airexpressRecurringOrder: 'Air Express Commandes récurrentes',
      airexpressInvoicing: 'Air Express Facturation',
      routeReceiptList: 'Liste des signatures',
      loadList: 'Liste de chargement',
      airexpressAdminTerminals: 'Terminaux',
      manageReturns: 'Gérer les retours',
      preAdviceFollowup: 'Gérer les préavis'
    },
    language: { en: 'Anglais', nb: 'Norvégien', sv: 'Suédois', dk: 'Danois', fi: 'Finlandais', fr: 'Français' },
    country: {
      AllCountry: 'Tous les pays',
      Country: 'Pays',
      AD: 'Andorre',
      AT: "L'Autriche",
      BE: 'la Belgique',
      BA: 'Bosnie Herzégovine',
      BG: 'Bulgarie',
      HR: 'Croatie',
      CZ: 'Tchéquie',
      DK: 'Danemark',
      GB: 'Royaume-Uni',
      EE: 'Estonie',
      FO: 'Îles Féroé',
      FI: 'Finlande',
      FR: 'La France',
      DE: 'Allemagne',
      GR: 'Grèce',
      HU: 'Hongrie',
      IS: 'Islande',
      IE: 'Irlande',
      IT: 'Italie',
      LV: 'Lettonie',
      LT: 'Lituanie',
      MK: 'Macédoine du Nord',
      MT: 'Malte',
      ME: 'Monténégro',
      NL: 'Pays-Bas',
      NO: 'Norvège',
      PL: 'Pologne',
      PT: 'le Portugal',
      RO: 'Roumanie',
      RU: 'Russie',
      SM: 'Saint Marin',
      RS: 'Serbie',
      SK: 'Slovaquie',
      SI: 'Slovénie',
      ES: 'Espagne',
      SE: 'la Suède',
      CH: 'la Suisse'
    },
    timeDistance: 'Heure et distance prévues',
    driverReturnToAddress: "Ne pas préciser d'adresse d'expéditeur",
    plannedTime: 'Heure prévue',
    plannedDistance: 'Distance prévue',
    plannedWeight: 'Poids prévu',
    noOptions: 'Aucune option',
    appBuild: "Compilation de l'appli",
    lastLogin: 'Dernière connexion',
    device: 'Appareil',
    model: 'Modèle',
    os: 'OS',
    units: 'Unités',
    helpLink: "(Qu'est-ce que c'est?)",
    groups: 'Groupes',
    departments: 'Départements',
    selectAll: 'Sélectionner tout'
  },
  serviceLevel: {
    label: 'Réduire le niveau de service',
    notSet: 'Non établi(e)',
    none: 'Désactivé(e)',
    premium: 'Premium'
  },
  simpleSearch: { link: 'Lien' },
  dropdown: {
    selectedCustomers: '{{count}} client',
    selectedCustomers_plural: '{{count}} clients',
    allCustomers: 'Tous les clients',
    filterCustomers: 'Filtrer les clients',
    selectedDepartments: '{{count}} département',
    selectedDepartments_plural: '{{count}} départements',
    allDepartments: 'Tous les départements',
    filterDepartments: 'Filtrer les départements',
    selectedServiceCodes: '{{count}} service',
    selectedServiceCodes_plural: '{{count}} services',
    allServiceCodes: 'Tous les services',
    filterServiceCodes: 'Filtrer les services',
    selectedVasCodes: '{{count}} vas',
    selectedVasCodes_plural: '{{count}} vases',
    allVasCodes: 'Tous les vases',
    filterVasCodes: 'Filtrer les vases',
    selectedDestinations: '{{count}} destination',
    selectedDestinations_plural: '{{count}} destinations'
  },
  button: {
    edit: 'Modifier',
    back: 'Retour',
    cancel: 'Annuler',
    done: 'Terminé',
    clear: 'Effacer',
    clearAll: 'Tout effacer',
    close: 'Fermer',
    save: 'Enregistrer',
    delete: 'Supprimer',
    export: 'Exporter',
    new: 'Créer nouveau',
    search: 'Rechercher',
    add: 'Ajouter',
    remove: 'Supprimer',
    assign: 'Affecter',
    fullEdit: 'Modification complète',
    confirm: 'Confirmé',
    continue: 'Continuer'
  },
  consignment: {
    includePreAdvice: 'Avis préliminaire',
    onlyFailedPreAdvice: "Échec de l'avis préliminaire",
    onlyReturnPreAdvice: "Retourner à l'expéditeur",
    consignmentDetails: 'Détails de colis',
    search: "Recherche d'expédition",
    noShipmentsFound: 'Aucune expédition trouvée',
    clickButtonToSearch: 'Cliquez sur le bouton Recherche pour lancer la recherche',
    consignmentsForDate: 'Expéditions {{date}}',
    showOnlyEditable: 'Seulement modifiable',
    showOnlyNotAssigned: 'Non affecté(e)',
    withoutDate: 'Sans date',
    extendedSearch: 'Recherche étendue',
    showDeleted: 'Afficher les éléments supprimés',
    showDeviated: 'Afficher les échecs',
    deletedBy: 'Supprimé par',
    deletionReason: 'Raison',
    numberOfConsignments: '{{number}} expéditions trouvées',
    showAll: 'Tout',
    delivery: 'Livraison',
    pickup: 'Enlèvement',
    pickups: '{{count}} enlèvement',
    pickups_plural: '{{count}} enlèvements',
    edit: 'Modifier',
    deleteOrders: 'Supprimer',
    duplicateOrder: 'Dupliquer',
    duplicateConfirmation: 'Voulez-vous vraiment dupliquer cette commande ?',
    orderDuplicated: 'La commande a été dupliquée',
    orderDuplicatedFailed: 'Impossible de dupliquer la commande',
    copyOrder: 'Copier',
    editOrder: 'Modifier',
    deletedOrderNotCopyable:
      "La commande supprimée ne peut pas être copiée en raison de l'insuffisance des informations de commande dans Glow",
    restoreOrder: 'Restaurer',
    deletedOrderNotRestorable:
      "La commande supprimée ne pas être restaurée en raison de l'insuffisance des informations de commande dans Glow",
    preplan: 'Préplanifier',
    deleteConfirmation: 'Voulez-vous vraiment supprimer cette expédition ?',
    deleteMultipleConfirmation: 'Voulez-vous vraiment supprimer les expéditions sélectionnées ?',
    copyConfirmation: 'Voulez-vous vraiment copier cette expédition ?',
    editConsignment: "Modifier les informations d'expédition",
    changeDateAndTime: "Changer la date et l'heure",
    searchShipments: 'Rechercher des expéditions :',
    searchCustomers: 'Rechercher des clients :',
    linkToNewSearchPage:
      '<0>Essayez la nouvelle page de recherche</0>, avec des fonctions de recherche améliorées et un nouveau design. La page est en cours de développement et de nouvelles fonctionnalités seront ajoutées. <1>En savoir plus sur Zendesk</1>',
    filterBy: 'Filtrer par :',
    limitedShipmentsShownMessage: "Affichage de {{count}} expéditions maximum, envisagez d'affiner la recherche",
    limitedUnresolvedAddressesShownMessage: "Affichage d'un maximum de {{count}} adresses non résolues",
    bulkActions: 'Actions en bloc',
    bulkActionsMultiple: 'Actions en bloc ({{count}})',
    moveShipmentsToDifferentDepartment: 'Aller à un département différent',
    addToRecurringGroup: 'Ajouter au groupe récurrent',
    addEvents: 'Ajouter des événements',
    disabledEditDeleteHelperText:
      'Cette commande ne peut pas être modifiée ni supprimée, contactez votre bureau Bring local pour la modifier.',
    editPickupConsignment: "Mettre à jour les informations d'enlèvement",
    editDeliveryConsignment: 'Mettre à jour les informations de livraison',
    editPickupAndDeliveryTime: "Mettre à jour les heures d'enlèvement et de livraison",
    editConsignmentLocation: ' Emplacement',
    editConsignmentTime: ' Heure',
    editConsignmentName: ' Nom',
    editConsignmentPhone: ' Numéro de téléphone',
    editConsignmentInstructions: 'Instructions',
    earliestPickupTime: "Première heure d'enlèvement*",
    latestPickupTime: "Dernière heure d'enlèvement*",
    earliestDeliveryTime: 'Première heure de livraison',
    latestDeliveryTime: 'Dernière heure de livraison',
    enterPickupInstructions: "Entrer les instructions d'enlèvement",
    enterDeliveryInstructions: 'Entrer les instructions de livraison',
    enterDeliveryName: 'Entrer le nom de livraison',
    enterPickupName: "Entrer le nom d'enlèvement",
    enterPickupPhone: "Entrer le numéro de téléphone d'enlèvement",
    enterDeliveryPhone: 'Entrer le numéro de téléphone de livraison',
    specificationSize: 'Les dimensions de spécification',
    enterSpecificationSize: 'Entrer les dimensions de spécification',
    editSpecificationSize: 'Modifier les dimensions de spécification',
    enterSpecificationDescription: 'Entrer la description',
    editSpecificationDescription: 'Modifier la description',
    editPickupInfoDescription: "Pour pouvoir modifier les infos, vous devez choisir l'une des options ci-dessus.",
    updateLocationFor: 'Mettre à jour les informations pour {{count}} expéditions',
    updateLocationFor_plural: "Mettre à jour les informations d'enlèvement pour {{count}} expéditions",
    comment: 'Commentaires',
    show: 'Afficher',
    filter: 'Filtrer les expéditions',
    state: 'État',
    estimatedDelivery: 'Livraison estimée',
    requestedTimePickup: "Heure d'enlèvement demandée",
    requestedTimeDelivery: 'Heure de livraison demandée',
    timeNotSet: 'Non établi(e)',
    customerReference: 'Référence client',
    shipmentId: 'ID expédition',
    packageId: 'ID colis',
    noCourier: 'Aucun coursier',
    noSlot: 'Aucune tournée',
    showEvents: 'Afficher les événements →',
    hideEvents: 'Masquer les événements ←',
    events: 'Événements',
    send: 'Envoyer',
    sendMessage: 'Envoyer un message',
    sendCommentFailed: "Échec de l'enregistrement des commentaires",
    recipient: 'Destinataire',
    unresolved: 'Expéditions non résolues',
    description: 'Description',
    pickupAddress: "Adresse d'enlèvement",
    numberOfPackages: 'Nombre de colis',
    registeredPickupAddress: "Adresse enregistrée d'enlèvement",
    pickupZipCode: 'Code postal',
    pickupZipArea: 'Région',
    pickupLocation: 'Emplacement',
    deliveryAddress: 'Adresse de livraison',
    deliveryZipCode: 'Code postal',
    deliveryZipArea: 'Région',
    deliveryLocation: 'Emplacement',
    registeredDeliveryAddress: 'Adresse enregistrée de livraison',
    updated: 'La commande a été mise à jour',
    moved: 'La commande a été déplacée',
    unresolvedMessage:
      "Il y a {{count}} expédition pour laquelle nous ne pouvons pas trouver l'adresse, merci de l'ajouter.",
    unresolvedMessage_plural:
      "Il y a {{count}} expéditions pour lesquelles nous ne pouvons pas trouver l'adresse, merci de les ajouter.",
    foundUnresolvedMessage:
      'Nous avons trouvé les adresses valides suivantes pour cette expédition. Si elles sont correctes, appuyez sur Mettre à jour. Sinon, veuillez trouver la bonne adresse sur la carte ci-dessous.',
    remove: 'Supprimer',
    alterSlot: 'Changer la tournée (sans optimisation)',
    alterSlotAndReoptimize: 'Changer la tournée (optimiser la nouvelle tournée avec la même commande)',
    alterSlotNotAvailable: "Indisponible pendant l'optimisation",
    optimizingNewSlot: "L'optimisation de l'expédition dans la nouvelle tournée est en cours",
    selectSlot: 'Sélectionner une nouvelle tournée',
    slotSelect: 'Choisir une tournée',
    confirmSlotChange: 'Confirmer le changement de tournée',
    pickupInstructions: "Instructions d'enlèvement",
    deliveryInstructions: 'Instructions de livraison',
    tracking: 'Suivi',
    recipientPhone: 'Numéro de téléphone du destinataire',
    customerRef: 'Réf. client',
    message: {
      CREATED: 'Créée',
      ASSIGNED: 'Affectée à la tournée',
      UNASSIGNED: 'Non affectée',
      COLLECTED: 'Enlèvement effectué',
      DELIVERED: 'Livraison effectuée',
      DEVIATED: 'Echec',
      COMMENT: 'Commentaires',
      RESOLVED: 'Résolue',
      UNRESOLVED: 'Non résolue',
      OFFERED: 'Offerte',
      REJECTED: 'Refusée',
      ACCEPTED: 'Acceptée',
      RETURNED: 'Retournée',
      DELETED: 'Supprimée',
      UNCONFIRMED: 'Non confirmé',
      REFUSED: 'Refusé'
    },
    manualOverrideTitle: 'Commande manuelle',
    adjustTabTitle: 'Modifier',
    bookingPanelTitle: 'Réservation',
    mapPanelTitle: 'Carte',
    recipientName: 'Nom du destinataire',
    receivedBy: 'Reçu par :',
    manuallyChangeLocations: 'Remplacer manuellement les lieux',
    manualOverrideDeviationTimeConfirm: "Confirmer la commande manuelle pour l'échec de l'expédition sélectionnée",
    manualOverrideDeliveryTimeConfirm: "Confirmer la commande manuelle pour la livraison de l'expédition sélectionnée",
    onManuallyChangeLocationsConfirm: 'Voulez-vous vraiment que cette expédition soit non résolue ?',
    unresolveDeliveryAddress: "Annuler la résolution de l'adresse de livraison",
    unresolvePickupAddress: "Annuler la résolution de l'adresse d'enlèvement",
    unresolveAddress: "Annuler la résolution de l'adresse",
    reference: 'Référence',
    shipmentIdNumber: 'Non',
    shipmentDetails: "Détails d'expédition",
    customer: 'Client',
    subcustomer: 'Client no',
    dateAndTime: 'Date et heures',
    filterCustomer: 'Filtrer le client',
    searchShipmentDateError: 'Entrer une plage de dates inférieure à 120 jours.',
    searchPhraseLength:
      'Pour une recherche étendue, la phrase de recherche doit comporter au moins {{minLength}} caractères.',
    moveOrder: "Déplacer l'expédition",
    moveOrderTitle: "Déplacer l'expédition vers un autre département",
    moveOrderDefault: 'Sélectionner un département',
    label: 'Étiquette',
    createLabel: 'Créer une étiquette',
    createLabelShipment: 'Créer une étiquette (Expédition)',
    createMultipleLabel: 'Créer une étiquette ({{count}})',
    viewLabel: "Afficher l'étiquette",
    viewLabels: "Afficher l'étiquette",
    generateLabelErrorTitle: "Une erreur s'est produite",
    generateLabelErrorDescription: "Désolé, impossible de créer d'étiquette pour le moment",
    generateLabelErrorButton: "Voir le code d'erreur",
    generatingLabels: "Les URL de PDF d'étiquette apparaîtront ci-dessous dans un instant",
    labelsGeneratedSuccessfully: "Tous les PDF d'étiquette ont été créés avec succès",
    vas: 'Services à valeur ajoutée (SVA)',
    price: 'Prix',
    orderSources: {
      glow: 'Glow',
      glow_customerBooking: 'Réservation de client GLOW',
      glow_recurring: 'Récurrent(e)',
      ShipmentAPI: "API d'expédition"
    },
    pickupDeviation: "Echec d'enlèvement",
    deliveryDeviation: 'Echec de livraison',
    shipmentSyncStatus: { no: 'Non', yes: 'Oui', partial: 'Partiel(le)' },
    unresolvedSuccessfully: "La résolution de l'expédition a été annulée avec succès.",
    wrongCoordinates: 'Erreur de coordonnées ?',
    moveShipmentToUnresolved: "Déplacer l'expédition vers « Non résolu(e) » pour établir les coordonnées correctes.",
    mapUnavailable: "La carte s'affichera dès la résolution de cette expédition.",
    selectOtherActions: 'Sélectionner d’autres actions'
  },
  eventsList: { timeWindow: 'Créneau horaire', from: 'De', to: 'A' },
  consignmentEvent: {
    message: {
      resolved: "Coordonnées de l'adresse définies",
      comment: 'Commentaires',
      created: 'Créée',
      completed: 'Terminé(e)',
      assigned: 'Coursier affecté',
      plannedOnRoute: 'Itinéraire prévu',
      plannedOnRouteMoreInfo: 'Itinéraire prévu {{routeName}} ({{unitName}})',
      delivered: 'Livraison effectuée',
      collected: 'Enlèvement effectué',
      returned: 'Retourné(e)',
      deviated: 'Echec',
      damaged: 'Endommagé(e)',
      slotChanged: 'Tournée changée manuellement',
      unassigned: 'Non affecté(e)',
      rejected: 'Refusé(e)',
      error: 'Erreur',
      started: 'Démarré(e)',
      stopped: 'Arrêté(e)',
      accepted: 'Accepté(e)',
      offered: 'Offert(e)',
      unitName: 'Unité',
      driverName: 'Livreur',
      'delivery-id-check': "Vérification d'identité",
      'delivered-manually-overridden': 'Livraison choisie manuellement',
      'deviated-manually-overridden': 'Echec choisi manuellement',
      'collected-manually-overridden': 'Enlèvement choisi manuellement',
      'pickup-location-changed': "Adresse d'enlèvement modifiée",
      'delivery-time-estimated': 'Créneau de livraison mis à jour',
      'slot-manually-changed': 'Tournée choisie manuellement',
      'pickup-time-changed': "Adresse d'enlèvement modifiée",
      'manually-change-location': 'Commande manuelle des lieux',
      'pickup-consignment-data-changed': "Les détails d'enlèvement ont été modifiés",
      'delivery-consignment-data-changed': 'Les détails de livraison ont été modifiés',
      'specification-size-data-changed': 'Les dimensions de spécification ont été modifiées',
      'specification-description-data-changed': 'La description a été modifiée',
      'digital-identity-verified': 'Identité du destinataire vérifiée numériquement',
      'digital-identity-verification-failed': "Impossible de vérifier l'identité du destinataire numériquement",
      'flex-delivery-ordered': 'Une livraison simplifiée a été commandée',
      'flex-delivery-changed': 'Une livraison simplifiée a été modifiée',
      'flex-delivery-confirmation-sms-sent': 'Un SMS de confirmation de livraison simplifiée a été envoyé',
      'return-to-pickup-point-delivery-deviated': 'Sera remis au point de collecte',
      recipientName: 'Nom du destinataire',
      receivedBy: 'Reçu par',
      'reject-order-correction': 'Correction de commande refusée',
      'accept-order-correction': 'Correction de commande acceptée',
      'handed-over-to-other-unit': 'Transmission à une autre unité',
      deliveredWithoutSignature: 'Livraison effectuée sans signature',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Colis déposé(s) selon les instructions',
        did_not_get_signature: 'En personne'
      },
      'returned-manually-overridden': 'Retour choisi manuellement',
      'eta-sms-sent': "Un SMS d'ETA e a été envoyé",
      calculatedEta: "Heure d'arrivée estimée : {{eta}}",
      etaSmsLabel: "Heure d'arrivée estimée",
      scanned: 'Arrivé(e) au terminal',
      'arrived-at-terminal': 'Arrivé(e) au terminal de distribution',
      'order-sorted': 'Tri effectué',
      'pickup-eta-sms-sent': "Un SMS d'ETA d'enlèvement a été envoyé",
      'handed-in-at-posten-terminal': 'Arrivé(e) au terminal de tri',
      'pickup-sms-sent': 'Un SMS de suivi a été envoyé',
      'pre-pickup-sms-sent': 'Un SMS de suivi a été pré-envoyé',
      'pickup-address-unresolved': "Adresse d'enlèvement non résolue",
      'delivery-address-unresolved': 'Adresse de livraison non résolue',
      'in-transit': 'En transit',
      'upcoming-pickup-sms-sent': 'Un SMS de livraison à venir a été envoyé',
      'order-sorted-same-day': "Date fixée à aujourd'hui par l'appli terminal",
      'signature-sms-sent': 'Un SMS de signature a été envoyé',
      'flex-delivery-cancelled': 'Livraison simplifiée annulée',
      'delivery-time-ordered': 'La date de livraison a été modifiée par le destinataire',
      'delivery-time-ordered-internal': 'Date de livraison modifiée',
      'delivery-time-updated-from-hf-sync': 'Heure de livraison synchronisée de HF',
      'planned-delivery-date-ordered': 'Une date de livraison prévue a été commandée',
      'delay-sms-sent': 'Un SMS de retard de livraison a été envoyé',
      'production-deviation': 'Ecart de production',
      'manual-delay-sms-sent': 'Un SMS de retard manuel a été envoyé',
      'route-planning-complete': 'Itinéraire terminé',
      'signature-delivered': 'Signature du destinataire',
      showPicture: 'Afficher photo',
      'external-address-wash': 'Les détails de livraison ont été modifiés',
      'order-note-changed': 'Bon modifié',
      'order-data-changed': 'Commande modifiée',
      'order-moved-to-department': 'Déplacée entre départements',
      'order-moved-to-department-with-data': 'Expédition déplacée de',
      'package-data-changed': "Les données de l'expédition ont été modifiées",
      'package-measurements-changed': 'Dimensions colis modifiées',
      'driver-deviated-from-route': "Le livreur s'est écarté de la route prévue",
      'delivered-outside-service-time-window': 'Livraison ou échec hors du créneau horaire de service',
      'delivered-outside-estimated-time-window': 'Livraison ou échec hors du créneau horaire prévu',
      'delivery-location-deviation': "La livraison n'a pas été faite à l'endroit convenu.",
      'seen-by-recipient': 'La page de suivi a été vue par le destinataire',
      'first-estimated-times-seen': 'Les premières heures estimées ont été vues par le destinataire',
      'pre-advice-push-sms-sent': "Un SMS PUSH d'avis préliminaire a été envoyé",
      'pre-advice-push-return-sms-sent': "Un SMS PUSH RETURN d'avis préliminaire a été envoyé",
      'pre-advice-pull-sms-sent': "Un SMS PULL d'avis préliminaire a été envoyé",
      'pre-advice-pull-reminder-sms-sent': "Un SMS PULL d'avis préliminaire rappel au destinataire a été envoyé",
      'pre-advice-pup-sms-sent': "Un SMS PUP d'avis préliminaire a été envoyé",
      'pre-advice-return-sms-sent': "Un SMS RETOUR d'avis préliminaire a été envoyé",
      'pre-advice-return-email-sent': "Un e-mail RETOUR d'avis préliminaire a été envoyé",
      'pre-advice-manual-request': "D'avis préliminaire manuel",
      'pre-advice-status-changed': 'Statut de préavis modifié',
      'reported-damaged': 'Dommages signalés',
      'reported-missing-article': 'Articles manquants signalés',
      'delivery-sms-sent': 'SMS de livraison envoyé',
      'parcelbox-allocated': 'Emplacement réservé dans la boîte à colis',
      unconfirmed: 'Non confirmé',
      'accepted-by-planner': 'Accepté',
      'apartment-information-updated': "Informations sur l'appartement mises à jour",
      'service-upgrade-purchased': 'Ventes incitatives: Service de portage commandé par le destinataire',
      serviceUpgradePurchasedDescription: "La livraison a été mise à jour de bordure de route à l'intérieu",
      'claim-registered-email-sent': 'Envoyer un e-mail concernant les articles endommagés ou manquants',
      'invoicing-failed': 'Échec de la facturation',
      'invoiced-ok': 'Facturé OK',
      'invoice-awaiting-acknowledgement': 'En attente de confirmation de facture',
      'preliminary-loaded': 'Chargé temporairement',
      'manual-price-override': 'Prix écrasé',
      'incomplete-price-approved': 'Prix incomplet approuvé',
      'incomplete-price-unapproved': 'Approbation de prix incomplète révoquée',
      unloaded: 'Déchargé',
      'manually-changed-measurements': 'Poids et volume modifiés manuellement',
      'internal-trade-order-created': "Création d'un ordre commercial interne",
      'delivery-time-purchased': 'Ventes incitatives: fenêtre de temps plus courte commandée par le destinataire',
      'load-carrier-ready-for-distribution': 'Conteneur prêt pour la distribution'
    },
    rejectedReason: {
      reason: 'Raison',
      private: 'Raisons privées',
      notEnoughTime: 'Impossible de livrer à temps',
      notEnoughSpaceOnVehicle: 'Manque de place dans le véhicule',
      other: 'Autre'
    },
    idCheck: {
      idCheckFailed: "Échec de la vérification d'identité, mais livraison effectuée avec {{idCheckFailedResult}}",
      idCheckSummaryOther: 'Autre ({{otherType}}), date de naissance {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, date de naissance {{birthDate}}'
    },
    damaged: {
      damageType: 'Type de dégât',
      causeCode: 'Code de cause',
      courierInput: 'Informations du coursier',
      showPictureLink: 'Afficher photo {{idx}}',
      type: { A: 'Colis/emballage endommagé', D: 'Dommage dû à une pression/compression', E: 'Compléter dommage' }
    },
    invoiceInitiationType: {
      automatic: 'Facturation planifiée',
      'manual-department-level': 'Facturation manuelle du département',
      'manual-order-level': 'Facturation manuelle des commandes',
      'multiple-order-level': "Facturation manuelle de plusieurs commandes par l'utilisateur"
    },
    changedFrom: 'Modifier de -',
    blankText: '(vierge)',
    name: 'Nom',
    resolved: {
      source: {
        DepartmentLocation: 'coordonnées du département',
        GeocodingAPI: 'Google',
        DatabaseLookup: 'coordonnées préenregistrées',
        PickupPointAPI: 'Pickup Point API',
        GlowFrontend: 'Glow',
        EDI: 'inconnu',
        CustomerSite: 'emplacement du site client',
        ParcelInMailbox: 'les coordonnées de la boîte aux lettres',
        Driver: 'livreur',
        OriginalOrder: 'Ordre original',
        Google: 'Google'
      },
      pickup: {
        coordinatesSetBy: 'Coordonnées de ramassage définies par {{source}}',
        coordinatesManuallySet: 'Les coordonnées de ramassage ont été définies manuellement',
        coordinatesRestored: 'Coordonnées de ramassage restaurées',
        coordinatesSwapped: 'Coordonnées de ramassage définies sur les anciennes coordonnées de livraison'
      },
      delivery: {
        coordinatesSetBy: 'Coordonnées de livraison définies par {{source}}',
        coordinatesManuallySet: 'Les coordonnées de livraison ont été définies manuellement',
        coordinatesRestored: 'Coordonnées de livraison restaurées',
        coordinatesSwapped: 'Coordonnées de livraison définies sur les anciennes coordonnées de ramassage'
      }
    },
    created: {
      recurringOrder: 'Créé à partir d’une commande récurrente.',
      showOriginalOrder: 'Afficher l’original'
    }
  },
  jobs: {
    job: 'Tâche',
    lastStarted: 'Démarrée la dernière fois',
    lastFinished: 'Terminée la dernière fois',
    disable: 'Désactiver'
  },
  numberSeries: {
    numberSeries: 'Numéro de série',
    addedNumberSeries: 'Ajouter une nouvelle série de numéros',
    addedNumberSeriesSubHeading: 'Ajouter la série de numéros (ne pas inclure les chiffres de contrôle)',
    shipmentNumber: 'GSIN (Numéro de livraison)',
    packageNumber: 'SSCC (Numéro de colis)',
    minimum: 'Minimum',
    maximum: 'Maximum',
    addNumberSeriesButton: 'Ajouter la série de numéros à la liste',
    type: 'Type',
    currentNumber: 'Numéro actuel',
    maxNumber: 'Numéro max',
    numbersLeft: 'Nombres restants',
    validMinimumValue: 'Vous devez entrer une valeur minimale valide',
    validMaximumValue: 'Vous devez entrer une valeur maximale valide',
    deleteModalTitle: 'Delete Number Series',
    deleteModalDescription: 'You will not be able to recover this action'
  },
  preadvice: {
    searchPlaceholder: 'Saisissez au moins 3 caractères pour filtrer',
    selectedCustomers: 'Client sélectionné ({{count}})',
    customerSearch: 'Champ de recherche - Tapez au moins 3 caractères pour rechercher des clients',
    settingTargetCustomers:
      "Ce paramètre de préavis cible un client spécifique. Supprimez tous les clients sélectionnés pour pouvoir passer à l'option des groupes de clients",
    nameDescription: 'Nom et description',
    createNewPreadvice: 'Créer une nouvelle règle de préavis',
    bizArea: "Sélectionner le domaine d'activité",
    filterDepartmentAndGroups: 'Filtrer par départements ou groupes de départements',
    filterCustomersAndGroups: 'Filtrer par clients ou groupes de clients',
    preadvice: 'Avis préliminaire',
    hdDepartments: 'Dpts Livraisons à Domicile',
    ceDepartments: 'Départements coursier',
    departmentGroups: 'Groupes de départements',
    customers: 'Clients',
    customerGroups: 'Groupes de clients',
    customersCount: 'Clients',
    customerGroupsCount: 'Groupes de clients',
    departmentsCount: 'Départements',
    departmentGroupsCount: 'Groupes de départements',
    locked: 'Préavis verrouillé',
    updated: 'Préavis mis à jour',
    error: {
      PRE_ADVICE_RULE_COLLISIONS: {
        label: 'Conflit de règle !',
        detail:
          'La règle pour {{service}} remplacera la règle pour {{policySetNames}} par le biais de la relation au {{relations}}.',
        department: 'département',
        department_group: 'groupe de départements',
        customer: 'client',
        customer_group: 'groupe de clients'
      }
    },
    noPoliciesFound: "Aucun paramètre d'avis préliminaire",
    policies: "Paramètres d'avis préliminaire",
    policy: {
      servicePoliciesDescription:
        'En fonction du type de notification que vous sélectionnez, différents champs de saisie seront affichés',
      exception: 'Exception',
      name: 'nom',
      new: "Nouveaux paramètres d'avis préliminaire",
      edit: "Modifier les paramètres d'avis préliminaire",
      save: 'Enregistrer',
      saveAnyway: 'Enregistrer malgré tout',
      servicePolicies: "Règles d'avis préliminaire",
      targets: 'Paramètres client/service',
      servicePolicy: {
        edit: 'Modifier les paramètres',
        new: "Ajouter des paramètres d'avis préliminaire de service",
        remove: 'Supprimer la sélection',
        sameDay: 'Même jour',
        triggerEvent: 'Avis préliminaire à',
        cutoff: 'Seuil',
        calendarDays: 'Jours civils',
        noServicePoliciesFound: "Aucun paramètre n'a été ajouté pour le moment",
        returnToSenderAfterDays: "Retour à l'expéditeur après (jours)",
        searchCustomer: 'Rechercher un client',
        remindAfterHours: 'Rappel après',
        reminderToSenderAfterHours: "Rappel à l'expéditeur après",
        label: { days: 'Jours', hours: 'Heures' },
        legend: { days: 'Jours', hours: 'Heures', smss: 'Nombre maxi de SMS' },
        returnToPup: 'Retour à point de ramassage '
      }
    },
    tooltip: "Verrouiller la commande = aucune notification n'est envoyée au destinataire."
  },
  holidays: {
    holidays: 'Jours fériés',
    holiday: 'Jour férié',
    dayBeforeHoliday: 'Jour avant le jour férié',
    addHoliday: 'Ajouter un nouveau jour férié',
    country: 'Pays',
    type: 'Type',
    date: 'Date',
    successfullyAdded: 'Les vacances ont été ajoutées avec succès à la liste',
    successfullyDeleted: 'La vacance a été supprimée avec succès',
    filterOnCountry: 'Filtrer par pays',
    selectHolidayType: 'Sélectionner le type de jour férié',
    addHolidayToList: 'Ajouter des jours fériés à la liste',
    selectCountry: 'Sélectionner le pays',
    addedHolidays: 'Jours fériés ajoutés',
    deleteModalTitle: 'Supprimer les jours fériés',
    deleteModalDescription: 'Vous ne pourrez pas récupérer cette action.'
  },
  department: {
    createNewDepartment: 'Créer un nouveau département',
    addressAndContact: 'Adresse et informations de contact',
    searchAddressError:
      "Valeurs manquantes pour l'adresse. Vous devez utiliser le champ de recherche pour obtenir la bonne adresse.",
    addressContactIngress:
      "Pour obtenir la bonne adresse, utilisez le champ de recherche. L'adresse, le code postal et la ville seront remplis automatiquement.",
    settingsInGlow: 'Paramètres sur la page de suivi du destinataire',
    settingsBodyText:
      'En fonction de votre sélection, différents contenus et communications seront affichés/envoyés aux destinataires.',
    emailAddress: 'Adresse e-mail',
    routePlanning: "Planification de l'itinéraire",
    autoRouteCreationTime: "Création automatique de l'itinéraire",
    autoRouteOptimizationTime: "Optimisation automatique de l'itinéraire",
    clearTime: 'Supprimer le temps',
    departmentTitle: 'Département',
    department: 'département',
    department_plural: 'départements',
    departmentWithCount: '{{count}} département',
    departmentWithCount_plural: '{{count}} départements',
    departmentGroups: 'Groupes de départements',
    numberOfDepartmentGroups: 'Groupes de départements',
    numberOfDepartments: 'Nombre de départements',
    search: 'Rechercher des départements',
    searchGroups: 'Rechercher des groupes de département',
    noDepartmentGroupsFound: 'Groupe de département non trouvé',
    manageDepartment: 'Gérer le département',
    searchDepartment: 'Filtrer par nom de département ou ID Alystra',
    searchDepartmentGroups: 'Rechercher par nom ou description du groupe département',
    searchDepartmentByNameOrId: 'Filtrer par nom de département ou par identifiant de département',
    noDepartmentName: 'Le nom est manquant',
    noDepartmentId: "L'identité est manquante.",
    noCouriers: 'Aucun coursier',
    courierCount_plural: '{{count}} coursiers',
    courierCount: '{{count}} coursier',
    noCourier: 'Aucun coursier',
    couriers: 'Nombre de coursiers',
    plannerCount_plural: '{{count}} planificateurs',
    plannerCount: '{{count}} planificateur',
    noPlanner: 'Aucun planificateur',
    planners: 'Nombre de planificateurs',
    choose: 'Choisir le département',
    new: 'Nouveau département',
    alystraId: 'Identité Alystra',
    description: 'Description',
    name: 'Nom',
    created: 'Département créé',
    updated: 'Département mis à jour',
    departmentType: 'Type de département',
    notSet: 'Non établi(e)',
    courier: 'Coursier',
    hd: 'Livraison à domicile',
    other: 'Autre',
    all: 'Tout',
    deliveryDisplayType: "Affichage de l'heure de livraison",
    terminalName: 'Nom du dépôt',
    terminalNameTooltip:
      'Si le point d’enlèvement et le nom de département sont différents, remplissez ces informations',
    selectArea: 'Sélectionner la région...',
    address: 'Adresse',
    zipCode: 'Code postal',
    zipArea: 'Région',
    autoRecurrentTime: 'Heure automatiquement récurrente',
    autoInvoiceTitle: 'Facturation récurrente',
    autoInvoiceEnable: 'Configurer la facturation automatique',
    autoInvoiceTime: 'Heure de facturation',
    autoInvoiceOffset: {
      legend: 'Quelles commandes facturer',
      oneDayEarlier: 'Livré 1 jour plus tôt',
      twoDaysEarlier: 'Livré 2 jours plus tôt',
      threeDaysEarlier: 'Livré 3 jours plus tôt',
      fourDaysEarlier: 'Livré 4 jours plus tôt',
      fiveDaysEarlier: 'Livré 5 jours plus tôt'
    },
    unitNumber: "Nombre d'unités",
    npsId: 'ID NPS',
    defaultAlystraUnitId: "Identité de l'unité d'autofacturation",
    timeWindow: 'Créneau horaire',
    pickupEarliest: "Premier heure d'enlèvement",
    pickupLatest: "Dernière heure d'enlèvement",
    deliveryEarliest: 'Première heure de livraison',
    deliveryLatest: 'Dernière heure de livraison',
    cutoff: 'Seuil',
    updateTimeWindow: 'Mettre à jour la fenêtre horaire',
    listAllDepartments: 'Faire la liste de tous les départements',
    listAllGroups: 'Lister tous les groupes de départements',
    orderBillingType: {
      title: 'Type de facturation de la commande',
      selectBillingType: 'Sélectionnez le type de facturation',
      pickup: 'Enlèvement',
      customerBillingNumber: 'Numéro de facturation client',
      addMoreOrderBillingInfo: 'Ajouter un nouveau type de facturation de commande'
    },
    departmentId: 'Identité département',
    disableSms: 'Désactiver le suivi et les SMS d’ETA sur l’itinéraire',
    smsShouldNotBeSent:
      'Les SMS de suivi et les SMS d’ETA sont désactivés et ne doivent pas être envoyés aux destinataires des itinéraires produits par ce service.'
  },
  departmentGroup: {
    countries: 'Pays',
    departmentTypes: 'Types de départements',
    manageDepartments: 'Gérer les départements dans le groupe',
    createDepartmentGroup: 'Créer un nouveau groupe de départements',
    selectAllFor: 'Sélectionner tout pour {{name}}',
    departmentGroup: 'Groupe de départements',
    name: 'Nom',
    edit: 'Modifier le groupe de départements',
    deleteConfirm: 'Voulez-vous vraiment supprimer ce groupe de départements ?',
    selectedDepartments: 'Départements sélectionnés ({{count}})',
    noSelectedDepartments: 'Aucun département sélectionné',
    selectedCountries_plural: '{{count}} pays sélectioné',
    selectedDepartmentTypes_plural: 'Types de département sélectionnés pour le groupe de départements'
  },
  deliveryDisplayType: { instant: 'Instantané', customized: 'Personnalisé(e)' },
  menu: {
    defaultDisabled: 'Sélectionnez un client pour activer cet élément de menu',
    backToOldShipmentsPage: "Retour à l'ancien site.",
    home: 'Accueil',
    planner: 'Planificateur',
    report: 'Rapports',
    customer: 'Client',
    airexpressAdmin: 'Admin Air Express',
    apiOrder: 'Trouver la commande API',
    support: 'Aide',
    priceMotor: 'Moteur de tarification',
    departments: 'Départements',
    users: 'Utilisateurs',
    customers: 'Client',
    customerGroups: 'Groupes de clients',
    numberSeries: 'Numéro de série',
    optimizing: 'Optimisation',
    services: 'Services',
    matrix: 'Matrice',
    jobsMonitoring: 'Surveillance des tâches',
    holidays: 'Jours fériés',
    settings: 'Paramètres',
    preadvice: 'Avis préliminaire',
    userRoles: "Rôles d'utilisateur",
    integrations: 'Integrations',
    live: 'En direct',
    routeManagement: 'Management des tournées de livraison',
    routePlanning: "Planification d'itinéraire",
    dispatching: 'Dispatch',
    booking: 'Réservation',
    h2Booking: 'H2 Réservation',
    setCoordinates: 'Définir les coordonnées',
    shipments: 'Rechercher',
    corrections: 'Corrections',
    units: 'Unités',
    couriers: 'Coursiers',
    vehicles: 'Véhicules',
    recurring: 'Récurrent(e)',
    importExport: 'Importer / Eksporter',
    addresses: 'Adresses',
    invoicing: 'Facturation',
    manageReturns: 'Gérer les retours',
    preAdviceFollowup: 'Gérer les préavis',
    vendors: 'Fournisseurs',
    mainNavigation: 'Navigation principale',
    closePanel: 'Fermer le panneau',
    expandPanel: 'Développer le panneau',
    admin: 'Administration'
  },
  planner: {
    chooseYourDepartment: 'Choisissez votre département',
    userRolesCount_plural: '{{count}} rôles utilisateurs',
    userRolesCount: '{{count}} rôle utilisateur',
    role: 'Rôle',
    typeOfRole: 'Type de rôle utilisateur',
    selectUserRole: 'Sélectionnez un rôle utilisateur',
    users: 'Utilisateurs',
    connectDepartment: "Connecter le département à l'utilisateur",
    unassignedShipments: 'Livraisons non attribuées',
    filterCustomers: 'Filtrer les clients',
    filterServices: 'Filtrer les services',
    autoRoute: 'Routage automatique',
    secondTab: 'Deuxième volet',
    saved: 'Enregistré',
    showInGoogleMaps: "Afficher l'itinéraire dans Google Maps",
    removeDepartment: 'Supprimer le département {{name}}',
    connectAirlineTerminal: "Connecter le(s) terminal(aux) d'aéroport à l'utilisateur",
    connectedAirlineTerminals: "Terminaux d'aéroport connectés ({{count}})",
    noConnectedTerminals: 'Aucun terminal connecté pour le moment',
    removeTerminal: 'Supprimer le terminal {{name}}',
    searchTerminals: "Rechercher des terminaux d'aéroport",
    youMustSelectTerminal: "Vous devez sélectionner un terminal d'aéroport",
    youMustProvideName: "Vous devez fournir le nom de l'utilisateur",
    youMustProvidePhonenumber: 'Vous devez fournir le numéro de téléphone',
    autoRouteModal: {
      selectRoutes: 'Selectionner les tournées de livraison',
      selectRoutesText: 'Sélectionner les tournées que vous voulez inclure dans le routage automatique',
      lockAllToSlot: 'Bloquer toutes les livraisons attribuées à ce créneau',
      useMapAreaRestrictions: 'Utiliser les restrictions de zone sur la carte',
      usePtv: "Utiliser PTV pour l'optimisation",
      optimizeOn: 'Optimiser sur',
      noRelevantRoutes: 'Tournées appropriées non trouvées',
      startAutomaticRouting: 'Commencer le routage automatiue'
    },
    wait: "Temps d'attente",
    trips: 'Trajets',
    searchSlotCourierName: 'Rechercher un créneau/un livreur',
    createdBy: 'Crée par',
    allUserRoles: "Tous les rôles d'utilisateurs",
    createNewUser: 'Créer un utilisateur',
    noPlannersFound: 'Planificateur non trouvé',
    searchForNameOrNumber: 'Trouver le nom ou le numéro de téléphone',
    banner: {
      tryNewRoutePlanner:
        'À partir du 1er mai, <0>Planification d’itinéraire 2.0</0> sera le seul planificateur d’itinéraires disponible. Si vous n’avez pas encore utilisé ce planificateur d’itinéraires, nous vous encourageons à le faire dès maintenant. <1>Lisez des informations sur Zendesk</1> ainsi que d’autres articles associés pour avoir une idée de ses fonctionnalités.'
    },
    totalTitle: 'Colis',
    pickList: "Liste d'articles à prélever",
    export: 'Exporter pour la facturation',
    deleteSlot: 'Supprimer',
    editSlot: 'Modifier',
    deleteSlotConfirmDialogText: 'Voulez-vous vraiment supprimer cette tournée ?',
    deleteFilteredSlots: 'Supprimer tous les emplacements',
    deleteAllSlotsConfirmDialogText: 'Êtes-vous sûr de vouloir supprimer tous les emplacements?',
    created: 'Un planificateur a été créé',
    updated: 'Un planificateur a été mis à jour',
    live: 'En direct',
    routePlanning: "Planification d'itinéraire",
    routeManagement: 'Management des tournées de livraison',
    vehicles: 'Véhicules',
    routePlanningConsignmentsAssigned: 'affecté(e)',
    routePlanningRangeDescription: 'Filtrer les expéditions par heure de livraison',
    unresolvedConsignments: 'Non résolu(e)',
    consignments: 'Rechercher',
    couriers: 'Coursiers',
    units: 'Unités',
    invoicing: 'Facturation',
    planner: 'Planificateur',
    planners: 'Planificateurs',
    noPlanners: 'Aucun planificateur',
    missingId: "L'identité est manquante",
    missingName: 'Le nom est manquant',
    search: 'Rechercher',
    searchForPlanner: 'Rechercher des planificateurs',
    name: 'Nom',
    phoneNumber: 'Numéro de téléphone',
    canLogIn: 'Peut se connecter',
    selectDepartments: 'Sélectionner les départements',
    createSlot: 'Créer une tournée',
    editSlotForm: 'Modifier une tournée',
    routeUpdated: 'Les détails de tournée ont été mis à jour',
    createSlotFormName: 'Nom de tournée',
    createSlotFormUnit: 'Unité',
    createSlotFormCourier: 'Coursier',
    createSlotFormSlotMode: 'Mode de tournée',
    createSlotFormSlotModePickup: "Dessiner la zone d'enlèvement sur la carte",
    createSlotFormSlotModeDelivery: 'Dessiner la zone de livraison sur la carte',
    createSlotFormSlotModeManual: 'Sélectionner manuellement les expéditions',
    createSlotFormSlotModeSelected: 'Expéditions sélectionnées',
    createSlotFormTimeRange: "Fourchette d'heures",
    createSlotFormStartLocation: 'Lieu de départ',
    createSlotFormEndTime: 'Heure de fin',
    createSlotFormEndLocation: "Lieu d'arrêt",
    createSlotFormSlotColor: 'Couleur de tournée',
    createSlotFormTimeRangeTooBig: 'La période de temps est trop large. Ne peut dépasser {{hours}} heures.',
    createSlotFormFilterCustomers: 'Filtrer les clients',
    createSlotFormFilterServices: "Filtrer les services (l'absence de coche signifie tous les services)",
    slotFilterInfo:
      "Cette tournée a fait l'objet d'un filtrage, c'est-à-dire que seules les expéditions appartenant au(x) client(s) choisi(s) et service(s) choisi(s) peuvent être reliées à la tournée.",
    slotFilteredCustomer: 'Cette tournée inclut UNIQUEMENT les commandes des clients suivants',
    slotFilteredService: 'Cette tournée inclut UNIQUEMENT les commandes avec les services suivants',
    onlyShowApplicable:
      "N'afficher que les commandes applicables à cette tournée en fonction du filtrage client/service",
    optimizeButtonLabel: 'Optimiser et affecter les itinéraires',
    optimizeSlotButtonLabel: 'Réoptimiser la tournée',
    reversedOptimizeSlotButtonLabel: "Inverser l'ordre de livraison",
    optimizingSince: 'Optimisation en cours, lancée à {{startTime}}',
    alterSlotIdOnConsignment: 'Voulez-vous vraiment modifier la tournée pour cette expédition ?',
    addToRoute: "Ajouter à l'itinéraire...",
    pickup: 'Enlèvement',
    delivery: 'Livraison',
    slot: 'Tournée',
    notAssigned: 'Non affecté(e)',
    readOnly: 'En lecture seule',
    partner: 'Partenaire',
    readOnlyAccessPlannersWithCount: '{{count}} planneurs avec accès en lecture seule',
    plusPlannersWithCount: '{{count}} planificateurs plus',
    normalPlannersWithCount: '{{count}} planificateurs normaux',
    saveTemplate: 'Enregistrer comme modèle',
    loadTemplate: 'Charger à partir du modèle',
    routeListForFilteredSlots: 'Liste des signatures (imprimer tout)',
    loadListForFilteredSlots: 'Liste de chargement (imprimer tout)',
    sendPrePickupSmsesToAll: 'Pré envoyer le sms de suivi',
    alertBeforeSendingPrePickupSms:
      "Êtes-vous sûr de vouloir pré-envoyer des SMS de suivi pour tous les créneaux pour aujourd'hui?",
    createTemplateFormName: 'Nom de modèle',
    createOrUpdateTemplateDescription:
      'Pour pouvoir enregistrer, vous devez écrire un nom de modèle unique OU sélectionner un modèle existant à mettre à jour',
    newTemplate: 'Créer un nouveau modèle',
    overwriteTemplate: 'Remplacer le modèle',
    templateSaved: 'Le modèle a été enregistré',
    updateTemplateDescription: 'Sélectionner le modèle existant à mettre à jour',
    updateTemplateConfirm: 'Voulez-vous vraiment mettre à jour ce modèle ?',
    selectOrDeleteTemplateDescription: 'Veuillez choisir un modèle à appliquer ou supprimer',
    selectTemplate: 'Sélectionner un modèle',
    deleteTemplate: 'Supprimer',
    selectTemplateDescription: 'Sélectionner un modèle :',
    templateSelected: 'Le modèle a été appliqué',
    templateDeleted: 'Le modèle a été supprimé',
    templateDeleteConfirm: 'Voulez-vous vraiment supprimer ce modèle ?',
    missingDeliveryMarker: 'Lieu ou adresse de livraison non valide',
    missingPickupMarker: "Lieu ou adresse d'enlèvement non valide",
    unassignConsignmentFromSlot: "Supprimer l'expédition de la tournée",
    unassignBulkConsignmentsFromSlotsAlert: 'Supprimer les expéditions de leurs tournées respectives',
    unassignedFromSlot: "L'affectation de l'expédition a maintenant été supprimée de la tournée",
    unassignBulkConsignmentsFromSlots:
      "L'affectation des expéditions a maintenant été supprimée de leurs tournées respectives",
    unassignTitle: "Supprimer l'affectation de cette expédition à la tournée",
    unassignButton: "Supprimer l'affectation à la tournée",
    forceUnassignButton: "Forcer la suppression de l'affectation à la tournée",
    moveOrder: 'Voulez-vous vraiment déplacer cette expédition ?',
    driverName: 'Livreur',
    deliveries: 'Livraisons',
    returns: 'Retours',
    installations: 'Installations',
    curbside: 'Trottoir',
    filters: { assigned: 'Affecté(e)', unassigned: 'Non affecté(e)', pickups: 'Enlèvements', deliveries: 'Livraisons' },
    saveSelected: 'Enregistrer les éléments sélectionnés ({{count}})',
    showSaved: 'Afficher les éléments sélectionnées ({{count}})',
    showAll: 'Afficher tout',
    clearSaved: 'Supprimer les éléments enregistrés',
    sendPrePickupSms: 'Pré-envoyer un SMS de suivi au destinataire',
    sendSms: 'Envoyer SMS',
    sendingSmsText: 'Pré-envoi de {{count}} SMS de suivi en cours',
    prePickupSmsSent: '{{sentCount}} sur {{totalCount}} des SMS de suivi ont été pré-envoyés',
    manualDelaySms: {
      smsSent: '{{count}} SMS de retard manuel(s) envoyé(s)',
      heading: 'SMS de retard manuel',
      sendingSmsText: 'Envoi de {{count}} SMS de retard manuel',
      selectSmsDescription:
        "Le SMS commencera par « Bonjour, Votre livraison en provenance de l'expéditeur est malheureusement en retard. » Sélectionner la formule de fin de SMS ci-dessous.",
      selectSmsEnding: 'Sélectionner la formule de fin de SMS',
      buttonTitle: 'Envoyer',
      endingType: {
        none: 'Aucun',
        soon: '« Nous nous efforcerons de vous livrer le plus tôt possible. »',
        tomorrow: '« Nous nous efforcerons de vous livrer le jour ouvrable suivant. »'
      },
      disabledTooltip: 'Les commandes ne peuvent pas être dans l’état Livrée'
    },
    h2: { booking: 'Réservation H2', vasHelperText: 'Utiliser SVA 1158 pour MBH' },
    loadList: {
      address: 'Adresse',
      customerRef: 'Référence client',
      deliveries: 'Livraisons',
      estimatedTotalTime: 'Heure est.',
      header: "Résumé de l'itinéraire",
      instructions: 'Instructions de livraison, /ntéléphone, /nréf. destinataire',
      instructionsPhone: 'Tél. :',
      instructionsRef: 'Réf. destinataire :',
      listName: 'Liste de chargement',
      name: 'Nom',
      qty: 'Qté',
      routeName: 'Itinéraire',
      service: 'Service\n(SVA)',
      stopOrder: 'Arrêter commande',
      timeWindow: 'Créneau horaire\n (ETA-ETD)',
      tripNumber: 'Tournée',
      unit: 'Unité',
      weight: 'Poids\nkg',
      zipArea: 'Région',
      zipCode: 'Code postal',
      unknownRouteName: "Nom d'itinéraire inconnu",
      unknownUnitName: "Nom d'unité inconnu",
      noDataText: "Il n'y a rien à voir ici pour le moment."
    },
    locationList: { listName: 'Liste des lieux' },
    assignedUnits: 'Unités affectées',
    unassignedUnits: 'Unités non affectées',
    noUnitSelected: 'Aucune unité sélectionnée',
    unitAssignmentCount: 'Unités affectées à {{count}} itinéraires'
  },
  plannerDashboard: {
    earningsRatings: { tooMuch: 'Trop' },
    noStatisticsAvailable: 'Aucune statistique disponible',
    routes: 'Itinéraires',
    status: 'Statut',
    started: 'Commencé(e)',
    notStarted: 'Non commencé(e)',
    vehicleTypes: {
      car: 'Voiture',
      bicycle: 'Bicyclette',
      truck: 'Camion',
      van: 'Camionnette',
      motorcycle: 'Moto',
      cargobike: 'Vélo cargo'
    }
  },
  updateServiceLevel: {
    menuItem: 'Modifier le niveau de service',
    selectOption: 'Sélectionner le niveau de service',
    heading: 'Modifier le niveau de service',
    confirmation: 'Le niveau de service a été mis à jour',
    subtext: 'Modifier le niveau de service pour {{count}} expédition',
    subtext_plural: 'Modifier le niveau de service pour {{count}} expéditions',
    failed: {
      title: "Nous n'avons pas pu modifier le niveau de service",
      message: "Nous n'avons pas pu modifier le niveau de service pour {{count}} expédition",
      message_plural: "Nous n'avons pas pu modifier le niveau de service pour {{count}} expéditions"
    }
  },
  manualPreAdvice: {
    menuItem: 'Préavis manuel',
    heading: 'Préavis manuel',
    subtext: 'Change service level for {{count}} shipment',
    subtext_plural: 'Change service level for {{count}} shipments'
  },
  setPickupDeliveryDuration: {
    menuItem: "Fixer la durée d'enlèvement/de livraison",
    heading: "Fixer la durée d'enlèvement et de livraison",
    subtext: "Fixer la durée d'enlèvement et de livraison pour {{count}} expédition",
    subtext_plural: "Fixer la durée d'enlèvement et de livraison pour {{count}} expéditions",
    pickupDuration: "Durée d'enlèvement",
    deliveryDuration: 'Durée de livraison',
    confirmation: 'Durées mises à jour',
    tooltipTitle: 'Lors de la saisie des durées, le nombre de minutes et de secondes maximum est 59'
  },
  importExportOrders: {
    importExportOrder: 'Importer / Eksporter',
    importSuccess: 'Toutes les commandes de la feuille de calcul sont ajoutées ou mises à jour dans le système',
    importSuccessTitle: 'Succès',
    importOrdersHeading: 'Importer la commande',
    importStatus:
      "Il y a actuellement {{imports}} importations en file d'attente avec un nombre total de {{orders}} commandes",
    exportForInvoice: 'Exporter pour la facturation',
    fileTemplateLink: 'Télécharger le modèle Excel',
    uploadFile: 'Importer la commande',
    uploadingMessage: 'Téléchargement du fichier et importation des commandes...',
    needToSelectFile: 'Vous devez sélectionner un fichier',
    exportSuccess: 'Toutes les commandes de la plage de dates sélectionnée sont téléchargées dans la feuille de calcul',
    exportSuccessTitle: 'Succès',
    dateFormat: 'JJ.MM.AAAA',
    testDataWarning: "Ne pas utiliser d'informations personnelles dans l'environnement de test",
    testDataInfo:
      "Il s'agit d'un environnement qatest, et vous n'êtes pas autorisé à utiliser les données de production dans les tests sans le consentement explicite des utilisateurs concernés.",
    fromDate: 'À partir de la date',
    toDate: "Jusqu'à la date",
    fromDateNotAfterToDate: "Ne peut pas être plus tard que 'Jusuq'à la date'",
    toDateNotBeforeFromDate: "Ne peut pas être plus tôt que 'À partir de la date'",
    removeControlColumns: 'Supprimer les colonnes de contrôle',
    importResults: 'Importer les résultats',
    fileName: 'Fichier importé',
    processedAt: 'Traité',
    createdShipmentId: "Identifiant d'expédition créé",
    exportShipments: 'Exporter des navires',
    createLabels: 'Créer des étiquettes',
    selectSlot: "Sélectionner l'itinéraire",
    selectExportLabel: 'Sélectionnez ce que vous souhaitez exporter',
    allOrders: 'Toutes les commandes',
    deviatedOrders: 'Commande avec écart',
    all: 'Tous',
    noSelection: 'Aucune sélection',
    selectFile: 'Sélectionner un fichier',
    noFileSelected: 'Aucun fichier',
    updateSelectedFile: 'Mettre à jour le fichier',
    jobList: {
      title: 'Exportations précédentes',
      content: 'Contenu',
      created: 'Créé',
      user: 'Utilisateur',
      dates: 'Date',
      download: 'Téléchargé',
      exporting: 'Exportation...',
      inQueue: "Dans la file d'attente d'exportation",
      exportFailed: "Échec de l'exportation",
      updated: 'Updated',
      previousImports: 'Importations précédentes',
      fileName: 'Nom du fichier',
      numberOfOrders: 'Nombre de commandes',
      imported: 'Importé',
      viewOrders: 'Voir les commandes',
      lastImports: 'Affichage des {{count}} dernières importations'
    },
    exportStartedNotification: "L'exportation a commencé",
    exportStartedNotificationMessage: 'Votre signalement sera disponible dès son traitement.',
    exportFailedNotification: "Échec de l'exportation, réessayez plus tard",
    refreshData: 'Mettre à jour les données dans le rapport existant',
    refreshDataDescription:
      'Choisissez cette option si vous avez déjà créé un rapport existant pour les dates spécifiques',
    types: {
      SHIPMENT_EXPORT: 'Expéditions',
      AIREXPRESS_SHIPMENTS: 'Expéditions (contenant des données AirExpress)',
      COURIER_WORK_REPORT: 'Rapport de travail du conducteur',
      END_OF_DAY_REPORT: 'Rapport de fin de journée',
      SOCIAL_CONTROL: 'Contrôle social',
      LOCATION_LIST: "Liste d'emplacements",
      ORDER_EXPORT: 'Exportation de la commande',
      ORDER_DEVIATION_EXPORT: 'Commande (déviation)',
      ORDER_EXPORT_ANONYMOUS: 'Commande (données anonymes)',
      ORDER_PARTNER_EXPORT: 'Commande (pour les partenaires)',
      DEVIATION_EVENTS: 'Événements de déviation',
      OPTIMIZATION_RESULTS: "Résultats d'optimisation",
      FINANCIAL_REPORT: 'Rapport financier'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Exporter des envois',
      AIREXPRESS_SHIPMENTS: 'Exporter les expéditions (contenant des données AirExpress)',
      COURIER_WORK_REPORT: 'Rapport de travail du conducteur',
      END_OF_DAY_REPORT: 'Rapport de fin de journée',
      SOCIAL_CONTROL: 'Exporter le contrôle social',
      LOCATION_LIST: 'Exporter la liste des emplacements',
      ORDER_EXPORT: 'Exporter la commande',
      ORDER_DEVIATION_EXPORT: 'Exporter la commande avec écart',
      ORDER_EXPORT_ANONYMOUS: "Commande d'exportation anonymisée",
      ORDER_PARTNER_EXPORT: 'Exporter la commande du partenaire',
      DEVIATION_EVENTS: "Exporter les événements d'écart",
      OPTIMIZATION_RESULTS: 'Exporter les optimointitulokset',
      FINANCIAL_REPORT: 'Exporter rapport financier'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: 'Ceci inclue des données AirExpress, en plus des données de livraisons habituelles',
      ORDER_EXPORT:
        'Si vous souhaitez utiliser des données dans des environnements de test, nous vous recommandons d\'utiliser l\'option "Commander (données anonymisées)"',
      ORDER_DEVIATION_EXPORT:
        'Si vous souhaitez utiliser des données dans des environnements de test, nous vous recommandons d\'utiliser l\'option "Commander (données anonymisées)"',
      ORDER_EXPORT_ANONYMOUS: 'Peut être utilisé si les données doivent être importées dans un environnement de test',
      ORDER_PARTNER_EXPORT: 'Fichier Excel personnalisé pouvant être utilisé pour importer dans Opter',
      OPTIMIZATION_RESULTS: '',
      FINANCIAL_REPORT:
        'Contient une feuille supplémentaire avec les commandes ventilées par lignes de débit, le cas échéant'
    }
  },
  unit: {
    clearSearch: 'Effacer la recherche',
    unitWithCount: '{{count}} unité',
    unitWithCount_plural: '{{count}} unités',
    new: 'Nouvelle unité',
    edit: "Modifier l'unité",
    addCourier: 'Ajouter un coursier',
    hideAddCourier: 'Masquer Ajouter un coursier',
    requiresCourierUsers: 'Doit comporter au moins un coursier',
    created: "L'unité a été créée",
    updated: "L'unité a été mise à jour",
    search: "Rechercher les unités d'autres départements...",
    unitsInCurrentDepartment: 'Les unités du département actuel',
    unitsInOtherDepartments: 'Les unités des autres départements',
    saveUnit: "Enregistrer l'unité",
    addCourierToUnit: "Enregistrer le livreur sur l'unité",
    noCouriersFound: 'Aucun coursier trouvé',
    addCourierToUnitSearch: 'Vous ne pouvez rechercher des coursiers que dans les services auxquels vous avez accès.',
    addedCouriers: 'Coursiers ajoutés ({{count}})',
    newCourier: 'Créer un nouveau coursier',
    name: 'Nom',
    unitId: "ID de l'unité",
    unitId_glow: 'ID Alystra',
    couriers: 'Coursiers',
    active: 'Actif',
    vehicle: 'Véhicule',
    deleteQuestion: "Supprimer l'unité du service actuel",
    deleteDescription: "L'unité peut être reconnectée plus tard si elle est toujours connectée à d'autres services.",
    unassigned: "L'unité a été supprimée avec succès du service.",
    setDefaultVehicle: {
      add: 'Ajouter un véhicule',
      change: 'Changer de véhicule',
      title: 'Ajouter un véhicule à {{unit}}',
      selectVehicle: 'Sélectionner un véhicule',
      addSuccess: 'Le véhicule a été ajouté avec succès à {{unit}}.',
      changeSuccess: 'Le véhicule a été changé avec succès sur {{unit}}.'
    }
  },
  courier: {
    isActive: 'Est actif(ve)',
    canLogIn: 'Peut se connecter',
    secureLoginEnabled: 'Connection par BankID',
    recipientName: 'Nom du destinataire',
    created: 'Un coursier a été créé',
    updated: 'Un coursier a été mis à jour',
    courier: 'Coursier',
    couriers: 'Coursiers',
    noCouriers: 'Aucun coursier',
    courierWithCount: '{{count}} coursier',
    courierWithCount_plural: '{{count}} coursiers',
    numberOfCouriers: '{{number}} coursiers ont été trouvés',
    multipleDrivers: 'Plusieurs coursiers',
    missingId: "L'identité est manquante",
    missingName: 'Le nom est manquant',
    search: 'Rechercher des coursiers',
    new: 'Nouveau coursier',
    edit: 'Modifier le coursier',
    name: 'Nom',
    alystraId: 'Identifiant Alystra',
    phoneNumber: 'Numéro de téléphone',
    driverId: 'ID livreur',
    capacity: 'Capacité',
    capacityLabel: 'Capacité (quantité)',
    weightCapacity: 'Capacité de poids (kg)',
    volumeCapacity: 'Capacité de volume (m³)',
    maxRangeKm: 'Distance maxi (km)',
    color: 'Couleur',
    resetColor: 'Rétablir la couleur',
    speedFactor: 'Facteur de vitesse',
    speedFactorExplanation: 'plus bas = plus lent',
    serviceTimeFactor: 'Facteur de temps du service',
    serviceTimeFactorExplanation: 'plus bas = plus rapide',
    defaultStopLocation: "Lieu d'arrêt par défaut",
    defaultStartLocation: 'Lieu de départ par défaut',
    slotNumDeliveries: '{{count}} livraison',
    slotNumDeliveries_plural: '{{count}} livraisons',
    slotNumPickups: '{{count}} enlèvement',
    slotNumPickups_plural: '{{count}} enlèvements',
    deliverPerformButtonLabel: 'Livrer',
    handoverPerformButtonLabel: 'Remettre',
    deviationPerformButtonLabel: 'Echec',
    deliverWithoutSignatureButtonLabel: 'Sans signature',
    deviateNotAtHomeButtonLabel: 'Absence',
    deviateLocNotFoundButtonLabel: 'Impossible de trouver le lieu',
    deviateRecipientDeclinedButtonLabel: 'Refus de livraison par le destinataire',
    deviateTimeWindow: 'Impossible de livrer dans le créneau horaire',
    addressNotFound: 'Adresse introuvable',
    deliverySpotUnavailable: "Le lieu de livraison convenu n'existe pas",
    deviation: {
      'H-12': "Une tentative de livraison de l'expédition a été effectuée, mais le destinataire était absent",
      'H-11': "Nous avons tenté de livrer le colis, mais le destinataire n'était pas sur place",
      'H-10': "Nous avons tenté de livrer le colis, mais nous n'avons pas trouvé l'adresse indiquée",
      'H-13': "Une tentative de livraison de l'expédition a été effectuée, mais la livraison a été refusée",
      '7-10': "Les marchandises n'ont pas pu être enlevées en raison d'une adresse incomplète",
      '7-12': "L'expéditeur n'était pas disponible. Les marchandises n'ont pas été enlevées",
      '7-82': "Les marchandises n'étaient pas prêtes à être enlevées",
      'V-41-K': "L'expédition est retardée et sera effectuée le plus tôt possible",
      'H-07-R': 'Echec du contrôle social',
      'H-07-Z': 'Echec du contrôle social',
      'H-08-Z': "Echec du contrôle d'âge",
      'H-09-Z': "Echec du contrôle d'identité",
      'VI-16-X': 'Impossible de livrer dans le créneau horaire',
      'H-11-Z': 'Adresse introuvable',
      'H-BL-A': "Le lieu de livraison convenu n'existe pas",
      'V-100-CA': 'Aucune boîte à colis disponible. Le colis sera livré à domicile.',
      'V-100-CI': 'Pas de trappe disponible',
      'V-80-CI': 'Le colis dépasse les dimensions/poids autorisés',
      'V-99-CI': 'Autre inconnu',
      'V-AO-CI': 'La machine est en panne'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} terminée(s).',
    lockCode: 'Digicode',
    flexDelivery: 'Livraison simplifiée',
    fuelType: 'Type de carburant',
    fuelTypes: {
      gasoline: 'Essence',
      natural_gas: 'Gaz naturel',
      rme: 'RME',
      ethanol: 'Éthanol',
      bio_gas: 'Biogaz',
      electric: 'Électricité',
      bicycle: 'Bicyclette',
      hydrogen: 'Hydrogène',
      diesel: 'Diesel',
      hvo: 'HVO'
    },
    fuelCategory: 'Catégorie de carburant',
    fuelCategories: {
      fossil: 'Combustible fossile',
      fossil_free: 'Sans combustible fossile',
      zero_emissions: 'Zéro émission'
    },
    latestLogin: 'Dernière connexion',
    latestVersion: 'Dernière version',
    failedSocialControl: 'Echec du contrôle social',
    failedIdControl: "Echec du contrôle d'identité",
    failedAgeControl: "Echec du contrôle d'âge",
    incompletePickupAddress: 'Adresse incomplète',
    custNotAvailableAtPickupAddress: 'Client absent',
    goodsNotReadyAtPickupForCust: "Marchandises non prêtes pour l'enlèvement chez le client",
    shipmentNotAvailableAtTerminal: 'Colis non disponible au terminal',
    registrationNumber: "Numéro d'immatriculation",
    commercialTrafficEligibility: 'Véhicule utilitaire autorisé',
    vehicleBrand: 'Marque du véhicule',
    vehicleModel: 'Modèle du véhicule',
    vehicleControl: 'Contrôle du véhicule',
    vehicleControlComment: 'Commentaires sur le contrôle du véhicule',
    euroClass: 'Classe Euro',
    alcoholDeliveryEducation: "Formation de livraison d'alcool",
    approved: 'Approuvée',
    notApproved: 'Non approuvée',
    couriersInCurrentDepartment: 'Coursiers du département actuel',
    editCourier: 'Modifier un coursier :',
    courierUserUpdated: 'Le coursier a été mis à jour avec succès.',
    dateOfBirth: 'Date de naissance',
    dateOfBirthWithFormat: 'Date de naissance (aaaa-mm-jj)',
    occupationalInjuryInsurance: 'Assurance contre les accidents du travail',
    courierScanEnabled: 'Scanner',
    breakTime: 'Temps de pause',
    breakDuration: 'Durée de la pause (min)',
    timeWindow: 'Début de la pause (le plus tôt et le plus tard)',
    breakTimeDescription:
      "Dans les champs de saisie <i>Début de la pause (le plus tôt et le plus tard)</i>, vous définissez l'heure la plus tôt et la plus tardive à laquelle la pause doit commencer. Par exemple, définir 11:00 - 17:00 signifie que l'heure la plus tardive pour commencer la pause est 17:00.",
    between: 'Entre',
    editBreakTime: 'Modifier le temps de pause',
    updateBreakTime: 'Mettre à jour le temps de pause',
    latestBreakStart: 'Dernier début de la pause',
    radioButtons: {
      noBreakTime: 'Aucun temps de pause',
      timeWindow: 'Fenêtre temporelle',
      workingTime: 'Temps de travail'
    },
    workingTimeDescription:
      'Vous définissez le temps de travail maximal avant que le conducteur ne doive prendre une pause. Par exemple, après 8 heures, le conducteur doit prendre une pause de 30 minutes.',
    maxWorkingTime: 'Temps de travail maximum',
    selectMaxWorkingTime: 'Sélectionner le temps de travail maximum',
    hours: '{{hours}} heures',
    breakTooltip: "Vous ne pouvez avoir qu'un seul type de pause par unité.",
    noCouriersExplain: "Nous n'avons trouvé aucun résultat. Veuillez essayer de rechercher avec un autre terme.",
    unitId: "Identifiant d'unité",
    minShipmentWeightKg: "Poids minimum d'une expédition sur ce véhicule (kg)",
    maxShipmentWeightKg: "Poids maximum d'une expédition sur ce véhicule (kg)",
    noAvailableHatchInParcelBoxMachine: 'Pas de trappe disponible',
    packageDoesNotFitInParcelBox: 'Le colis dépasse les dimensions/poids autorisés',
    unknownErrorForParcelBoxDelivery: 'Autre inconnu',
    errorInParcelBoxMachine: 'La machine est en panne',
    euroClassTypes: { euroEL: 'Euro EL', euro5: 'Euro 5', euro6: 'Euro 6' },
    co2: 'CO2',
    inCurrentDepartment: 'Service actuel',
    couriersInOtherDepartments: "Coursiers dans d'autres services",
    inOtherDepartments: 'Différents services',
    confirmConnect: 'Associer un coursier au service actuel',
    searchForCouriers: 'Rechercher des coursiers',
    dateNotValid: 'Date non valide'
  },
  slot: {
    minutesDelay: 'minutes de retard',
    minutesAhead: "minutes d'avance",
    time: 'Heure',
    created: 'Créé',
    assigned: 'Affecté',
    lock: 'Verrouiller',
    lockDescription:
      "Lorsque la tournée est verrouillée, elle ne fera pas partie des optimisations d'itinéraire futures et ne peut pas être modifiée.",
    toggleVisibleSlotDescription: 'Masquer la tournée.',
    inProgress: 'En cours',
    onHold: 'En attente',
    complete: 'Terminé',
    unknownState: 'État inconnu',
    courierSelect: 'Sélectionner un coursier',
    unitSelect: "Sélectionner l'unité",
    printableSlotList: 'Liste de tournées imprimable',
    followsRoute: 'Suit',
    doesNotFollowRoute: 'Ajouter un échec',
    hasNoLocation: 'Non visible',
    showActiveSlots: 'Afficher tournées en cours',
    showSlotNotStarted: 'Afficher tournées commencées',
    showCompletedSlot: 'Afficher tournées terminées',
    showPerfectSlot: 'Afficher tournées parfaites',
    filterSlots: 'Filtrer les tournées',
    planningCompleted: 'Terminé',
    addMapArea: 'Ajouter la zone de la carte',
    editMapArea: 'Modifier la zone de la carte'
  },
  login: {
    heading: 'Connexion',
    phone:
      "Entrez votre numéro de téléphone enregistré auprès de Glow avec l'indicateur pays (p. ex. +47...), et nous vous enverrons un jeton SMS",
    code: 'Jeton envoyé, si vous avez un utilisateur enregistré avec ce numéro. Il est valide pendant 5 minutes. Entrer le jeton.',
    requestNewCode: 'Demander un autre code',
    verifyFailed:
      'Le jeton ou le numéro de téléphone est incorrect, ou vous avez fait un nombre excessif de tentatives. Réessayez ou demandez un autre code',
    requestFailed: "Impossible d'envoyer un jeton. Réessayez plus tard.",
    verifyTokenButton: 'Vérifier le jeton',
    requestTokenButton: 'Demander un jeton',
    privacyLink: 'Politique de confidentialité',
    passwordWrong: 'Mot de passe incorrect',
    customer: 'Je suis un(e) client(e)',
    forgotPassword: 'Oublié votre mot de passe ?',
    tryApp: 'Essayez la nouvelle appli !',
    appDownload: "Téléchargez l'appli de coursier ici et profitez d'un nombre de fonctions encore plus grand !",
    appInfo:
      "Êtes-vous un coursier possédant un smartphone Android ? Téléchargez l'appli de pilotes <1>ici</1> et profitez d'un nombre de fonctions encore plus grand !",
    webApp: 'Application Web',
    note: "Remarque ! Utilisez uniquement la version Web de l'appli de coursier si, pour une raison quelconque, vous ne pouvez pas utiliser les applis pour iOS et Android",
    resendToken: 'Envoyer à nouveau le jeton',
    submitPassword: 'Soumettre le mot de passe',
    loginAsCustomer: 'Se connecter en tant que client',
    loginAsPlanner: 'Se connecter en tant que planificateur',
    token: 'Code',
    password: 'Mot de passe',
    tokenRequired: 'Le code est requis',
    forgotPasswordDescription:
      'Pour continuer, vous devez définir un mot de passe. Nous vous enverrons un e-mail avec un lien pour le définir. Si votre adresse e-mail a changé, vous devez contacter un administrateur pour la modifier.',
    sendEmailDescription:
      "Pour continuer, nous avons besoin de votre adresse e-mail. Nous vous enverrons un lien de confirmation à l'adresse fournie. Avec ce lien, vous pouvez définir votre mot de passe à deux facteurs.",
    emailFormatValidation: "L'e-mail doit être dans un format valide",
    sendEmailSuccess: "L'e-mail devrait arriver sous peu",
    sendEmailFailed: 'L’envoi de l’e-mail a échoué, réessayez',
    setYourPassword: 'Définir votre mot de passe',
    sendEmail: "Envoyer l'e-mail",
    passwordsDontMatch: 'Les mots de passe ne correspondent pas',
    passwordNotMeetRequirements: 'Le mot de passe ne répond pas aux exigences',
    passwordRequired: 'Le mot de passe est obligatoire',
    newPassword: 'Nouveau mot de passe',
    repeatPassword: 'Répétez votre mot de passe',
    lowercaseLetter: 'Une lettre minuscule',
    uppercaseLetter: 'Une lettre majuscule',
    numberCharacter: 'Un chiffre',
    minimumCharacters: 'Minimum 15 caractères',
    passwordDescription: 'Vous pouvez maintenant définir votre mot de passe, il doit contenir les éléments suivants'
  },
  error: {
    '404Title': 'Page introuvable',
    '404Message': 'Impossible de trouver la page que vous recherchez',
    '500Message': '{{error}} - Désolé, impossible de trouver vos données... nous réessayerons dans une minute...',
    title: "Une erreur s'est produite !",
    dateRequiredWhenSecureLogin: '*Champ obligatoire si l’identifiant bancaire est active',
    dateNotValid: 'Date non valide',
    errorMessage:
      "Nous sommes désolés ! Cela n'a pas fonctionné comme prévu, mais les développeurs ont été avisés de cette erreur.",
    errorClickToReload: 'Cliquez ici pour recharger la page',
    phoneNumber: 'Vous devez préciser numéro de téléphone valide, p. ex. +47 12345678',
    phoneNumberPrefix: "Vous devez préciser l'indicateur pays, p. ex. +47...",
    somethingWentWrong: "Une erreur s'est produite pendant l'opération d'enregistrement...",
    notAValidNumber: 'Numéro non valide',
    notAValidAlphaNumeric: 'Doit contenir des chiffres, les lettres sont optionnelles',
    notAValidDate: 'Doit être une date valide (aaaa-mm-jj)',
    notAValidTime: 'Doit être une heure valide (hh:mm)',
    notAValidDuration: 'Doit être une durée valide (mm:ss)',
    notAValidZipCode: 'Doit être un code postal valide',
    notAValidTimezone: 'Doit être un fuseau horaire valide. Essayez « Europe/Oslo » ou une entrée similaire',
    somethingWentWrongOnTheServer: "Une erreur s'est produite sur le serveur...",
    somethingWentWrongWithTheRequest: "Une erreur s'est produite avec la requête...",
    somethingWentWrongWithTheResponse: "Une erreur s'est produite avec la réponse...",
    selectAtLeastOne: 'Vous devez sélectionner au moins une option',
    invalidEmail: 'Vous devez fournir une adresse e-mail valide, ex. name@gmail.com',
    requiredBreakDuration: 'Vous devez entrer la durée de la pause',
    notAValidTimeWindow: 'Le début de la pause la plus tôt doit être avant la plus tardive',
    requiredEarliestStartBreak: 'Vous devez entrer l’heure de début de pause la plus tôt',
    requiredLatestStartBreak: 'Vous devez entrer l’heure de début de pause la plus tardive',
    requiredIfWorkingTimeDuration: 'Vous devez entrer la durée maximale de travail',
    invalidUrlParameterCombination:
      'Cette combinaison de paramètres d’URL est invalide et la page ne peut pas être affichée',
    numberCannotContainDecimals: 'Le nombre ne peut pas contenir de décimales'
  },
  loading: 'Extraction de vos données en cours...',
  newAppVersion: "Glow s'est encore amélioré, nous allons maintenant extraire la nouvelle version.",
  loadingReceiptList: 'Les listes de signatures sont en cours de création...',
  loadingLabelUrl: "La génération d'URL de PDF d'étiquette est en cours...",
  invoicing: { departmentInvoicingInProgress: 'La facturation est en cours...' },
  months: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  },
  weekdays: {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche'
  },
  weekdaysShort: {
    sunday: 'Dim',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mer',
    thursday: 'Jeu',
    friday: 'Ven',
    saturday: 'Sam'
  },
  maps: { addressLookup: "Recherche d'adresse" },
  notifications: {
    dismiss: 'Rejeter',
    dismissAll: 'Rejeter tout',
    error: 'Erreur',
    reload: 'Rafraîchir',
    reloadCourier: 'Appuyer ici pour recharger',
    navigate: 'Accéder à la page',
    networkError:
      "Il semble qu'il y ait des problèmes de réseau, ils sont peut-être temporaires. Toutefois, la page doit être rechargée.",
    networkErrorCourier: 'Vous avez été hors ligne, veuillez recharger.'
  },
  modals: {
    preplan: {
      create: "Préplanifier l'expédition",
      change: 'Changer le livreur prévu',
      shipmentInfo: "Préplanifier l'expédition {{shipmentId}} (de {{pickupName}} à {{deliveryName}})",
      pickDriver: 'Choisir un livreur.',
      unpreplan: 'Annuler une préplanification',
      preplan: 'Préplanifier',
      error: "Impossible de préplanifier l'expédition",
      notification: {
        preplanned: 'Préplanifiée',
        unpreplanned: 'Non préplanifiée',
        preplannedMessage: "L'expédition était préplanifiée pour {{courierId}}",
        unpreplannedMessage: "L'expédition n'était pas préplanifiée"
      }
    },
    maps: {
      save: 'Enregistrer les modifications',
      cancel: 'Annuler',
      delete: 'Supprimer',
      editMode: 'Modifier le mode',
      mapArea: 'Zone de la carte {{routeName}}',
      saved: 'Enregistré(e)',
      deleted: 'Supprimé(e)'
    }
  },
  grid: {
    columns: {
      addressWashRecipientId: 'ID du destinataire',
      addressWashAddressId: "ID de l'adresse",
      palletSpace: 'Emplacement de palette',
      department: 'Département',
      deadline: 'Délai limite',
      failedReason: "Message d'erreur",
      dispatchAsGroup: 'Expédier comme groupe',
      name: 'Nom',
      senderName: "Nom de l'expéditeur",
      puearly: 'ENL',
      pulate: 'ENL',
      delearly: 'LIV',
      dellate: 'LIV',
      vas: 'SAV',
      zip: 'Code postal',
      estimated: 'Heure estimée',
      adjusted: 'Ajusté(e)',
      timeWindow: 'Créneau horaire',
      onCar: 'Dans la voiture',
      frequency: 'Fréquence',
      startDate: 'Date de début',
      endDate: 'Date de fin (facultatif)',
      from: 'De',
      to: 'A',
      days: 'Jours',
      holidays: 'Jours fériés',
      startdate: 'Date de début',
      enddate: 'Date de fin',
      orders: 'Commandes',
      id: 'ID',
      shipmentId: 'ID expédition',
      packageId: 'ID colis',
      errorMessage: "Message d'erreur",
      customer: 'Client',
      pickup: 'Enlèvement',
      delivery: 'Livraison',
      address: 'Adresse',
      zipcode: 'Code postal',
      area: 'Région',
      weight: 'Poids',
      qty: 'Qté',
      preplanned: 'Préplanifié(e)',
      preplannedRouteTemplate: 'Itinéraire',
      group: 'Groupe',
      totalOrders: 'Total des commandes',
      ordersFailed: 'Commandes non abouties',
      date: 'Date',
      finished: 'Terminée',
      failed: 'Non aboutie',
      ordersInvoiced: 'Commandes facturées',
      start: 'Départ',
      stop: 'Arrêt',
      pickupContactPerson: "Personne de contact pour l'enlèvement",
      pickupPhone: "Téléphone pour l'enlèvement",
      deliveryContactPerson: 'Personne de contact pour la livraison',
      deliveryPhone: 'Téléphone pour la livraison',
      contactPerson: 'Personne de contact',
      phone: 'Téléphone',
      deliveryReferencePlaceholder: 'Référence du destinataire',
      specificationDescriptionPlaceholder: 'Informations sur les marchandises',
      multileg: 'Plusieurs étapes',
      groupName: 'Nom du groupe',
      customerNumber: 'Client no',
      orderNote: 'Bon',
      returned: 'Retourné(e)',
      price: 'Prix',
      slotName: 'Tournée',
      waitingTime: "Temps d'attente",
      timeOfDelivery: 'HdL',
      timeOfPickup: 'HdE',
      deliveryEtaSmsSentAt: "Liv. Un SMS d'ETA e a été envoyé",
      trip: 'Tournée',
      addresses: {
        title: 'Adresses',
        search: "Recherche d'adresses",
        searchAddresses: 'Rechercher des adresses',
        filterAddresses: 'Filtrer les adresses',
        deleteConfirmation: 'Voulez-vous vraiment supprimer cette adresse ?',
        deleteMultipleConfirmation: 'Voulez-vous vraiment supprimer toutes les adresses sélectionnées ?',
        deleteSuccess: "L'adresse a été supprimée avec succès",
        deleteMultipleSuccess: 'Les adresses ont été supprimées avec succès',
        delete: 'Supprimer',
        name: 'Nom',
        phone: 'Numéro de téléphone',
        address: 'Adresse',
        zipArea: 'Région',
        zipCode: 'Code postal',
        country: 'Pays',
        contactPerson: 'Personne de contact',
        deleteAddress: "Supprimer l'adresse",
        deleteMultipleAddresses: 'Supprimer les adresses ({{count}})',
        loc: 'Empl',
        locTitle: 'Emplacement'
      },
      orderGrid: { vehicleType: 'Véhicule', extraInfo: 'Autres infos' },
      dispatch: {
        id: 'ID',
        address: 'Adresse',
        adjusted: 'Ajusté(e)',
        alreadyAssigned: '<< Déjà affecté(e)',
        customerName: 'Nom du client',
        name: 'Nom',
        timeWindow: 'Créneau horaire'
      },
      orderSource: 'Source de la commande',
      postalCodes: 'Codes postaux #',
      displayEndCustomerPriceShd: 'Afficher le prix client',
      courier: 'Coursier',
      createdBy: 'Créé(e) par',
      plannedDeliveryDate: 'Date prévue',
      orderedDeliveryDate: 'Date commandée',
      lastUpdatedAt: 'Dernière mise à jour à',
      route: 'Itinéraire',
      pickupDuration: 'Durée enlèvement',
      deliveryDuration: 'Durée livraison',
      originalPickupAddress: "Adresse d'expéditeur d'origine",
      originalPickupZipCode: "Code postal d'expéditeur d'origine",
      originalPickupZipArea: "Ville d'expéditeur d'origine",
      originalPickupCountry: "Pays d'expéditeur d'origine",
      originalDeliveryAddress: "Adresse de livraison d'origine",
      originalDeliveryZipCode: "Code postal de livraison d'origine",
      originalDeliveryZipArea: "Ville de livraison d'origine",
      originalDeliveryCountry: "Pays de livraison d'origine",
      packagesArrivedAtDip: 'Arrivé(e)',
      packagesCollected: 'Enlèvement effectué',
      packagesDelivered: 'Livraison effectuée',
      originalDeliveryEarliest: "LIV d'origine",
      originalDeliveryLatest: "LIV d'origine"
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Référence client – visible sur la facture',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – visible sur la facture',
    invoiceInfoPlaceholder: 'Information de facture – visible sur la facture',
    confirmed: {
      header: 'Merci !',
      text: "Votre commande est maintenant réservée. Des mises à jour sur l'enlèvement ou la livraison seront envoyées à {{email}}.",
      support: "Avez-vous des questions concernant votre commande ? Appelez le service d'assistance au 04050.",
      book: "Aimeriez-vous passer d'autres commandes à un prix intéressant ? <0>Contactez-nous</0> pour obtenir un devis."
    },
    pickupTimePlaceholder: "Première heure d'enlèvement",
    error: {
      notInRangeZipCode: 'Erreur',
      notInSameZipCodeRange: "Nous n'offrons pas ce service dans la zone choisie",
      notInAreaRangeZipCode: 'Le code postal ne correspond à aucun code de la zone',
      pickupTimeBeforeEarliestServicePickupTime: 'Erreur',
      pickupNotAvailableForWeekend: "Le service d'enlèvement n'est pas disponibles les weekends",
      invalidOrganisationNumber: "Numéro d'organisation non valable"
    }
  },
  instant: {
    title: 'Dispatch',
    openMap: 'Ouvrir la carte',
    group: 'Groupe sélectionné',
    editGroup: 'Modifier le groupe',
    groupName: 'Nom du groupe',
    groupOrders: 'Commandes du groupe',
    viewGroup: 'Voir le groupe',
    ordersToGroup: 'Commandes pour le groupe',
    fixedOrder: 'Ordre de livraison fixe',
    paidTogether: 'Paiement regroupé',
    saveGroup: 'Enregistrer le groupe',
    ungroup: 'Dégrouper',
    removeSelected: 'Supprimer la sélection',
    split: 'Diviser la sélection',
    noCourierSelected: 'Aucun coursier sélectionné',
    noOrderSelected: 'Aucune commande sélectionnée',
    workingHours: 'Heures de travail',
    chatButton: 'Tchatter avec le livreur',
    offeredShipments: 'Expéditions offertes',
    rejectedShipments: 'Expéditions refusées',
    rejectedShipmentsSnackbar: '{{count}} expédition refusée par "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} expéditions refusées par "{{name}}"',
    toBeDone: 'Enlèvements et livraisons à effectuer',
    finishedRoutePoints: 'Enlèvements et livraisons terminés',
    suggestedRouteApplied: "L'itinéraire suggéré a été appliqué",
    suggestedRouteCancelled: "L'itinéraire suggéré a été annulé",
    reorderValidationError: "L'ordre de l'itinéraire suggéré est invalide",
    planSlot: "Plan d'itinéraire",
    columnSettings: {
      reset: 'Rétablir',
      settings: 'Paramètres',
      hideColumns: 'Afficher les colonnes',
      saveAndClose: 'Enregistrer et fermer'
    },
    routePoint: { pickup: 'E', delivery: 'L' },
    confirmation: {
      heading: 'Confirmation de commande',
      from: 'De',
      to: 'A',
      pickup: 'Enlèvement',
      delivery: 'Livraison',
      shipmentNumber: "Numéro d'expédition",
      printLabel: "Imprimer l'étiquette",
      copyOrder: 'Copier la commande',
      editOrder: 'Modifier la commande'
    },
    goods: {
      custom: 'Personnaliser',
      letter: 'Lettre',
      bag: 'Poche',
      smallbox: 'Boîte petit format',
      mediumbox: 'Boîte moyen format',
      largebox: 'Boîte grand format',
      halfpallet: 'Demi-palette',
      quarterpallet: 'Quart de palette',
      fullpallet: 'Palette complète',
      suitcase: 'Valise',
      movingbox: 'Carton de déménagement',
      trolley: 'Chariot',
      parcel: 'Colis'
    },
    instantGrid: {
      editColumnTooltip: 'Cliquez pour modifier',
      deleteColumnTooltip: 'Cliquez pour supprimer',
      unassignColumnTooltip: "Cliquez pour annuler l'affectation d'une expédition",
      dragColumnTooltip: 'Cliquez et faites glisser pour affecter à un coursier',
      filtersActive: 'Les filtres sont actifs',
      clearFilters: 'Supprimer tous les filtres',
      filterPlaceholder: 'filtre <entrer>'
    },
    booking: {
      standaloneInvoiceCheckbox: 'Marquer comme facture indépendante',
      standaloneInvoiceDescription:
        "En sélectionnant cette option, cette commande sera traitée individuellement et ne sera pas regroupée avec d'autres commandes pour la facturation.",
      addPackage: 'Ajouter un nouveau forfait',
      removePackageRow: 'Supprimer le paquet',
      cannotRemoveLastPackageRow: 'Impossible de supprimer le dernier paquet',
      copyPackageRow: 'Copier le paquet',
      title: 'Réservation',
      header: 'Réservation',
      headerCreate: 'Nouvelle réservation',
      headerEdit: 'Modifier la réservation',
      headerCopy: 'Copier la réservation',
      headerRestore: 'Restaurer la réservation',
      customer: 'Client',
      customerPlaceholder: 'ID Client',
      priceDetails: 'Détails des prix',
      subcustomerPlaceholder: 'Client ultérieur',
      invalidSubcustomer: 'Le nom du sous-client est invalide',
      invoiceSplitPlaceholder: 'Division de les factures',
      invoiceSplitPlaceholderLegend: 'Sélectionner la division de les factures',
      shipmentOrPackageId: 'ID expédition ou ID colis',
      creditBlockedCustomer: 'Client au crédit bloqué',
      sender: 'Expéditeur',
      setAsDefaultAddress: 'Définir comme adresse par défaut',
      defaultAddressUpdated: 'Adresse par défaut mise à jour',
      recipient: 'Destinataire',
      pickupNamePlaceholder: 'Nom',
      pickupAddressPlaceholder: 'Adresse',
      pickupZipCodePlaceholder: 'Code postal',
      pickupZipAreaPlaceholder: 'Ville',
      pickupCountryPlaceholder: '-- Sélectionner le pays--',
      pickupPhoneNumberPlaceholder: 'Téléphone',
      pickupContactPerson: 'Personne de contact',
      customerReference: 'Référence client',
      pickupReferencePlaceholder: 'Référence client – Ce champ est visible sur la facture du client',
      pickupInformationPlaceholder: "Information d'enlèvement",
      customerContact: 'Contact client',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – Ce champ est visible sur la facture du client',
      customerInfoPlaceholder: 'Informations générales propres au client',
      customerInfoHelper:
        'Ces informations se rapportent au client. Les modifications apportées seront visibles dans toutes les commandes liées à ce client.<br><br>Pour ajouter des informations propres à une commande, utilisez le champ de saisie « Remarque » ci-dessous.',
      deliveryNamePlaceholder: 'Nom',
      deliveryAddressPlaceholder: 'Adresse',
      deliveryZipCodePlaceholder: 'Code postal',
      deliveryZipAreaPlaceholder: 'Ville',
      deliveryCountryPlaceholder: '-- Sélectionner le pays--',
      deliveryPhoneNumberPlaceholder: 'Téléphone',
      deliveryReferencePlaceholder: 'Référence du destinataire',
      deliveryInformationPlaceholder: 'Informations de livraison',
      orderNote: 'Bon',
      internalNote: 'Note interne',
      invalidInvoiceSplit: 'Texte de fractionnement de facture non valide',
      clearData: 'Effacer les données',
      dimensions: 'Marchandises',
      weightPlaceholder: 'Poids (kg)',
      lengthPlaceholder: 'Longueur (cm)',
      widthPlaceholder: 'Largeur (cm)',
      heightPlaceholder: 'Hauteur (cm)',
      serviceCode: 'Code de service',
      servicePlaceholder: 'Code de service',
      serviceSelect: 'Sélectionner le service',
      serviceSelectDisabled: 'Toute modification des services sur les commandes HD doit être effectuée dans HappyFlow',
      goodsEditDisabled: 'Toute modification des Marchandises sur les commandes HD doit être effectuée dans HappyFlow',
      standardServices: '-- SERVICES STANDARD --',
      customizedServices: '-- SERVICES UNIQUES CLIENT --',
      specificationDescription: 'Marchandises',
      specificationDescriptionPlaceholder: 'Informations sur les marchandises',
      invoiceInfo: 'Informations de facturation',
      invoiceInfoPlaceholder: 'Informations de facturation – Ce champ est visible sur la facture du client',
      manualInvoiceInfoPlaceholder:
        'Gestion manuelle des factures (si ce champ est renseigné avec des instructions de facturation, la commande ne sera pas automatiquement facturée)',
      pickup: 'Enlèvement',
      delivery: 'Livraison',
      pickupDatePlaceholder: "Date d'enlèvement",
      pickupTimePlaceholder: "Heure d'enlèvement",
      deliveryDatePlaceholder: 'Date de livraison',
      deliveryTimePlaceholder: 'Heure de livraison',
      deliveryContactPerson: 'Personne de contact',
      restoreButton: 'Restaurer',
      orderButton: 'Commander',
      editButton: 'Mettre à jour',
      copyButton: 'Copier',
      created: 'La commande a été enregistrée',
      edited: 'La commande a été mise à jour',
      quickSelect: 'Sélection rapide',
      totalVolume: 'Volume total {{volume}}',
      totalWeight: 'Poids total {{weight}}',
      removeItem: 'Supprimer',
      copyItem: 'Copier',
      addItem: 'Ajouter',
      calculatePrice: 'Calculer le prix',
      priceExplanations: {
        header: 'Explication du prix',
        priceListName: 'Liste de prix:: {{pricelist}}',
        explanations: 'Explications',
        noInfo: 'Aucune information de prix disponible',
        details: 'Détails',
        notFound: 'Aucune explication de prix disponible.'
      },
      approveIncompletePrice: "J'approuve toutes les lignes avec un prix zéro",
      prePlan: 'Préplanifier',
      prePlannedToCourier: 'Sélectionner un coursier',
      prePlannedToRouteTemplate: "Sélectionnez le modèle d'itinéraire",
      prePlannedToRouteText:
        "L'itinéraire disponible dépend des modèles enregistrés dans le département. Pour que la planification préétablie fonctionne correctement, vous devez configurer les paramètres d'itinéraire au <0>niveau du département</0> dans les pages d'administration.",
      automaticDepartment: 'Automatique',
      departmentLabel: 'Département',
      notifications: 'Notifications',
      emailNotifications: 'Notifications par e-mail',
      eventRegistered: 'Enregistrer',
      eventPickup: 'Enlèvement',
      eventDelivery: 'Livraison',
      emailAddress: 'Adresse électronique',
      emailRecipientText: 'Des notifications seront envoyées aux adresses e-mail suivantes :',
      invalidEmail: 'Adresse e-mail invalide',
      editIndicator: 'Modifier',
      updateIndicator: 'Mettre à jour',
      copyIndicator: 'Copier',
      quantity: 'Quantité',
      additionalServices: 'Services additionnels',
      paymentInformation: 'Informations de paiement',
      paidTogetherLabel: 'Paiement regroupé ?',
      groupingLabel: 'Regroupement des expéditions',
      groupIdPlaceholder: 'ID de groupe unique',
      groupOrderPlaceholder: "Commande d'expédition de groupe",
      addRecipient: 'Ajouter un destinataire supplémentaire',
      addPickup: 'Ajouter un expéditeur supplémentaire',
      vehicleType: 'Type de véhicule',
      vehicleTypeUpdated: 'Type de véhicule mis à jour',
      selectVehicleType: 'Sélectionner le type de véhicule',
      customerPrice: 'Prix client',
      resourcePrice: 'Prix unitaire',
      priceList: 'Liste de prix',
      manualPrice: 'Manuel',
      vehicleTypes: {
        car: 'Voiture',
        bicycle: 'Bicyclette',
        truck: 'Camion',
        van: 'Camionnette',
        motorcycle: 'Moto',
        cargobike: 'Vélo cargo'
      },
      unNumber: 'No ONU',
      hazardous: 'Classe',
      group: 'Groupe',
      netWeight: 'Poids net',
      name: 'Nom',
      totalPoints: 'Points totaux'
    },
    courierSorting: { title: 'Tri par', alystraId: 'Numéro de livreur', empty: 'Vide', full: 'Plein' },
    courierFilter: {
      title: 'Filtrer les coursiers par',
      all: 'Afficher tout',
      inactive: 'Masquer les éléments inactifs',
      offline: 'Masquer les éléments hors ligne',
      both: 'Masquer les éléments inactifs et hors ligne'
    },
    invoicingFilter: {
      ALL: 'Afficher tout',
      INVOICED_OK: 'Déjà facturé(e)',
      TO_BE_INVOICED: 'À facturer',
      ORDER_CORRECTION: 'Avec correction de commande',
      INVOICED_FAILED: 'Echec de facturation'
    },
    shipmentFilter: {
      title: 'Filtrer les expéditions',
      showAll: 'Afficher tout',
      notCollected: 'Enlèvement non effectué',
      hideAll: 'Masquer tout'
    },
    offer: {
      carEmpty: 'Voiture actuellement vide',
      suggestedDelivery: 'Ordre de livraison suggéré',
      newShipments: 'Nouvelles expéditions',
      manualRoute: 'Itinéraire manuel',
      confirmRoute: "Confirmer l'itinéraire",
      assigning: 'Affectation en cours',
      replanning: "Replanification de l'itinéraire pour",
      shipmentsTo: 'les expéditions à',
      shipmentTo: "l'expédition à"
    },
    useFlexible: 'Utiliser des horaires flexibles',
    forceOptimizing: "Forcer l'optimisation de la commande",
    replanRoute: "Replanifier l'itinéraire",
    recurring: {
      title: 'Récurrent(e)',
      successMessage: '{{number}} expédition(s) a(ont) été ajoutée(s) aux groupes récurrents sélectionnés',
      recurringOrders: 'Commandes récurrentes',
      includedOrders: 'Commandes incluses dans la tournée',
      details: 'Détails',
      editOriginal: "Modifier l'original",
      copyOriginal: "Copier l'original",
      notStarted: 'Non commencé(e)',
      executing: 'Exécution en cours...',
      ended: 'Terminé(e)',
      status: 'Statut',
      pending: 'En attente',
      failedOrders: 'Echec des commandes',
      exceptionsHeading: 'Exceptions pour les commandes',
      addExceptionHeading: "Nouvelle période d'exception",
      exceptionStart: 'Départ',
      exceptionEnd: 'Fin',
      editExceptions: 'Modifier les exceptions',
      addRecurring: 'Récurrent(e)',
      addToGroup: 'Sélectionner le groupe récurrent',
      recurringOrderAddedConfirmation: 'Commande ajoutée au groupe récurrent',
      exceptionAddedConfirmation: 'Exception ajoutée',
      dispatchAsGroup: 'Expédier comme groupe',
      name: 'Nom',
      frequency: 'Fréquence',
      frequencies: {
        weekly: 'Toutes les semaines',
        second_week: 'Toutes les deux semaines',
        third_week: 'Toutes les trois semaines',
        monthly: 'Chaque mois'
      },
      daysOfWeek: 'Jours de la semaine',
      includeHolidays: 'Y compris jours fériés',
      startDate: 'Date de début',
      endDate: 'Date de fin (facultatif)',
      save: 'Enregistrer',
      fromDate: 'Date de début',
      confirmDelete: 'Voulez-vous vraiment supprimer la ou les commandes récurrentes ?',
      from: 'De',
      createOrders: 'Créer des commandes',
      showResult: 'Afficher le résultat',
      startdate: 'Date de début',
      enddate: 'Date de fin',
      orders: 'Commandes',
      failed: 'Non aboutie',
      by: 'Par',
      on: 'le',
      reset: 'rétablir',
      confirmReset: 'Voulez-vous vraiment rétablir les commandes récurrentes pour le {{date}} ?',
      startDateExplanation: 'Les commandes seront créées le même jour que la date de début'
    },
    corrections: {
      headline: 'Corrections de commande pour le {{date}}',
      accepted: 'Corrections de commande acceptées',
      rejected: 'Corrections de commande refusées ',
      approve: 'APPROUVER',
      decline: 'REFUSER',
      shipmentId: 'ID expédition',
      courier: 'Coursier',
      from: 'De',
      to: 'A',
      details: 'Détails',
      pickupWaitingTime: "Temps d'attente enlèv.",
      deliveryWaitingTime: "Temps d'attente livr.",
      pickupLoadingTime: 'Temps de chargement enlèv.',
      deliveryLoadingTime: 'Temps de chargement livr.',
      comment: 'Commentaires',
      weight: 'Poids',
      packages: 'Colis',
      serviceCode: 'Code de service'
    },
    dispatch: {
      assigningMultilegDelivery: 'Affectation de {{count}} livraison à plusieurs escales en cours',
      assigningMultilegDelivery_plural: 'Affectation de {{count}} livraisons à plusieurs escales en cours',
      turningThisOnExplanation: '(Activer cela vous permet de remplacer le plan de la tournée de livraison)'
    },
    suggestedUnits: {
      unitName: "Nom de l'appareil",
      driverName: 'Nom du conducteur',
      slotName: 'Itinéraire',
      timeAtLocation: 'Temps en place',
      distanceFromLocation: 'Distance du lieu',
      shipmentInformation: 'Informations sur les transmissions',
      suggestedVehicles: 'Véhicules suggérés',
      suggestedVehiclesOnPickup: 'Véhicules suggérés pour le ramassage',
      suggestedVehiclesOnDelivery: 'Véhicules suggérés pour la livraison',
      suggestedUnits: 'Unités suggérées',
      noSuggestionsAvailable: 'Aucune suggestion disponible',
      pickupTime: 'Heure de ramassage',
      deliveryTime: 'Heure de livraison',
      filterBy: 'Filtrer par'
    }
  },
  errors: {
    ALREADY_ENQUEUED_FOR_INVOICING: "Déjà en file d'attente pour facturation",
    DUPLICATE: 'Données dupliquées',
    MISSING_DEPARTMENT_DETAILS:
      'Le département ne dispose pas d’informations sur la zone et le code postal. Veuillez les ajouter avant de continuer.',
    DEPARTMENT_INVALID_ALYSTRA_ID: 'ID alystra de département invalide',
    ENTITY_DOES_NOT_EXIST: "L'entité n'existe pas",
    INVALID_DATE_RANGE: 'Plage de dates invalide',
    INVALID_DELIVERY_LATITUDE: 'Latitude de livraison invalide',
    INVALID_DELIVERY_LONGITUDE: 'Longitude de livraison invalide',
    INVALID_LATITUDE: 'Latitude invalide',
    INVALID_LONGITUDE: 'Longitude invalide',
    INVALID_OR_MISSING_TIME: 'Heure invalide ou manquante',
    INVALID_PHONE_NUMBER: 'Numéro de téléphone invalide',
    DUPLICATE_PHONE_NUMBER: 'Numéro de téléphone dupliquée !',
    DUPLICATE_PHONE_NUMBER_COURIER: 'Un conducteur est déjà enregistré avec ce numéro de téléphone',
    INVALID_PICKUP_LATITUDE: "Latitude d'enlèvement invalide",
    INVALID_PICKUP_LONGITUDE: "Longitude d'enlèvement invalide",
    INVALID_SLOT_TYPE: 'Type de tournée invalide',
    MISSING_ADDRESS: 'Adresse manquante',
    MISSING_ALYSTRA_ID: 'ID alystra manquante',
    MISSING_AREA: 'Région manquante',
    MISSING_CAPACITY: 'Capacité manquante',
    MISSING_COLOR: 'Couleur manquante',
    MISSING_CONSIGNMENT_INPUTS: "Données d'expédition manquantes",
    MISSING_CONSIGNMENT: 'Expédition manquante',
    MISSING_CONSIGNMENTS: 'Expéditions manquantes',
    MISSING_COURIER_ID: 'ID coursier manquante',
    MISSING_COURIER: 'Vous devez sélectionner un coursier',
    MISSING_CREATED_AT: 'Champ Créé à manquant',
    MISSING_CUSTOMER_ID: 'ID client manquante',
    MISSING_CUSTOMER_REF: 'Réf. client manquante',
    MISSING_DATE: 'Date manquante',
    MISSING_DAYS: 'Jours manquants',
    MISSING_DEPARTMENT: 'Département manquant',
    MISSING_DESCRIPTION: 'Description manquante',
    MISSING_DEVIATION_TEXT: "Texte d'échec manquant",
    MISSING_END_LOCATION_LATITUDE: "Latitude du lieu d'arrivée manquante",
    MISSING_END_LOCATION_LONGITUDE: "Longitude du lieu d'arrivée manquante",
    MISSING_EVENT_DATA: "Données d'événement manquantes",
    MISSING_EVENT_TYPE: "Type d'événement manquant",
    MISSING_FILE_NAME: 'Nom de fichier manquant',
    MISSING_FROM_DATE: 'Champ Date de début manquant',
    MISSING_ID: 'ID manquante',
    MISSING_LOCATION_LATITUDE: 'Latitude du lieu manquante',
    MISSING_LOCATION_LONGITUDE: 'Longitude du lieu manquante',
    MISSING_LOCATION_POSITION: 'Position du lieu manquante',
    MISSING_LOCATION_TIME: 'Heure du lieu manquante',
    MISSING_NAME: 'Nom manquant',
    MISSING_GROUP: 'Groupement manquant',
    MISSING_PACKAGE_ID: 'ID colis manquante',
    MISSING_PHONE: 'No de téléphone manquant',
    MISSING_PLANNER_ID: 'ID planificateur manquante',
    MISSING_SERVICE_CODE: 'Code de service manquant',
    MISSING_SHIPMENT_ID: 'ID expédition manquante',
    MISSING_SLOT_ID: 'ID tournée manquante',
    MISSING_SLOT_TYPE: 'Type de tournée manquant',
    MISSING_SMS_TOKEN: 'Jeton SMS manquant',
    MISSING_START_LOCATION_LATITUDE: 'Latitude du lieu de départ manquante',
    MISSING_START_LOCATION_LONGITUDE: 'Longitude du lieu de départ manquante',
    MISSING_TO_DATE: 'Champ Date de fin manquante',
    MISSING_ZIP_AREA: 'Code régional manquant',
    MISSING_ZIP_CODE: 'Code postal manquant',
    MISSING_COUNTRY_CODE: 'Code pays manquant',
    NO_SUCH_USER: "L'utilisateur n'existe pas",
    PARAM_NOT_OF_EXPECTED_TYPE: 'Param pas du type prévu',
    PARAM_NULL_NOT_ALLOWED: 'Param nul non autorisé',
    QUERY_NAME_MISSING: 'Nom de requête manquant',
    QUERY_PARAMS_MISSING: 'Param de requête manquants',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: "L'utilisateur n'existe pas ou rôle incorrect",
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: "L'utilisateur existe déjà et a un autre rôle",
    SLOT_CANNOT_BE_STARTED: 'Impossible de commencer la tournée',
    SLOT_CANNOT_BE_DELETED: 'Impossible de supprimer la tournée',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Le coursier ne fait pas partie du département',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Marchandises dans un état incorrect, impossible de mettre à jour',
    ORDER_DOES_NOT_EXISTS: "La commande n'existe pas",
    OPTIMIZE_ALREADY_RUNNING: 'Optimisation déjà en cours',
    CANNOT_CHANGE_ORDER: 'Impossible de modifier la commande',
    NO_ACCESS: "Pas d'accès",
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Aucune expédition pertinente pour les tournées données',
    NO_RELEVANT_CONSIGNMENTS: 'Aucune expédition pertinente pour les tournées données',
    NO_RELEVANT_SLOTS:
      'Seuls les la tournée de livraisons non encore commencés ou en attente peuvent être optimisés en utilisant cette fonctionnalité',
    NOT_EQUAL_PICKUP_ADDRESSES:
      'Toutes les adresses de collecte doivent être identiques pour restreindre toutes les commandes à un seul conducteur',
    NO_SUGGESTIONS_FOUND_FOR_ORDERS: 'Aucun conducteur éligible pour les commandes.',
    NO_SUCH_QUERY: "La requête n'existe pas",
    EXCEL_MISSING_SHIPMENT_ID: 'ID expédition manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Réf. client manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'ID client manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Département manquant à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'ID colis manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_NAME: 'Nom manquant à la cellule {{col}} ligne {{row}}',
    EXCEL_INVALID_PHONE: 'Numéro de téléphone invalide à la cellule{{col}} ligne {{row}}',
    EXCEL_MISSING_ADDRESS: 'Adresse manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Code postal manquant à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Ville manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Date de début manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_TO_DATE: 'Date de fin manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_INVALID_FROM_DATE:
      "La date de début ne peut être antérieure à la date d'aujourd'hui. Trouvée à la cellule {{col}} ligne {{row}}",
    EXCEL_INVALID_TO_DATE:
      "La date de fin ne peut être antérieure à la date d'aujourd'hui. Trouvée à la cellule {{col}} ligne {{row}}",
    EXCEL_MISSING_NOTIFICATION_SMS: 'Notification SMS manquante à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_SIZE: 'Dimensions manquantes à la cellule {{col}} ligne {{row}}',
    EXCEL_MISSING_ORDERS: 'Pas de commande trouvée dans la feuille de calcul.',
    EXCEL_MISSING_COUNTRY: 'Code ou nom du pays manquant à la cellule {{col}} ligne {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Pas d\'accès au département "{{dept_ref}}" tel que trouvé à ligne {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Description manquante dans "{{cell}}" ligne {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Description enlèvement manquante dans "{{cell}}" ligne {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Description livraison manquante dans "{{cell}}" ligne {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: "Lieu et heure d'enlèvement manquants",
    MISSING_EARLIEST_PICKUP_DATE: "Première date d'enlèvement manquante",
    MISSING_LATEST_PICKUP_DATE: "Dernière date d'enlèvement manquante",
    MISSING_EARLIEST_DELIVERY_DATE: 'Première date de livraison manquante',
    MISSING_LATEST_DELIVERY_DATE: 'Dernière date de livraison manquante',
    INVALID_EARLIEST_PICKUP_DATE: "Date d'enlèvement invalide",
    INVALID_LATEST_PICKUP_DATE: "Date d'enlèvement invalide",
    INVALID_EARLIEST_DELIVERY_DATE: 'Date de livraison invalide',
    INVALID_LATEST_DELIVERY_DATE: 'Date de livraison invalide',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Impossible de modifier les commandes API dans Excel',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST:
      "L'heure d'enlèvement doit être antérieure à l'heure de livraison",
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST:
      'La première livraison doit être antérieure à la dernière livraison',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Impossible de livrer un jour férié',
    MISSING_TEMPLATE_ID: 'ID modèle manquante',
    TEMPLATE_DOES_NOT_EXIST: "Le modèle n'existe pas",
    TEMPLATE_CANNOT_BE_DELETED: 'Le modèle ne peut pas être supprimé',
    MISSING_TEMPLATE_SLOTS: 'Il manque des tournées dans le modèle',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Il existe déjà un modèle de ce nom',
    COURIER_ALREADY_EXISTS: 'Il existe déjà un autre coursier avec ce numéro de téléphone',
    ORDER_EXIST_FOR_SERVICE: 'Impossible de supprimer le service car il existe des commandes pour ce service',
    NO_SERVICE_FOUND: "Ajouter un service avant d'ajouter le profil client",
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Supprimer le profil de service client avant de supprimer le service',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: "La tournée n'est pas dans un état valide pour cette mise à jour",
    GRAPHHOPPER_UNASSIGNED:
      "Impossible d'affecter toutes les expéditions (en raison du facteur temps, capacité ou autre), détails : {{details}}",
    GRAPHHOPPER_REQUEST_FAILED: "Echec de la demande d'optimisation de l'itinéraire",
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      "La dernière arrivée au lieu de livraison ne peut pas être postérieure au premier départ – le créneau horaire d'enlèvement est-il dans le passé ?",
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST:
      'La première heure ne peut pas être postérieure à la dernière – certaines des heures sont-elles dans le passé ?',
    GRAPHHOPPER_PICKUP_EARLIEST_AFTER_LATEST:
      "Le créneau horaire d'enlèvement {{shipmentId}} ne peut pas être dans le passé ({{pickupEarliest}} - {{pickupLatest}})",
    GRAPHHOPPER_DELIVERY_EARLIEST_AFTER_LATEST:
      'Le créneau horaire de livraison {{shipmentId}} ne peut pas être dans le passé ({{deliveryEarliest}} - {{deliveryLatest}})',
    SLOT_CANNOT_BE_EDITED: "La tournée n'est pas dans un état valide pour cette mise à jour",
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Code postal et code pays invalides',
    INVALID_BASIC_SERVICE_CODE: 'Code de service de base invalide',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: "Format de première date d'enlèvement invalide",
    INVALID_PICKUP_DATE_LATEST_FORMAT: "Format de dernière date d'enlèvement invalide",
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Format de dernière date de livraison invalide',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Format de première date de livraison invalide',
    INVALID_POSTAL_CODE: 'Code postal invalide',
    INVALID_MEASUREMENT_UNIT: 'Unité de mesure invalide',
    MISSING_CONSIGNMENT_SET: 'Jeu de données des colis manquant',
    MISSING_UPDATE_INDICATOR: 'Indicateur de mise à jour manquant',
    MISSING_CONSIGNMENT_ID: 'ID expédition manquante',
    MISSING_REFERENCE_NO: 'ID référence manquante',
    MISSING_PACKAGE: 'Colis manquant',
    MISSING_DELIVERY_DATE_EARLIEST: 'Première date de livraison manquante',
    MISSING_DELIVERY_DATE_LATEST: 'Dernière date de livraison manquante',
    MISSING_CONSIGNEE: 'Destinataire manquant',
    MISSING_CONSIGNOR: 'Expéditeur manquant',
    MISSING_POSTAL_CODE: 'Code postal manquant',
    MISSING_CITY: 'Code régional manquant',
    MISSING_PICKUP_DATE_LATEST: "Dernière date d'enlèvement manquante",
    MISSING_PICKUP_DATE_EARLIEST: "Première date d'enlèvement manquante",
    MISSING_CONSIGNOR_PARTY_ID: 'ID partie destinataire manquante',
    INVALID_CUSTOMER_NO_MAPPING: 'Profil numéro de client invalide',
    INVALID_ROUTING: 'Acheminement invalide',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Aucun service ne correspond au code de service',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Aucun service ne correspond au jour de la semaine',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES:
      "Aucun service ne correspond aux heures d'enlèvement et de livraison",
    ORDER_WAS_AFTER_CUTOFF_TIME: "La commande était après l'heure limite",
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: "Heures d'enlèvement et de livraison obligatoires",
    ORDER_CANNOT_BE_MOVED: 'Impossible de déplacer la commande, elle ne doit pas être affectée à une tournée',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Département de destination manquant',
    SHIPMENT_NOT_FOUND: 'Expédition non trouvée',
    DUPLICATE_EXCEL_IMPORT:
      "Le même nom de fichier a déjà été importé dans ce département aujourd'hui. Vous pouvez voir les résultats de cette importation <0>ici</0>. Veuillez modifier le nom de fichier si vous souhaitez le réimporter.",
    noOrdersForDateAndCourier: 'Aucune commande présente pour la date et l’unité sélectionnées.',
    SHIPMENT_INVALID_QUERY:
      'Toute recherche couvrant les heures de pointe sera limitée pour optimiser la performance. Vous pouvez rechercher toutes les commandes pour une journée particulière ou ajouter un paramètre de recherche.',
    DURATION_IS_MORE: 'Merci de limiter votre recherche à une période de {{maxDuration}} jours.',
    ADDRESS_CANNOT_BE_DELETED: "L'adresse sert d'adresse par défaut et ne peut pas être supprimée.",
    MISSING_ORDER_BILLING_TYPE: 'Type de facturation de commande manquant.',
    UNKOWN_ERROR: 'Impossible d’enregistrer les modifications',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'État incorrect ou Affectée à la tournée',
    CANNOT_MOVE_ORDERS: 'Impossible de déplacer les commandes',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: "Le département ne comporte pas de détails d'adresse",
    MISSING_DELIVERY_PHONE_NUMBER: 'Le numéro de téléphone est manquant pour',
    INVALID_DELIVERY_PHONE_NUMBER: 'Le numéro de téléphone n’est pas valide pour',
    SMS_SERVICE_NOT_ORDERED: "Le service SMS n'est pas commandé pour.",
    SLOT_ROUTED_IN_HAPPY_FLOW: 'Le créneau est routé dans Happy Flow pour.',
    SERVICE_LEVEL_SHOULD_NOT_BE_NONE: 'Le niveau de service est aucun pour.',
    SLOT_NAME_ALREADY_CREATED: 'Le nom de l’itinéraire existe déjà.',
    ORDER_IN_INCORRECT_STATE: 'La commande est dans un état incorrect.',
    ORDER_IN_INCORRECT_STATE_plural: 'Les commandes sont dans un état incorrect.',
    ROLE_IN_USE: "Le rôle ne peut pas être supprimé car il est en cours d'utilisation.",
    INVALID_NAME: 'Le nom du rôle doit être unique. Un rôle avec ce nom existe déjà.',
    TEXT_CANNOT_BE_DELETED:
      'Le texte est utilisé dans un service et ne peut pas être supprimé. Déconnectez le texte du service pour le supprimer.',
    INVALID_INVOICING_STATUS: 'La commande a un statut non valide pour la facturation',
    ORDER_NOT_READY_FOR_INVOICING: "La commande n'est pas prête pour la facturation",
    ORDER_IS_PAUSED_FROM_INVOICING: 'La commande est suspendue de la facturation',
    ORDER_IS_EXCLUDED_FROM_INVOICING: 'La commande est exclue de la facturation',
    MISSING_ORDER_CORRECTION: 'La commande manque une correction',
    INVALID_MULTILEG_STATE_FOR_INVOICING:
      'Il y a des commandes dans le groupe multileg qui ne sont pas prêtes pour la facturation',
    INVALID_BANNER_NAME: 'Nom de bannière invalide.',
    INVALID_WHATS_THIS_NAME: "Nom invalide de la fenêtre Qu'est-ce que c'est.",
    INVALID_CREDIT_ORDER: 'Impossible de créditer une commande qui n’a pas été facturée ou qui a déjà été créditée.',
    OVERLAPPING_BANNERS: 'L’URL chevauche une bannière existante.',
    OVERLAPPING_WHATS_THIS_MODALS: "L’URL chevauche une fenêtre Qu'est-ce que c'est existante.",
    INVALID_ORDER_BILLING_CUSTOMER_NUMBER:
      'Le numéro client de facturation de la commande pour le type {{orderBillingType}} est soit nul, soit invalide.',
    INVALID_ORDER_BILLING_TYPE:
      "Le type de facturation de commande doit être l'un des suivants : {{orderBillingTypes}}, mais était - {{orderBillingType}}.",
    INVALID_ORDER_BILLING_INFO: 'Informations de facturation de commande invalides.',
    CANNOT_SEND_SMS_FOR_MESTER_GRONN_FUNERAL: 'Les funérailles Mester Grønn sont commandées pour.',
    INVALID_PARAMETERS: 'Paramètres invalides, {{description}}.',
    FAILED_DELETING_WEBHOOK_SUBSCRIPTION: 'L’abonnement Webhook n’a pas pu être supprimé. Réessayez plus tard.',
    DEFAULT_ROLE_ALREADY_EXISTS: "Un autre rôle {{name}} est déjà défini par défaut pour le même type d'utilisateur."
  },
  customer: {
    useTerminalAddressModalHeading: "Utiliser l'adresse du terminal",
    settings: { webhook: { overviewTitle: 'Configuration du webhook' } },
    invoiceSplit: { title: 'Fractionnement de la facture', NotSet: 'Non défini' },
    showAll: 'Afficher tous les clients',
    customer: 'Client',
    groups: {
      title: 'Groupes de clients',
      titleWithCount: '{{count}} groupes de clients',
      name: 'Nom du groupe',
      numberOfCustomers: 'Nombre de clients',
      none: 'Aucun groupe de clients',
      new: 'Nouveau groupe de clients',
      edit: 'Modifier le groupe de clients',
      searchCustomer: 'Rechercher un client',
      members: 'Clients du groupe',
      save: 'Enregistrer',
      deleteConfirm: 'Voulez-vous vraiment supprimer ce groupe de clients ?',
      deleted: 'Le groupe de clients a été supprimé.',
      cannotDelete:
        "Impossible de supprimer le groupe de clients car il est utilisé dans une règle d'avis préliminaire."
    },
    customerTitle: 'Clients',
    customerNo: 'Client no',
    name: 'Nom',
    emailToSender: "E-mail à l'expéditeur",
    deviations: 'Echecs',
    add: 'Ajouter',
    noOrders: 'Pas de commandes dans Glow',
    noOrdersDescription: "Les clients auxquels vous avez accès n'ont pas de commandes dans Glow",
    choose: 'Veuillez sélectionner un client',
    findCustomer: 'Trouver un client',
    searchAndSelect: 'Rechercher et sélectionner le client',
    customerPlaceHolder: 'Rechercher des clients',
    emailRecipients: "Destinataires d'e-mail",
    smsRecipients: 'Destinataires de SMS',
    addEditEmails: 'Ajouter/modifier les adresses e-mail',
    addEditSms: 'Ajouter/modifier les SMS',
    addEditDeviations: 'Ajouter/modifier les échecs',
    live: 'En direct',
    search: 'Rechercher',
    startTypingPlaceHolder: 'Commencez à taper',
    AddEdit: 'Ajouter/Modifier',
    searchHint:
      "Rechercher les expéditions en fonction de l'ID expédition, de la réf. client, de l'ID colis, du nom ou de l'adresse de livraison",
    senderName: "Nom de l'expéditeur",
    addSenderName: "Ajouter un nom d'expéditeur",
    senderNameAdded: "Nom de l'expéditeur ajouté",
    addInvoiceHDOrdersFromDate: 'Ajouter une date de début pour la facturation de commandes HD via Glow',
    invoiceHDOrdersFromDate: 'Facturer les commandes HD dans Glow à partir de la date',
    invoiceHDOrdersFromDateAdded: 'La date de début pour la facturation de commandes HD dans Glow a été modifiée',
    addNPSFromDate: 'Ajouter la date de début du NPS',
    activateNPSFromDate: 'Activer NPS à partir de la date',
    scanToCreatePackages: {
      title: 'Scanner pour créer des colis',
      label: 'Utiliser le scanneur pour créer des colis',
      details:
        "Lors de l'activation de la numérisation pour créer des colis, le livreuer est obligé de scanner les colis lors de l'enlèvement. Les colis scannés seront automatiquement mis à jour sur l'expédition.",
      success: 'Numéro de scan pour créer des colis mis à jour.',
      enabled: 'Activé',
      disabled: 'Désactivé'
    },
    billingCustomerNumber: 'Numéro client de facturation',
    addBillingCustomerNumber: 'Ajouter le numéro client de facturation',
    billingCustomerNumberAdded: 'Numéro client de facturation ajouté',
    searchPage: {
      fromDate: 'Date de début',
      toDate: 'Date de fin',
      today: "Aujourd'hui",
      tomorrow: 'Demain',
      yesterday: 'Hier',
      last30days: 'Les 30 derniers jours',
      plusMinus15Days: '+/- 15 jours',
      plusMinus30Days: '+/- 30 jours'
    },
    smsText: {
      title: 'Remplacer les SMS de notification',
      addEditSmsText: 'Ajouter/modifier les SMS',
      smsTextUpdated: 'SMS ajouté/mis à jour',
      en: 'ANG',
      se: 'SUE',
      dk: 'DAN',
      description: 'Description',
      submit: 'Soumettre',
      smsType: 'Type de notification',
      smsTypeTexts: {
        preadvice: 'Avis préliminaire',
        pickup: 'SMS de suivi',
        pre_pickup: 'SMS de suivi préliminaire',
        manual_delay: 'SMS de retard manuel',
        eta_pickup: "SMS ETA d'enlèvement",
        eta_delivery: 'SMS ETA de livraison',
        flex_delivery_confirmation: 'SMS de confirmation de livraison flex.',
        advise: "SMS d'avis",
        delivery_confirmation: 'SMS de confirmation de livraison',
        pre_advice_push: 'SMS Push',
        pre_advice_push_return: 'SMS Push Return',
        pre_advice_push_email: 'E-mail Push',
        pre_advice_push_2nd: 'SMS Push 2nd avis préliminaire',
        pre_advice_push_2nd_email: 'E-mail Push 2nd avis préliminaire',
        pre_advice_push_return_to_sender_email: "E-mail Push de retour à l'expéditeur",
        pre_advice_pull: 'SMS Pull',
        pre_advice_pull_email: 'E-mail Pull',
        pre_advice_pull_reminder: 'SMS Pull de rappel',
        pre_advice_pull_reminder_email: 'E-mail Pull de rappel',
        pre_advice_pull_reminder_to_sender_email: "E-mail Pull de rappel à l'expéditeur",
        pre_advice_pull_return_to_sender_email: "E-mail Pull de retour à l'expéditeur",
        pre_advice_return: 'SMS de retour de livraison',
        pre_advice_return_email: 'SMS de retour de livraison',
        pre_advice_return_2nd: 'SMS de 2nd avis préliminaire de retour de livraison',
        pre_advice_return_2nd_email: 'E-mail de 2nd avis préliminaire de retour de livraison',
        pre_advice_return_return_to_sender_email: "E-mail retour à l'expéditeur pour retour de livraison",
        pre_advice_pup: 'SMS PUP',
        pre_advice_pup_email: 'E-mail PUP',
        pre_advice_pup_reminder: 'SMS PUP de rappel',
        pre_advice_pup_reminder_email: 'E-mail PUP de rappel',
        pre_advice_pup_reminder_to_sender_email: "E-mail PUP de rappel à l'expéditeur",
        pre_advice_pup_return_to_sender_email: "E-mail PUP de retour à l'expéditeur",
        delivery: 'SMS de livraison'
      }
    },
    showNotCollected: 'Enlèvement non effectué',
    booking: {
      pickupReady: "Articles prêts à l'enlèvement",
      pickup: 'Enlèvement',
      delivery: 'Livraison',
      deliveryAvailable: 'Livraison disponible',
      priceComment:
        'Vous serez facturé en fonction de votre contrat client avec Bring. Les frais ne sont pas inclus dans ce prix.',
      serviceUnavailable: "Le service sélectionné n'est pas disponibles pour le code postal {{postalCode}}",
      recipientPrice: 'Prix du destinataire :',
      customerContactPlaceholder: 'Commandé par – Ce champ est visible sur la facture du client'
    },
    email: {
      emailTitle: 'E-mail au client',
      getNotificationsFor: 'Recevez des notifications pour',
      emailAddress: 'Adresse e-mail',
      createdEvent: 'Enregistré(e)',
      collectedEvent: 'Enlèvement',
      deliveredEvent: 'Livraison',
      save: 'Enregistrer',
      edit: 'Modifier',
      delete: 'Supprimer',
      emailExists: "L'e-mail existe déjà",
      invalidEmail: 'E-mail invalide',
      true: 'Oui',
      false: 'Non',
      notificationType: { standard: 'Informations standard' },
      informationToCustomer: 'Informations au client',
      returnToCustomer: 'Retour au client',
      addEmailToList: "Ajouter l'email à la liste",
      registeredEmailSubscription: 'Abonnement email enregistré',
      updateEmail: "Mettre à jour l'email",
      noRegisteredEmails: 'Aucun e-mail enregistré',
      detailsSavedSuccessfully: "L'email à l'expéditeur a été enregistré avec succès"
    },
    customerDeviations: {
      new: 'Ajouter un nouvel échec',
      deviationTitle: 'Echecs pour le client',
      addDeviation: 'Ajouter un nouvel échec',
      editDeviation: "Modifier l'échec",
      en: 'ANG',
      sv: 'SUE',
      dk: 'DAN',
      event: 'Événement',
      reason: 'Raison',
      submit: 'Soumettre',
      deviationCode: "Code d'échec",
      deviationCode_plural: "Codes d'échec",
      deviation: 'Echec',
      deviationType: "Type d'échec",
      description: 'Description',
      edit: 'Modifier',
      delete: 'Supprimer',
      deviationAdded: 'Echec ajouté',
      deviationEdited: 'Echec modifié',
      deviationExists: "L'échec existe déjà",
      deviationDeleted: 'Echec supprimé'
    },
    customerNumber: 'Numéro de client',
    customerInfo: 'Informations concernant le client',
    customerName: 'Nom du client',
    invoicingAddress: 'Adresse de facturation',
    organisationNumber: "Numéro d'organisation",
    visitingAddress: 'Adresse de visite',
    creditBlockedFrom: 'Crédit bloqué à partir de la date',
    creditBlockedFromInfo: "Bloquer le crédit pour ce client à partir d'une date sélectionnée",
    from: 'De',
    address: 'Adresse',
    zipCode: 'Code postal',
    zipArea: 'Ville',
    country: 'Pays',
    edit: 'Éditer {{name}}',
    selectCountry: 'Choisissez le pays',
    settingsInGlow: 'Paramètres dans Glow',
    addNewCustomer: 'Ajouter un nouveau client',
    bookingPage: 'Page de réservation',
    bookingPageModalHeading: 'Réservation dans le portail client.',
    bookingPageModalDescription:
      'Désactivez la réservation si le client ne doit pas être en mesure de réserver des commandes sous ce numéro de client dans le portail client Glow.',
    brandedTrackingModalHeading: 'Facturation du suivi de marque',
    brandedTrackingModalDescription: 'Activez pour activer la facturation pour le suivi personnalisé.',
    bookingActive: 'Activé',
    bookingInactive: 'Désactivé',
    brandedTrackingActive: 'Activé',
    customerBookingPageAccessUpdated: 'Accès à la page de réservation mis à jour',
    brandedTrackingAccessUpdated: 'Statut de facturation avec suivi de marque mis à jour',
    connectMatrices: 'Connecter les matrices',
    returnLastLegModalHeading: 'Créer le tronçon retour',
    returnLastLegModalDescription:
      'Choisir "oui" signifie que nous divisons la commande en deux étapes et ajoutons un tronçon de retour. "Non" signifie qu\'aucun tronçon de retour n\'a besoin d\'être créé. "Non défini" signifie que la règle de gestion des retours n\'est pas définie pour un client spécifique.'
  },
  feedback: {
    title: 'Commentaires',
    message: "Veuillez nous donner des suggestions sur la façon d'améliorer cette page.",
    linkText: 'Envoyer le feedback'
  },
  texts: {
    title: 'Texte',
    update: 'Mettre à jour le texte',
    name: 'Nom du texte',
    group: 'Regroupement en cours',
    edit: 'Modifier',
    delete: 'Supprimer',
    add: 'Ajouter texte',
    reset: 'Rétablir',
    confirmDelete: 'Voulez-vous vraiment supprimer ce texte ?',
    added: 'Texte ajouté',
    deleted: 'Texte supprimé',
    updated: 'Texte mis à jour',
    tabTitle: 'Textes et traductions',
    successfullyAdded: 'Le texte a été ajouté avec succès à la liste',
    successfullyUpdated: 'Le texte a été mis à jour avec succès',
    allTextsAndVases: 'Liste de tous les textes et traductions',
    searchText: 'Rechercher un texte',
    createNewText: 'Créer un nouveau texte',
    nameToolTip: "Le nom s'affiche lorsque le texte est lié au service"
  },
  countries: { norway: 'Norvège', sweden: 'Suède', finland: 'Finlande', denmark: 'Danemark', germany: 'Allemagne' },
  billingType: { day: 'jour', evening: 'soir', weekend: 'week-end (week-end)', weekendEvening: 'soir le week-end' },
  shipmentsExport: {
    unit: 'Unité',
    exportButton: 'Exporter dans Excel',
    shipmentId: 'ID expédition',
    status: 'Statut',
    returned: 'Retourné(e)',
    department: 'Département',
    customer: 'Client',
    customerNumber: 'Numéro client',
    pickupEarliest: 'Premier enlèvement',
    pickupLatest: 'Dernier enlèvement',
    deliveryEarliest: 'Première livraison',
    deliveryLatest: 'Dernière livraison',
    additionalServices: 'Services additionnels',
    pickupName: 'Enlèvement',
    pickupAddress: "Adresse d'enlèvement",
    pickupZipCode: "Code postal d'enlèvement",
    pickupZipArea: "Région d'enlèvement",
    pickupCoordinates: "Coordonnées d'enlèvement",
    pickupInstructions: "Instructions d'enlèvement",
    deliveryName: 'Livraison',
    deliveryAddress: 'Adresse de livraison',
    deliveryZipCode: 'Code postal de livraison',
    deliveryZipArea: 'Zone de livraison',
    deliveryCoordinates: 'Coordonnées de livraison',
    deliveryInstructions: 'Instructions de livraison',
    quantity: 'Quantité',
    weight: 'Poids',
    customerRef: 'Référence client',
    pickupContactPerson: "Personne de contact pour l'enlèvement",
    pickupPhone: "Téléphone pour l'enlèvement",
    deliveryContactPerson: 'Personne de contact pour la livraison',
    deliveryPhone: 'Téléphone pour la livraison',
    deliveryEmail: 'E-mail de livraison',
    recipientRef: 'Référence du destinataire',
    price: 'Prix',
    multileg: 'Plusieurs étapes',
    group: 'Groupe',
    pickupTime: 'Enlevé/Tentative',
    delivered: 'Livré/Tentative',
    deliveryEtaSmsSentAt: "Liv. Un SMS d'ETA e a été envoyé",
    courierName: 'Coursier',
    goodsInfo: 'Informations sur les marchandises',
    note: 'Bon',
    slotName: 'Tournée',
    estimatedArrivalTime: 'Heure estimée',
    orderSource: 'Source de la commande'
  },
  importExport: {
    dropText: 'Déposez le fichier ici pour le chargement',
    export: 'Exporter',
    exportAddresses: 'Exporter des adresses client dans Excel',
    importExcel: 'Importer depuis Excel',
    imported: 'adresses importées avec succès',
    noRecipients: 'Aucun destinataire valide trouvé',
    or: 'ou',
    recipients: 'destinataires',
    selectFile: 'Sélectionner un fichier',
    uploadingFile: 'Téléchargement en cours...'
  },
  customMeasurements: {
    name: 'Nom',
    weight: 'Poids',
    height: 'Hauteur',
    length: 'Longueur',
    width: 'Largeur',
    heading: 'Types de marchandises personnalisées',
    error: "Erreur d'enregistrement du type de marchandise : ",
    confirmDelete: 'Voulez-vous vraiment supprimer ce type de marchandise ?',
    new: 'Créer nouveau',
    customizeGoodsTypes: 'Personnaliser les types de marchandises',
    customGoodsTypes: 'Types de marchandises personnalisées',
    defaultGoodsTypes: 'Types de marchandises par défaut',
    invalidName: 'Le nom doit comporter au moins une lettre'
  },
  appSetting: {
    courierWebAppActivate: "L'application Web du coursier est activée",
    stripeApiKey: 'Clé API de paiement Stripe',
    stripeApiLegend:
      'Afin de recevoir des paiements par carte via Glow, veuillez créer un compte ou utiliser un compte existant avec Stripe. Une fois connecté à Stripe, copiez la clé API depuis Démarrer avec Stripe / Pour les développeurs / clé secrète et collez-la ici.',
    c2cBookingCustomerNumber: 'Numéro de client C2C par défaut',
    c2cBookingCustomerNumberLegend: 'Les commandes créées via C2C Booking recevront ce numéro de client'
  },
  unresolved: {
    unresolvedAddresses: 'Adresses non résolues',
    allResolved: 'Toutes les adresses sont résolues !',
    address: 'Adresse',
    noOfShipments: "Nbre d'expéditions",
    shipment: 'expédition',
    shipment_plural: 'expéditions',
    shipmentNumber: "Numéro d'expédition",
    customerRef: 'Réf. client',
    senderName: "Nom de l'expéditeur",
    senderPhone: "Numéro de téléphone de l'expéditeur",
    recipientName: 'Nom du destinataire',
    recipientPhone: 'Téléphone du destinataire',
    searchAndSetLocation: "Rechercher et définir l'emplacement",
    setALocationToContinue: 'Définir un emplacement pour continuer',
    resolveAddress: "Résoudre l'adresse",
    suggestedAddressFound: 'Adresse suggérée trouvée',
    thereIsOneUnresolvedAddress: 'Il y a {{count}} adresse non résolue',
    thereIsOneUnresolvedAddress_plural: 'Il y a {{count}} adresses non résolues'
  },
  optimizing: {
    title: 'Optimisation en cours',
    reqTime: 'Heure de requête',
    respTime: 'Heure de réponse',
    showOnlyErrors: 'Afficher uniquement les erreurs',
    department: 'Département',
    userName: 'Utilisateur',
    graphID: 'ID',
    request: 'Requête',
    response: 'Réponse',
    error: 'Erreur',
    viewReqResp: 'Afficher',
    status: 'Statut',
    totalTime: 'Heure',
    selectOptimizingObjective: "Veuillez sélectionner Optimisation de l'objectif",
    objectives: {
      completion_time: 'Temps total minimum de livraison (par défaut)',
      min_vehicles: 'Nombre total minimum de véhicules',
      work_time: 'Temps de livraison identique',
      number_of_deliveries: 'Nombre de livraisons identique',
      deliveries_and_work_time: 'Temps de travail identique et nombre de livraisons identique'
    },
    kbSize: 'Taille de la demande (KB)',
    numberOfShipments: 'Nombre de colis',
    numberOfVehicles: 'Nombre de véhicules',
    processingTime: 'Temps de traitement'
  },
  date: {
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    yesterday: 'Hier',
    from: 'De',
    fromDate: 'Date de début',
    to: 'À',
    toDate: 'Date de fin',
    dateAndTime: 'Date et heures',
    rangeOptions: {
      '15d': '+/- 15 jours',
      '30d': '+/- 30 jours',
      custom: 'Dates personnalisées',
      last30d: 'Les 30 derniers jours',
      last7d: 'Les 7 derniers jours',
      month: 'Mois',
      months: 'Mois',
      today: 'Aujourd’hui',
      tomorrow: 'Demain',
      yesterday: 'Hier'
    }
  },
  routeReceipt: {
    printRouteReceiptList: 'Imprimer la liste des signatures',
    noRoutesInfoAvailable: "Aucune information d'itinéraire n'est encore disponible"
  },
  homeDeliveryRouteList: {
    receiptList: 'Liste des signatures',
    shipments: 'Expéditions',
    weight: 'Poids',
    sender: 'Expéditeur',
    package: 'Colis',
    shipmentNo: 'Expédition no',
    ref: 'Réf.',
    recipient: 'Destinataire',
    time: 'Heure',
    mobile: 'Smartphone',
    other: 'Autre',
    attemptedDelivery: 'Tentative de livraison',
    receipt: 'Réception',
    dateOfBirth: 'Date de naissance',
    nameClarification: 'Clarification de nom',
    goodsLine1: "N'oubliez pas d'inspecter les marchandises pour repérer tout dommage visible avant de signer.",
    goodsLine2:
      "En cas de dommage visible, le livreur doit l'enregistrer devant vous lors de la signature de la livraison des marchandises.",
    damage: 'Dommage',
    missing: 'Manquant(e)',
    packageNumber: 'Nombre de colis',
    comments: 'Commentaires'
  },
  invoicingStatus: {
    TO_BE_INVOICED: 'Non facturé',
    INVOICING_FAILED: 'Échec de facturation',
    CORRECTION_PENDING: 'Correction en attente',
    INVOICING_PAUSED: 'Facturation suspendue',
    EXCLUDED_FROM_INVOICING: 'Exclu',
    NOT_READY_FOR_INVOICING: 'Non prêt',
    INVOICED_OK: 'Facturé OK',
    ZERO_PRICE: 'Prix ​​incomplet',
    AWAITING_ACKNOWLEDGEMENT: 'En attente de confirmation'
  },
  invoice: {
    allOrdersInvoice: 'Toutes les commandes sont facturées',
    allOrdersInvoiceDescription: 'Nous n’avons trouvé aucune commande non facturée.',
    handledBy: 'Effectué par',
    invoicing: 'Facturation',
    carrierNumber: "Numéro d'unité",
    details: 'Détails',
    information: 'Informations sur la facture',
    items: 'Articles',
    searchShipmentId: 'Rechercher l’ID d’expédition',
    state: '{{count}} statut',
    state_plural: '{{count}} statuts',
    quantity: 'Quantité',
    mustBeNegative: 'doit être < 0',
    mustBePositive: 'doit être > 0',
    description: 'Description',
    customerAmount: 'Montant client',
    carrierAmount: "Montant pour l'unité",
    totalCustomerAmount: 'Montant total du client',
    totalCarrierAmount: "Montant total pour l'unité",
    failedPriceListLink: 'Liste de prix qui a échoué dans Moteur de tarification',
    failedPriceList: 'Liste de prix échouée',
    priceFailureDetail: "Détails de l'erreur",
    priceFailureReason: "Raison de l'échec",
    priceFailureReasons: {
      missingAttributeValue: "Valeur d'attribut manquante",
      matrixLookupMiss: 'Absence de correspondance dans la matrice',
      invalidExpression: 'Expression non valide',
      scriptError: 'Erreur de script'
    },
    text: 'Texte de la facture',
    invoiceDepartment: 'Département de facturation',
    invoiceDepartments: 'Service(s) de facturation',
    invoicingJobsSummary: 'Récapitulatif des tâches de facturation',
    invoicingStatus: 'Statut de facturation',
    invoicingStatusDescription: 'Affichage des commandes des 3 derniers mois.',
    invoicingModalWarningInvoicedOk:
      'Les expéditions ne seront pas facturées et seront considérées comme traitées. Elles disparaîtront de la page de facturation.',
    notInvoicedOrders: 'Commandes non facturées',
    ordersOnPage: 'Affichage de {{count}} commandes sur la page',
    ordersFoundCount_plural: '{{count}} commandes trouvées',
    toBeInvoiced: 'Bon pour facturation',
    ordersNotInvoiced: 'Commandes non encore facturées',
    invoicingFailed: 'Échec de la facturation',
    invoicingFailedDescription: 'Commandes traitées sans succès',
    correctionPending: 'Avec correction de commande',
    correctionPendingDescription: 'Commandes avec corrections en attente',
    invoicingPaused: 'Facturation suspendue',
    ordersPausedFromInvoiced: 'Commandes dont la facturation est suspendue',
    invoicingZeroPrice: 'Prix incomplet',
    invoicingZeroPriceDescription: 'Commandes avec des lignes zéro non approuvées',
    confirmCancelDialog: {
      title: 'Vous avez des changements non enregistrés',
      question: 'Vous avez des changements non enregistrés. Souhaitez-vous continuer?',
      abort: 'Rester sur cette page',
      confirm: 'Continuer sans sauvegarder'
    },
    articleCode: 'Article',
    credit: 'Crédit',
    invoice: 'Facture',
    create: 'Créer',
    invoiceOrderAction: 'Commande de facture',
    invoiceOrderAction_plural: 'Facturer les commandes',
    customerNumber: 'Numéro de client',
    reference: 'Référence de la facture',
    invoiceDepartment_plural: 'Départements de facturation',
    dashboard: 'Tableau de bord',
    ordersFoundCount: '{{count}} commande trouvée',
    sendInvoices: 'Envoyer des factures',
    sendInvoicesDescription:
      "Vous êtes sur le point de facturer {{departments}}. Veuillez sélectionner la date ou l'interval des dates pour la période de facturation.",
    sendInvoiceDateValidationError: 'La date de début ne peut pas être postérieure à la date de fin.',
    sendInvoiceDateValidationErrorOneMonth: "La durée maximale de l'intervalle des dates est de 1 mois",
    invoicingInProgress:
      'La facturation de {{departmentName}} est en cours, il reste {{count}} travaux de facturation.',
    invoicingInFinished: 'La facturation de {{departmentName}} est terminée',
    orderEnqueuedForInvoicing: "La commande est mise en file d'attente pour la facturation",
    orderEnqueuedForInvoicing_plural: "Les commandes sont mises en file d'attente pour la facturation",
    orderSuccessNotInvoiced: 'La commande a été modifiée avec succès et ne sera plus facturée',
    orderSuccessNotInvoiced_plural: 'Les commandes ont été modifiées avec succès pour ne plus être facturées',
    orderFailedInvoice: 'Échec de facturation de la commande sélectionnée',
    howManyInvoices: 'Combien de factures seront envoyées',
    fetchAmount: 'Récupérer le montant',
    wantToResend: 'Cette commande a déjà été facturée. Êtes-vous sûr(e) de vouloir la renvoyer?',
    adHocInvoiceOrder: 'Vous êtes sur le point de facturer cette commande. Voulez-vous continuer?',
    adHocInvoiceOrder_plural: 'Vous êtes sur le point de facturer ces commandes. Voulez-vous continuer?',
    pauseOrders: 'Suspendre les commandes',
    datePeriod: 'Période',
    ordersWereSuccessfullyPaused: 'La facturation de {{count}} commandes a été suspendue avec succès',
    ordersWereSuccessfullyPaused_plural: '{{count}} commandes ont été suspendues avec succès de la facturation',
    releaseOrders: 'Déployer les commandes',
    recalculatePrices: 'Recalculer les prix',
    ordersSentForPriceRecalculation: '{{count}} commande a été placée avec succès dans la file d’attente pour recalcul',
    ordersSentForPriceRecalculation_plural:
      '{{count}} commandes ont été placées avec succès dans la file d’attente pour recalcul',
    ordersWereSuccessfullyReleased: '{{count}} commande a été validée avec succès pour la facturation',
    ordersWereSuccessfullyReleased_plural: '{{count}} commandes ont été validées pour la facturation',
    ordersWereSuccessfullyAcceptedWithIncompletePrice:
      '{{count}} commande a été acceptée avec succès avec un prix incomplet',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural:
      '{{count}} commandes ont été acceptées avec succès avec un prix incomplet',
    addUnit: 'Ajouter une unité',
    addUnitDescription:
      'Ajoutez une unité à vos commandes sélectionnées. Cette unité est celle qui sera auto-facturée.',
    addUnitToOrders: 'Ajouter une unité aux commandes',
    addUnitToOrder: '{{unit}} a été ajouté avec succès à la commande {{count}}',
    addUnitToOrder_plural: '{{unit}} a été ajouté avec succès à {{count}} commandes',
    addUnitBulkDisabled: "L'unité ne peut être ajoutée qu'aux commandes sans unité",
    approveIncompletePrices: 'Approuver avec des prix incomplets',
    manualOverrideTitle: 'Changer manuellement le statut de facturation',
    unitId: "ID de l'unité",
    bannerOldInvoicePage:
      "Le 26 août, <0>la nouvelle page de facturation</0> s'ouvrira par défaut. Nous vous encourageons à commencer à utiliser la nouvelle page. Plus d'infos sur la nouvelle page <1>ici</1>.",
    acceptIncompletePricesTitle: 'Approuver les prix incomplets',
    acceptIncompletePricesModalQuestion:
      "Vous êtes sur le point d'approuver {{count}} commande avec des prix incomplets. Êtes-vous sûr de vouloir continuer ?",
    acceptIncompletePricesModalQuestion_plural:
      "Vous êtes sur le point d'approuver {{count}} commandes avec des prix incomplets. Êtes-vous sûr de vouloir continuer ?"
  },
  columnCategories: {
    aboutTheShipment: 'À propos de la livraison',
    goods: 'Marchandises',
    timeAndDate: 'Date et heure',
    planning: 'Planification',
    pickupInformation: "Informations d'enlèvement",
    customer: 'Client',
    deliveryInformation: 'Informations de livraison',
    originalSender: "Expéditeur d'origine",
    originalDelivery: "Livraison d'origine",
    service: 'Service',
    invoicingInformation: 'Informations de facturation'
  },
  shipment: {
    shipment: 'Expédition',
    multileg: 'Plusieurs étapes',
    customer: 'Client',
    customerContact: 'Contact client',
    customerReference: 'Référence client',
    customerNumber: 'Numéro client',
    route: 'Itinéraire',
    additionalServices: 'Services additionnels',
    quantity: 'Quantité',
    totalWeight: 'Poids total',
    totalVolume: 'Volume total',
    goodsInfo: 'Informations sur les marchandises',
    packages: 'Colis',
    pickupInfo: "Information d'enlèvement",
    pickupDate: "Date d'enlèvement",
    pickupTime: "Heure d'enlèvement",
    pickupInstructions: "Instructions d'enlèvement",
    deliveryInfo: 'Informations de livraison',
    deliveryDate: 'Date de livraison',
    deliveryTime: 'Heure de livraison',
    receiverReference: 'Référence destinataire',
    deliveryInstructions: 'Instructions de livraison',
    trackingPage: 'Page de suivi de destinataire',
    internalNote: 'Note interne',
    closeWhileEditing:
      'Voulez-vous vraiment la fermer en cours de modification ?\nToutes les modifications non enregistrées seront perdues.',
    name: 'Nom',
    address: 'Adresse',
    zipCode: 'Code postal',
    zipArea: 'Ville',
    phone: 'Téléphone',
    secondPhone: '2nd téléphone',
    email: 'E-mail'
  },
  bookingTimeWindow: {
    week: 'Semaine',
    bookButton: 'Réserver',
    customButton: 'Créneau horaire personnalisé',
    removeBookingButton: 'Retirer la réservation',
    bookedInfo: 'Réservé(e) {{date}} entre {{timeWindow}}',
    notBookedInfo: 'Non réservé(e)',
    noBookingYet: 'Pas de réservation',
    tableHeaderSlots: 'Tournées disponibles',
    serverError: "Une erreur s'est produite sur le serveur. Merci de réessayer plus tard.",
    previousButton: 'Précédent',
    nextButton: 'Suivant',
    noAvailableDeliveryTimes: 'Pas de créneaux de livraison disponibles ce mois-ci',
    customTimeTitle: "Définir le créneau horaire personnalisé pour l'expédition",
    customTimeWindowLabel: 'Créneau horaire de livraison',
    backFromCustomTimeButton: 'Retour aux heures disponibles',
    errorEarliestNotBeforeLatest:
      'La première heure de livraison doit être antérieure à la dernière heure de livraison',
    arrivedAtTerminal: 'Arrivé(e) au terminal',
    bookCustomTimeSlot: 'Réserver un créneau horaire',
    shipmentBooked: 'Expédition réservée',
    shipmentBookedWithCustomTimeWindow: 'Expédition réservée avec un créneau horaire personnalisé',
    updateBooking: 'Mettre à jour la réservation',
    removeTimeSlot: 'Supprimer le créneau horaire',
    bookingChanged: 'La réservation a été modifiée',
    bookingSuccessful: 'La réservation a été effectuée',
    customTimeWindowAdded: 'Le créneau horaire personnalisé pour l’expédition a été ajouté',
    timeFrom: 'Première heure',
    timeTo: 'Dernière heure',
    timeFieldError: 'La première heure de livraison doit être antérieure à la dernière',
    availableTimeSlot: 'Créneaux horaires disponibles',
    updateButtonTooltip: "Pour mettre à jour la réservation, vous devez d'abord choisir un nouveau créneau horaire"
  },
  manualOverride: {
    scannedFormDescription: "Le type de scan d'arrivée est déclenché par le service que vous choisissez.",
    eventType: {
      delivered: 'Livraison effectuée',
      collected: 'Enlèvement effectué',
      returned: 'Retourné(e)',
      deviated: 'Echec',
      scanned: 'Scanné',
      preadvised: 'Tentative de preadvice ',
      'order-sorted': 'Trié'
    },
    selectEventType: "Sélectionner un type d'événement",
    noEventSelected: "Pas d'événement sélectionné",
    selectManifest: 'Sélectionner un vol',
    noManifestSelected: 'Aucun vol sélectionné',
    disabledEventReason: {
      returned: ' - Echec requis',
      collected: ' - Expédition requise',
      default: ' - Non autorisé',
      'order-sorted': 'Pour les commandes H2 uniquement'
    },
    fillDeliverOrReturnEventInfo: "Remplir le nom, la date et l'heure",
    fillDeviationEventInfo: "Sélectionner échec et remplir la date et l'heure",
    fillGeneralEventInfo: "Remplir la date et l'heure",
    eventMessage: 'Evénement créé',
    allPackagesSelected: 'Tous les colis ont été sélectionnés',
    noActiveDriverError: 'Pas de livreur actif sur cette unité.',
    selectPackages: 'Sélectionner les colis',
    selectDriver: 'Sélectionner le livreur',
    selectUnit: "Sélectionner l'unité",
    selectDepartment: 'Remplacer le département de numérisation'
  },
  live: {
    onlyDeviated: 'Ne montrer que les tournées avec des déviations',
    shipment: 'Expédition',
    shipmentFrom: 'De',
    shipmentTo: 'A',
    confirm: 'Confirmer',
    unconfirm: 'Annuler la confirmation',
    confirmedBy: 'Confirmé par {{name}} à {{time}}',
    theCourier: 'Le coursier',
    activeRoutes: 'Itinéraires actifs',
    routeDeviation: "Ecart d'itinéraire",
    routeDeviations: "Ecarts d'itinéraire",
    multipleDeviations: 'Plusieurs écarts',
    recentDeviations: 'Ecarts récents',
    viewRoute: "Afficher l'itinéraire",
    noRoutesFound: "Aucun itinéraire n'a été trouvé",
    noDeviationsFound: "Aucun échec n'a été trouvé",
    consignmentDamage: 'Dommage des marchandises',
    shipmentDeviation: "Echec d'expédition",
    outsideServiceTime: 'Hors du créneau de service',
    outsideEstimatedTime: "Hors de l'heure estimée",
    deliveryLocationDeviation: 'Emplacement inattendu',
    eventTextDeviationOne: "{{name}} a un échec d'expédition.",
    eventTextDeviationMany: "{{name}} a {{count}} échecs d'expédition.",
    eventTextDamageOne: '{{name}} a des dommages sur {{count}} expéditions.',
    eventTextDamageMany: '{{name}} a des dommages sur une expédition.',
    eventTextServiceTimeWindow: '{{name}} a effectué une livraison hors du créneau horaire de service.',
    eventTextEstimatedTimeWindow: '{{name}} a effectué une livraison hors du créneau horaire estimé.',
    eventTextDeviated: "{{name}} s'est écarté de son itinéraire.",
    eventHelpDeviation: "{{name}} a un échec sur l'expédition numéro {{shipmentId}}.",
    eventHelpDamage: "{{name}} a un dommage sur l'expédition numéro {{shipmentId}}.",
    eventHelpServiceTimeWindow:
      "{{name}} a livré l'expédition numéro {{shipmentId}} à {{actual}}, ce qui est en dehors du créneau horaire de service {{earliest}}-{{latest}}.",
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        "{{name}} a livré ou dévié le numéro de l'envoi {{shipmentId}} <4>ici</4>, à {{distance}} mètres de <9>l'emplacement prévu</9>.",
      withoutShipmentId: "{{name}} a livré ou dévié <4>ici</4>, à {{distance}} mètres de <9>l'emplacement prévu</9>."
    },
    eventHelpEstimatedTimeWindow:
      "{{name}} a livr� l'envoi num�ro {{shipmentId}} � {{actual}}, ce qui est en dehors de la fen�tre de temps estim�e {{earliest}}-{{latest}}.",
    eventHelpDriverDeviated: "{{name}} a livré l'expédition numéro {{shipmentId}} dans le mauvais ordre.",
    eventHelpDriverDeviatedBefore:
      "{{name}} a livré l'expédition numéro {{shipmentId}} dans le mauvais ordre, s'arrêtant à la livraison #{{actual}} avant la livraison #{{expected}}.",
    eventHelpDriverDeviatedAfter:
      "{{name}} a livré l'expédition numéro {{shipmentId}} dans le mauvais ordre, s'arrêtant à la livraison #{{actual}} après la livraison #{{expected}}.",
    filterOngoing: 'En cours',
    filterNotStarted: 'Non commencé(e)',
    filterFinished: 'Terminé(e)',
    filterOnlyDeviated: 'Uniquement les échecs',
    filterShipmentDeviations: "Echecs d'expédition",
    filterShipmentDamage: "Dommage d'expédition",
    filterEstimatedTime: 'Heure estimée',
    filterServiceTime: 'Heure de service',
    filterRouteDeviations: "Ecarts d'itinéraire",
    filterDeliveryLocationDeviation: 'Emplacement non conforme',
    filterConfirmed: 'Confirmé(e)',
    routeProgress: 'Réalisation de {{complete}} sur {{total}}',
    routeDuration: 'De {{from}} à {{to}}',
    routeDelayed: 'En retard',
    routeAhead: 'En avance',
    routeOnTime: "À l'heure",
    routeUnknown: 'Inconnu',
    routeDeviationsNone: 'Aucun écart',
    showHandledDeviations: 'Afficher toutes les déviations ({{count}})',
    openRoute: 'Voir itinéraire',
    openShipment: 'Voir expédition',
    allRoutesStates: 'Tous les statuts de la tournée',
    selectedRouteStates_plural: '{{count}} statuts de tournées de livraison',
    allDeviationTypes: 'Tous les types de déviations',
    selectedDeviationTypes: '{{count}} type de déviation',
    selectedDeviationTypes_plural: '{{count}} types de déviations',
    noSelectedTypes: 'Aucun type sélectionné.',
    viewShipment: "Voir l'expédition",
    selectAllRouteStates: 'Sélectionner tous les statuts  de la tournée de livraison.',
    noRoutesWithDeviations: 'Aucune tournée de livraison disponible',
    selectAllDeviationTypes: 'Sélectionner tous les types de déviations.',
    noDeviationOnRoute: 'Aucune déviation sur la tournée de livraison',
    noDeviationAvailable: 'Aucune déviation disponible',
    noDeviationsOnFilter: 'Aucune déviation disponible pour le filtre sélectionné. Essayez un filtre différent.',
    noRoutesOnFilter:
      "Il n'y a pas d'la tournée de livraisons disponibles pour le filtre sélectionné. Essayez un filtre différent.",
    search: 'Effectuer une recherche par itinéraire, véhicule ou livreur',
    unhandled: 'écarts non',
    deviations: 'gérés',
    mostDeviations: 'La plupart des écarts',
    latestInTime: 'Dernier dans le temps',
    earliestInTime: 'Le plus tôt dans le temps',
    sortOn: 'Trier sur',
    late: 'En retard',
    early: 'En avance',
    stops: 'Arr�ts : {{stops}}',
    deviatedFromRoute: "D�viation de l'itin�raire",
    totalTimeMajorDeviations: 'Temps total des d�viations majeures (?5 min)',
    totalTimeMinorDeviations: 'Temps total des d�viations mineures (<5 min)',
    lateOrEarlyFirstStop: 'En retard ou en avance pour la premi�re livraison',
    showAdjusted: "Afficher l'heure ajust�e",
    showAdjustedTooltip: 'Heure ajust�e affich�e sur la page de suivi du destinataire',
    showDiff: "Afficher la diff�rence d'arr�t",
    diff: "Diff�rence d'arr�t",
    showDiffTooltip: "Diff�rence entre l'heure pr�vue (ETD) et l'heure r�elle entre les arr�ts",
    modalTableSummary: "Temps total de l'itin�raire",
    liveModalActualTooltip1: "En comparaison avec l'ETD :",
    liveModalActualTooltip2: "Vert : Jusqu'� 15 min",
    liveModalActualTooltip3: 'Orange : 15-30 min',
    liveModalActualTooltip4: 'Rouge : Plus de 30 min',
    eventTextDeliveryLocationDeviation:
      "{{name}} a livr�/s'est d�vi� de {{distance}} m�tres par rapport � l'emplacement pr�vu.",
    routeStateFilter: "Filtre d'�tat de l'itin�raire",
    departmentFilter: 'Filtre de service',
    allDepartments: 'Tous les services',
    deviationTypeFilter: 'Filtre de type de d�viation'
  },
  zendesk: {
    email: 'Votre e-mail :',
    confirmEmail: 'Saisir à nouveau votre e-mail :',
    send: 'Enregistrer',
    missingEmail:
      "Pour utiliser le nouveau portail, vous devez enregistrer votre adresse e-mail Posten ou Bring. Vous ne devez le faire qu'une seule fois."
  },
  instantPlannerUnification: {
    message:
      'Les modules Instant et Planner sont maintenant combinés. Veuillez utiliser Planner pour trouver tout ce qui était auparavant sous Instantané.',
    linkTitle: 'En savoir plus ici.'
  },
  shipmentFormPage: {
    advancedFilter: 'Filtre avancé',
    selectTable: 'Sélectionner une table',
    selectCondition: 'Sélectionner une condition',
    findShipment: 'Trouver une commande',
    searchPhraseError: 'Vous devez fournir au moins 3 caractères',
    selectStateLabel: 'Sélectionnez un état dans la liste déroulante',
    selectDateLabel: 'Sélectionnez une date dans la liste déroulante',
    submit: 'Afficher les résultats',
    inputPlaceholder: 'Rechercher l’ID d’expédition, le numéro de téléphone, l’adresse, la région, le code postal...',
    clearButtonLabel: 'bouton d’effacement de l’expression de recherche',
    allStates: 'Tous les états',
    customDate: 'Personnaliser',
    noDate: 'Aucune restriction de date',
    selectFromDate: 'Sélectionnez la date de début',
    selectToDate: 'Sélectionnez la date de fin',
    include: 'Inclure',
    exclude: 'Exclure',
    selectAdvancedOperator: 'Sélectionnez un opérateur avancé dans la liste déroulante',
    ofTheseRequirements: 'de cette ou ces exigences',
    tableColumn: 'Colonne de tableau',
    searchTerm: 'Rechercher un terme',
    all: 'TOUT',
    any: 'N’IMPORTE LAQUELLE',
    tableDropdown: 'Sélectionnez un tableau dans la liste déroulante',
    conditionDropdown: 'Sélectionnez une condition dans la liste déroulante',
    typeSearch: 'Taper dans le terme de recherche',
    addNewRow: 'Ajouter un nouveau rang',
    deleteRow: 'Effacer le rang actuel',
    goodsDescription: 'Description des marchandises',
    route: 'Itinéraire',
    unit: 'Unité',
    deliveryArea: 'Zone de livraison',
    deliveryZipcode: 'Code postal de livraison',
    state: '{{count}} État',
    state_plural: '{{count}} États',
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    yesterday: 'Hier',
    last30Days: 'Les 30 derniers jours',
    plusMinus15Days: '+/- 15 jours',
    plusMinus30Days: '+/- 30 jours',
    shipmentIds: 'Plusieurs expéditions',
    selectState: 'Sélectionner un état',
    orderId: 'Numéro de commande interne',
    allAnd: 'Tous (et)',
    anyOr: "N'importe lequel (ou)"
  },
  shipmentResultPage: {
    noResultTitle: 'Aucun résultat trouvé',
    batchLimitReached: 'Affichage d’un maximum de 2000 expéditions, envisagez d’affiner la recherche',
    noResultExplanation:
      'Nous n’avons pu trouver aucun résultat. Merci d’essayer de faire une recherche avec un autre terme.',
    withoutDateLimitReached:
      'En cas de recherche sans date, nous afficherons un maximum de {{limit}} expéditions. Envisagez d’affiner votre recherche.',
    findYourShipments: 'Trouvez vos envois',
    findYourShipmentsDescription:
      "Saisissez ce que vous recherchez dans le champ de recherche ci-dessus. Plus d'options sont disponibles dans la recherche avancée.",
    searchByShipmentIdsLimitReached:
      "Lorsque vous recherchez par identifiants d'envoi, nous afficherons un maximum de {{limit}} envois. Pensez à affiner votre recherche.",
    searchError: 'La recherche a échoué. Essayez de recharger la page ou de modifier vos paramètres de recherche.'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Supprimer l’expédition ?',
    confirmDeleteTitle_plural: 'Supprimer les envois sélectionnés ?',
    confirmDuplicateTitle: 'Dupliquer l’expédition ?',
    confirmDuplicateButton: 'Dupliquer',
    deleteSuccess: 'L’expédition a été supprimée',
    deleteSuccess_plural: 'Les envois ont été supprimés avec succès.',
    deleteFailure: 'Impossible de supprimer l’expédition',
    deleteFailure_plural: 'Impossible de supprimer les envois.',
    invoiceActions: 'Actions sur la facture',
    shipmentActions: "Actions sur l'envoi",
    showInvoicePayloadDisableTooltip: 'Les envois doivent être en statut de facturation "échec" ou "OK".'
  },
  shipmentTable: {
    quantity: 'Quantité',
    delEta: 'HAE LIV',
    delDuration: 'D LIV',
    delDurationTitle: 'Durée de livraison',
    delDeliveryFrom: 'LIV DE',
    delDeliveryFromTitle: 'Heure de livraison de début',
    delDeliveryTo: 'LIV À',
    delDeliveryToTitle: 'Heure de livraison de fin',
    timeOfDelivery: 'Heure de livraison',
    puDuration: 'D ENL',
    puDurationTitle: 'Délai d’enlèvement',
    puPickupFrom: 'ENL DE',
    puPickupFromTitle: 'Heure d’enlèvement de début',
    puPickupTo: 'ENL À',
    puPickupToTitle: 'Heure d’enlèvement de fin',
    timeOfPickup: 'Heure d’enlèvement',
    delEtaTitle: 'Heure de livraison estimée',
    recipientRef: 'Réf. destinataire',
    loading: 'Chargement en cours...',
    pickupName: 'Nom pour l’enlèvement',
    deliveryName: 'Nom pour la livraison',
    selectAllOnPage: 'Sélectionner tout sur page ({{count}})',
    selectAll: 'Sélectionner tout ({{count}})',
    deselectAll: 'Désélectionner tout',
    numSelectedShipments: '{{numSelected}} sélectionné(e)(s)',
    createLabels: 'Créer les étiquettes',
    editShipments: 'Modifier les informations d’expédition',
    moveShipments: 'Déplacer vers un département différent',
    yourSearchResult: 'Le résultat de votre recherche',
    numberOfShipmentsFound: '{{count}} expéditions trouvées',
    orderedDeliveryDate: 'Date de livraison prévue'
  },
  tablePagination: { goToPage: 'Aller à page', of: 'sur' },
  moveDepartmentModal: {
    errorMessage: 'Commande(s) : {{orderIds}}, erreur : {{error}}',
    errorMessage_plural: 'Commande(s) : {{orderIds}}, erreur : {{error}}',
    ordersMovedText: 'La ou les commandes {{orderIds}} ont été déplacées vers le département {{department}}',
    ordersMovedText_plural: 'La ou les commandes {{orderIds}} ont été déplacées vers le département {{department}}',
    partialSuccessMessage: '{{count}} expédition(s) sur {{fullCount}} a(ont) été déplacée(s)',
    title: 'Déplacer l’expédition sélectionnée vers un autre département'
  },
  sendManualDelaySmsModal: {
    partialSuccessMessage: '{{count}} SMS sur {{fullCount}} a(ont) été envoyé(s)',
    successMessage: 'Un SMS de retard manuel a été envoyé'
  },
  sendPrePickupSmsModal: {
    'return-order': 'Les commandes ne peuvent pas être des retours',
    'invalid-state': 'Les commandes doivent avoir le statut "Attribué à la plage horaire".'
  },
  multistopModal: {
    title: 'Informations de groupe',
    description: 'Vue de groupe pour les commandes payées ensemble.',
    debitLines: 'Lignes de débit',
    notFound: 'Aucune information de groupe trouvée',
    shipmentId: 'ID expédition',
    deliveryDate: 'Date de livraison',
    stopAddress: "Adresse d'arrêt",
    postalCode: 'Code postal',
    noPrice: 'Aucune information de prix disponible pour le groupe',
    priceUpdated: 'Les prix du groupe ont été mis à jour'
  },
  shipmentDetails: {
    eventWasAdded: 'L’événement a été ajouté',
    issueSaving: 'Un problème est survenu lors de l’enregistrement',
    noSelectedPackages: 'Aucun colis n’a été sélectionné',
    pickupAndDelivery: 'Enlèvement et livraison',
    whereIsTheOrder: 'Où est la commande',
    communicationToRecipient: 'Communication au destinataire',
    yesPremium: 'Oui, premium',
    alerts: 'Alertes',
    pickupDeliveryInfo: 'Informations d’enlèvement et de livraison',
    quickEdit: 'Modification rapide',
    quickEditTitle: 'Modifier les détails d’enlèvement et de livraison',
    aboutShipment: 'À propos de l’expédition',
    mapLinkText: 'Afficher les coordonnées de carte',
    copyShipment: 'Copier l’expédition',
    editShipment: 'Modifier les détails d’expédition',
    location: 'Lieu',
    time: 'Heure',
    selectDate: 'Sélectionner la date',
    manualChangeEvent: 'Modifier manuellement l’événement',
    disabledEventReason: {
      returned: 'Échec requis',
      collected: 'Doit être affectée à un itinéraire',
      default: 'Non autorisé(e)'
    },
    selectDeviation: 'Sélectionner Échec',
    required: 'Obligatoire(s)',
    allPackages: 'Tous les colis',
    packages: 'Colis',
    package: 'Colis',
    changeDepartment: 'Modifier le département',
    moveDepartment: 'Déplacer vers département',
    shipmentMoved: 'Expédition déplacée',
    shipmentMovedText: 'L’expédition {{shipmentId}} a été déplacée vers le département {{department}}',
    shipmentMovedGoBack: 'Retourner à la page de recherche',
    moveShipmentDescription:
      'Si vous n’avez pas accès au service, vous ne pourrez pas trouver l’expédition après son déplacement',
    moveShipmentConfirmText: 'Déplacer l’expédition vers un autre service ?',
    addShipmentToSlot: 'Ajouter l’expédition à {{slot}} ?',
    changeSlotConfirm: 'Changer la tournée pour l’expédition à {{slot}} ?',
    changeSlot: 'Changer la tournée',
    selectSlot: 'Sélectionner une tournée',
    AddToSlot: 'Ajouter à la tournée',
    optimisationInProgress:
      'Optimisation en cours. Vous ne pouvez pas changer la tournée pendant l’optimisation de l’itinéraire.',
    removeFromSlot: 'Supprimer de la tournée',
    removeShipmentFromSlot: 'Supprimer l’expédition de la tournée',
    removeShipmentFromSlotHeading: 'Supprimer l’expédition de cette tournée ?',
    successRemoveFromSlot: 'L’expédition a été supprimée de la tournée',
    successChangeSlot: 'Expédition affectée à une nouvelle tournée',
    successAddToSlot: 'Expédition ajoutée à la tournée',
    changeSlotDescription: 'Le changement de tournée est fait sans optimisation d’itinéraire.',
    addToSlotDescription: 'La tournée sélectionnée sera optimisée et l’ordre de livraison restera le même',
    pickupOrDeliveryChange: 'L’enlèvement ou la livraison a été changé(e)',
    pickupOrDeliveryChangeDescription:
      'L’adresse d’enlèvement ou de livraison a été changée. Si vous voulez remettre l’adresse précédente, appuyez sur l’un des boutons ci-dessous.',
    addressWasUnresolved: 'Adresse non résolue',
    eventTimeCannotBeInFuture: "L'heure de l'événement ne peut pas être dans le futur."
  },
  terminal: 'Dépôt',
  location: 'Emplacement',
  pickupOrders: {
    acceptOrders: 'Accepter les commandes',
    selected: 'sélectionnée(s)',
    handleOrders: 'Gérer les commandes d’enlèvement',
    found: 'trouvé',
    accept: 'Accepter',
    reject: 'Refuser',
    noResultText: 'Nous n’avons pu trouver aucun résultat. Merci d’essayer de faire une recherche avec une autre date.',
    last7d: 'Aujourd’hui + 7 derniers jours',
    rejectOptions: {
      notFound:
        'Expéditeur introuvable sur l’adresse d’enlèvement. Aucune autre tentative possible. Enlèvement clôturé.',
      terminatesPickupOrder:
        'L’expéditeur met fin à la commande d’enlèvement. Aucune autre tentative possible. Clôture de l’enlèvement.',
      handedOver:
        'L’expéditeur a transmis l’enlèvement à un autre transporteur. Aucune autre tentative possible. Enlèvement clôturé.',
      noMoreAttempts: 'L’expéditeur a déménagé. Aucune autre tentative possible. Enlèvement clôturé.',
      pickupCorrected: 'L’expéditeur a déménagé. Enlèvement corrigé et renvoyé au sous-traitant.',
      goodsNotReady:
        'Les marchandises n’étaient pas prêtes à être enlevées. Aucune autre tentative possible. Enlèvement clôturé.',
      incorrectBooking: 'Réservation incorrecte. Mise à fin de l’enlèvement.',
      senderRefuses:
        'L’expéditeur refuse de remettre les marchandises. Aucune autre tentative possible. Enlèvement clôturé.',
      addressCorrected: 'Adresse d’enlèvement incorrecte. Adresse corrigée.',
      incorrectAddressNoMoreAttempts:
        'Adresse d’enlèvement incorrecte. Aucune autre tentative possible. Enlèvement clôturé.',
      senderNotified: 'Adresse d’enlèvement incorrecte. Aucune autre tentative possible. Enlèvement clôturé.',
      forbiddenGoods: 'Marchandises interdites. Aucune autre tentative possible. Enlèvement clôturé.',
      timeFrame:
        'Impossible de faire l’enlèvement dans le délai d’exécution. Commande d’enlèvement renvoyée au bureau de réservation.'
    },
    mustSelectReason: 'Vous devez sélectionner un motif pour le refus',
    selectRejectReason: 'Sélectionnez un motif pour le refus*',
    selectReason: 'Sélectionnez le motif',
    commentPlaceholder: 'Facultatif – fournissez des informations supplémentaires sur le refus',
    acceptedSuccessToast: 'a été acceptée',
    rejectedSuccessToast: 'a été refusée',
    errorToast: 'Une erreur s’est produite',
    rejectOrders: 'Refuser les commandes',
    shipment: '{{count}} expédition',
    shipment_plural: '{{count}} expéditions'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery:
      "L'expédition [{{shipmentIds}}] n'était pas éligible car la combinaison des horaires nouveaux et existants n'est pas valide. Veuillez désélectionner la commande en échec et réessayer.",
    pickupEarliestShouldBeBeforeLatest: 'La première heure d’enlèvement doit être antérieure à la dernière',
    deliveryEarliestShouldBeBeforeLatest: 'La première heure de livraison doit être antérieure à la dernière',
    success: 'Les informations d’expédition ont été mises à jour',
    mixedShipmentsSelected:
      'Vous avez sélectionné des expéditions avec et sans date. Les informations de livraison et d’enlèvement sont obligatoires.',
    fieldNotEditable:
      'Impossible de modifier ces informations pour les commandes dans l’état Affectée à la tournée ou Enlèvement effectué',
    bulkActionDisabledReason: 'Impossible de modifier les commandes dans l’état Échec, Livrée, Refusée ou Retournée',
    withoutDateDisabledReason:
      'Impossible de modifier les commandes dans l’état Affectée à la tournée ou Enlèvement effectué qui sont sans date',
    pickupShouldBeBeforeDeliveryForAllShipments_plural:
      "{{count}} commandes n'étaient pas éligibles en raison de la combinaison d'horaires nouveaux et existants qui n'est pas valide. Veuillez désélectionner les commandes en échec et réessayer. ID des commandes en échec - {{shipmentIds}}",
    useExistingTimes: 'Utiliser les horaires existants',
    pickupDateTime: 'Enlèvement date et heures',
    deliveryDateTime: 'Livraison date et heures',
    earliestPickupTime: "Première heure d'enlèvement",
    latestPickupTime: "Dernière heure d'enlèvement",
    earliestDeliveryTime: 'Première heure de livraison',
    latestDeliveryTime: 'Dernière heure de livraison',
    pickupName: 'Enlèvement nom',
    pickupPhoneNumber: 'Enlèvement numéro de téléphone',
    deliveryName: 'Livraison nom',
    deliveryPhoneNumber: 'Livraison numéro de téléphone',
    pickupInstructions: "Instructions d'enlèvement",
    deliveryInstructions: 'Instructions de livraison'
  },
  shipmentSelectColumn: {
    title: 'Personnaliser les en-têtes de tableau',
    addColumnHeader: 'Ajouter les en-têtes de colonnes',
    addColumnBody:
      'Sélectionnez les en-têtes que vous souhaitez afficher dans le tableau de données. Nous vous conseillons d’utiliser 10-15 différentes\n...en-têtes afin que les informations ne soient pas coupées. Le montant exact dépend de la taille de\n    l’appareil sur lequel vous travaillez.',
    orderOfTable: 'Ordre des en-têtes de tableau',
    orderOfTableDescription: 'Choisissez l’ordre préféré des en-têtes de tableau en glissant-déplaçant.'
  },
  bulkActions: {
    routeReceiptList: { disabledTooltip: 'Les commandes ne peuvent pas être dans l’état Résolue ou Non résolue' },
    unassignShipmentFromSlot: 'Annuler l’affectation des expéditions aux tournées',
    unassignShipmentFromSlotDisabledReason: 'Les commandes doivent être dans l’état Affectée à la tournée ou Offerte',
    forceUnassignShipmentFromSlot: 'Forcer l’annulation de l’affectation des expéditions aux tournées',
    disabledMoveDepartmentTooltip:
      'Les commandes doivent être dans l’état Créée, Résolue, Non résolue ou Refusée avant de pouvoir être déplacées',
    deleteDisableTooltip:
      'Impossible de supprimer une expédition dont l’état est Créée, Non résolue, Résolue, Offerte ou Affectée',
    duplicateDisableTooltip:
      'Les expéditions doivent être dans l’état Échec ou Retournée, et le type de service doit être Coursier',
    deleteShipments: 'Supprimer les commandes',
    viewGroupDisabledTooltip: 'La commande doit être attribuée avant que vous puissiez voir et modifier son groupe'
  },
  routeManagement: {
    routeListHelpModal: {
      title: 'Liste des tournées de livraisons',
      manageRoute: 'Créer, modifier et supprimer un la tournée de livraison',
      manageRouteDesc:
        'Le menu "Action de la tournée de livraison" vous permet de créer de nouveaux la tournée de livraisons. Pour modifier ou supprimer un la tournée de livraison existant, sélectionnez "Paramètres de la tournée de livraison".',
      routeDetails: 'Détails de la tournée de livraison',
      routeDetailsDesc:
        "Vous pouvez accéder aux détails de la tournée de livraison en cliquant sur un la tournée de livraison. Dans cette section, vous pouvez ajuster les paramètres de la tournée de livraison, effectuer des actions telles que l'inversion des ordres de livraison ou ajouter et modifier la zone de la carte. Les paramètres de routage ne peuvent être modifiés que lorsque la tournée de livraison n'a pas encore commencé. Toutes les commandes ajoutées seront affichées sur la carte à droite.",
      dispatchModal: 'Modalité de dispatching',
      dispatchModalDesc:
        'La modalité de dispatching est accessible depuis les détails de la tournée de livraison. Cliquez simplement sur "Ajuster manuellement la tournée de livraison". Si vous cliquez ici, vous verrez la modalité qui vous permet de flexibiliser, forcer et désaffecter les commandes de la tournée de livraison.'
    },
    unassignedShipmentsHelpModal: {
      title: 'Liste des livraisons non attribuées',
      filterAndSorting: 'Filtrer et trier',
      filterAndSortingPara1:
        "Si vous souhaitez, par exemple, répartir des livraisons à partir d'une certaine zone ou d'un client spécifique, vous pouvez utiliser le filtre dans le coin à droite",
      filterAndSortingPara2:
        "Les options visibles dépendent du contenu de la page. Par exemple, si les itinéraires ne comprennent que des voitures et des camions comme types de véhicules, ce sont les options de filtre disponibles dans la fenêtre modale. Le filtre s'applique à la fois à la liste et à la carte. Lorsqu'un filtre est actif, un badge rouge sera affiché.layed.",
      filterAndSortingPara3:
        "Le tri par défaut se fait en fonction de l'heure de livraison (de la plus précoce à la plus tardive), mais vous pouvez facilement le modifier. Lorsque vous triez par codes postaux, le tri est effectué en fonction du code postal de livraison.",
      shipmentInformation: 'Informations sur la livraison',
      shipmentInformationDesc:
        'En cliquant sur la flèche en haut à droite des cartes, davantage d\'informations seront affichées. Vous pouvez également accéder à la page des détails de la livraison en cliquant sur "Voir les détails".',
      bulkActions: 'Actions groupées',
      bulkActionsDesc:
        'Lorsque vous sélectionnez des livraisons, vous activez les actions groupées disponibles - regrouper les livraisons, déplacer vers un département différent et supprimer.'
    },
    helpModal: {
      linkToSupportArticle:
        'Vous pouvez <0>en savoir plus sur le fonctionnement de la gestion des la tournée de livraisons</0> ici.'
    },
    assignBestDriver: 'Attribution automatique au livreur le mieux placé',
    assignClosestDriver: 'Attribuer au livreur le plus proche',
    assignToRoute: 'Attribuer à la tournée sélectionnée',
    unassignedShipments: 'Livraisons non attribuées ({{count}})',
    drivers: 'Livreurs {{count}})',
    selectedShipments: '{{count}} nombre de livraisons',
    pickupTimeWindow: "Fenêtre d'enlèvement",
    deliveryTimeWindow: 'Fenêtre de livraison',
    autoDispatchTitle: 'Attribution automatique de {{count}} livraisons',
    toClosestDrivers: 'Au(x) livreur(s) le(s) plus proche(s)',
    assignOneDriver: 'Attribuer toutes les livraisons à un conducteur',
    driverAdded: 'Livreur ajouté',
    noDriver: 'Pas de livreur',
    disableAssingButton: 'Vous devez sélectionner des commandes pour les attribuer.',
    viewTasks: 'Voir les tâches',
    trackLivePage: 'Suivre sur la page en direct',
    viewDetails: 'Voir les détails',
    selectAll: 'Sélectionner tout',
    routeTime: 'Temps de la tournée de livraison',
    changeRouteSettings: 'Modifier les paramètres de la tournée',
    createRouteDescription:
      'Si vous ne définissez pas de nom pour la tournée de livraison, le nom sera généré automatiquement en fonction des autres informations saisies.',
    saveAndCreateRoute: 'Enregistrer et créer un nouvelle tournée de livraison',
    createRoute: 'Créer un la tournée de livraison',
    vehicle: 'Véhicule',
    selectVehicle: 'Sélectionner le véhicule',
    nameOfRoute: 'Nom de la tournée de livraison',
    selectSubcontractor: 'Sélectionner le sous-traitants',
    setStartTime: "Définir l'heure de départ",
    setEndTime: "Définir l'heure de fin",
    routeActions: 'Actions de la tournée de livraison',
    routeSettings: 'Paramètres de la tournée de livraison',
    mustSelectVehicle: 'Vous devez sélectionner un véhicule',
    routeCreatedMessage:
      'la tournée de livraison est enregistrée avec succès et ajoutée à la liste des tournées de livraisons.',
    createNewRoute: 'Créer une nouvelle tournée de livraison',
    noRoutes: "Aucun la tournée de livraison n'a encore été créé.",
    autoRouteTitle: 'la tournée de livraison automatique pour {{count}} commandes',
    lockRoutes: 'Verrouiller toutes les commandes attribuées à la tournée de livraison',
    editRoute: 'Modifier la tournée de livraison',
    endTimeValidationError: "L'heure de fin doit être après l'heure de début",
    deleteAllRoutes: 'Supprimer tous les la tournée de livraisons',
    deleteRoute: 'Supprimer la tournée de livraison',
    routeWasDeleted: 'la tournée de livraison a été supprimé avec succès',
    routeWasUpdated: 'la tournée de livraison a été mise à jour avec succès',
    routeWasUpdated_plural: 'Les la tournée de livraisons ont été mis à jour avec succès',
    routeWasDeleted_plural: 'Les la tournée de livraisons ont été supprimés avec succès.',
    manuallyAdjustRoute: 'Ajuster manuellement la tournée de livraison',
    shipmentsNeededToActivate: 'Vous devez ajouter des livraisons pour pouvoir activer cette fonction',
    routeInfo: 'Informations sur la tournée de livraison',
    shipmentsSelected: '{{count}} livraisons sélectionnées',
    driverPhoneNumber: 'Numéro de téléphone du conducteur',
    filterSettings: 'Paramètres de filtre',
    shipmentFilter: "Filtre d'expédition",
    routeFilter: 'Filtrer les la tournée de livraisons',
    applyFilter: 'Appliquer le filtre.',
    pickupDeliveryTime: "Heures d'enlèvement et de livraison",
    completedShipmentsMap: 'Voir les commandes terminées sur la carte',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    routeStatus: 'État de la tournée de livraison',
    filter: 'Filtrer',
    routeNotEditable: 'Seules les tournées ayant un statut "Créée" ou "Attibuée" peuvent être modifiées',
    sort: 'Trier',
    zipCodeFrom: 'Code postal de',
    zipCodeTo: "Code postal jusqu'à",
    vehicles: 'Vehicules',
    zipCodeError: 'Code postal invalide',
    clearFilter: 'Effacer le filtre',
    clearFilters: 'Effacer les filtres',
    templates: 'Modèles',
    loadTemplate: 'Charger le modèle.',
    deleteTemplate: 'Supprimer le modèle',
    saveAsTemplate: 'Enregistrer en tant que modèle',
    saveTemplate: 'Enregistrer le modèle',
    templateWasCreated: '{{name}} a été créé avec succès.',
    templateWasLoaded: '{{name}} a été chargé avec succès',
    templateWasDeleted: '{{name}} a été supprimé avec succès',
    loadTemplateSubHeading: 'Sélectionnez le modèle que vous souhaitez ajouter.',
    createANew: 'Créer un nouveau',
    orUpdateExisting: 'ou mettre à jour existant',
    deleteTemplateSubHeading: 'Sélectionnez le modèle que vous souhaitez supprimer.',
    selectTemplate: 'Sélectionnez le modèle',
    selectTemplateToOverride: 'Sélectionner le modèle à remplacer.',
    nameOfTemplate: 'Nom du modèle',
    templateIsRequired: 'Le modèle doit être sélectionné',
    templateOrNameIsRequired: 'Le modèle ou le nom doit être sélectionné.',
    addMapArea: 'Ajouter une zone de carte',
    editMapArea: 'Modifier la zone de la carte',
    areaWasUpdated: 'Zone de carte modifiée avec succès pour {{name}}',
    areaWasCreated: 'La zone de la carte a été ajoutée à {{name}} avec succès.',
    areaWasDeleted: 'Zone de carte supprimée avec succès pour {{name}}.',
    useMapArea: 'Utiliser des restrictions de zone de carte',
    areYouDonePlanning: 'Avez-vous terminé la planification de la tournée de livraison ?',
    noBackPage: 'Non, retour à la page',
    yesDone: "Oui, j'ai terminé.",
    completedRoute: '{{name}} a été terminé',
    successfullyReversed: "L'ordre de livraison pour {{name}} a été inversé avec succès.",
    addAreaRoute: 'Ajouter une zone à la tournée de livraison',
    deleteMapArea: 'Supprimer la zone de carte',
    sendPrePickupSms: "Envoi préalable d'un SMS de suivi de livraison",
    groupSelection: 'Sélectionner un groupe',
    groupRequestSuccess: 'Les commandes ont été regroupées avec succès.',
    ungroupRequestSuccess: 'Les commandes ont été désassociées avec succès.',
    groupShipments: 'Regrouper les commandes',
    nameOfGroup: 'Nom du groupe',
    shipmentsInGroup: 'commandes dans le groupe',
    disableGroupShipment: "Vous ne pouvez ajouter des commandes qu'à un seul groupe à la fois.",
    noShipmentsInGroup: 'Aucune expédition dans le groupe',
    noShipmentsInGroupDescription:
      'Vous avez supprimé toutes les commandes du groupe, appuyez sur <i>Enregistrer le groupe</i> pour sauvegarder les modifications.',
    onlyDispatchOneVehicle: "Vous ne pouvez dispatcher qu'un véhicule à la fois.",
    noVehicleFoundToDispatch: 'Aucun véhicule trouvé. Le curseur doit être dans la zone de dépôt marquée.',
    dragToVehicle: 'Faites glisser la commande sur le véhicule pour effectuer le dispatch.'
  },
  dispatchModal: {
    title: 'Enlèvements et livraisons sur la tournée de livraison {{name}}',
    force: 'Forcer',
    invalidRouteOrder:
      "Les enlèvements ne peuvent pas précéder les livraisons. Modifiez l'ordre de la tournée de livraison.",
    submitDisabled: 'Replanifier la tournée de livraison pour activer le bouton',
    replanDisabled: "L'ordre de la tournée de livraison doit être valide pour être replanifié",
    noShipments: 'Aucune expédition sur la tournée de livraison',
    noShipmentsDescription:
      ' Vous avez supprimé toutes les commandes de la tournée de livraison, appuyez sur <i>Confirmer la tournée de livraison</i> pour enregistrer les modifications.',
    unassignShipmentFromRoute: "Cliquez pour désaffecter l'expédition de la tournée de livraison",
    unassignBreakFromRoute: 'Cliquez pour enlever la pause de la tournée de livraison',
    editBreakOnRoute: 'Cliquez pour modifier la pause sur la tournée de livraison',
    earliestStartOfBreak: 'Début le plus tôt de la pause',
    latestStartOfBreak: 'Début le plus tardif de la pause'
  },
  vehicle: {
    createNewVehicle: 'Créer un nouveau véhicule',
    createAndEditVehicle: 'Créer et modifier des véhicules',
    listAllVehicle: 'Lister tous les véhicules de ce service',
    weightCapacity: 'Capacité de poids ',
    volumeCapacity: 'Capacité de volume',
    maxRangeKm: 'Distance maxi',
    deleteSuccessMessage: 'Le véhicule a été supprimé avec succès.',
    createdSuccessMessage: 'Le véhicule a été créé avec succès.',
    updatedSuccessMessage: 'Le véhicule a été mis à jour avec succès.',
    deleteVehicle: 'Supprimer le véhicule',
    notRecover: 'Vous ne pourrez pas annuler cette action.',
    createVehicle: 'Créer un véhicule',
    editVehicle: 'Modifier {{name}}',
    vehicleInformation: 'Informations sur le véhicule',
    selectFuelType: 'Sélectionner le type de carburant',
    selectEuroClass: 'Sélectionner la classe Euro',
    settings: 'Paramètres',
    activeDriver: 'Conducteur actif',
    noVehicleControl: 'Aucun contrôle du véhicule',
    slower: 'Plus lent',
    slow: 'Lent',
    faster: 'Plus rapide',
    speedFactorVehicle: 'Facteur de vitesse du véhicule',
    driverSpeed: 'Vitesse du conducteur',
    serviceSpeed: 'Vitesse du service',
    defaultStartLocation: "L'emplacement de départ par défaut est l'adresse du service.",
    specifyEndLocation: "Spécifier l'emplacement d'arrivée",
    earliest: 'Le plus tôt',
    latest: 'Le plus tard',
    normal: 'Normal'
  },
  lmEvents: {
    heading: 'Evènements LM',
    event: 'Evènement ',
    lmEventReasonAction: 'LM Événement-Raison-Action',
    eventTime: "Heure de l'événement",
    eventSentAt: 'Événement envoyé à',
    lmPayload: 'LM Charge utile',
    view: 'Afficher',
    enterShipmentId: 'Vous devez entrer un numéro de livraison'
  },
  userRolesAdmin: {
    userRoles: "Rôles d'utilisateur",
    successCreateMessage: '{{name}} a été créé avec succès',
    successUpdateMessage: '{{name}} a été mis à jour avec succès',
    saveRole: 'Enregistrer le rôle',
    editRole: 'Modifier {{name}}',
    createNewRole: 'Créer un nouveau rôle',
    nameOfRole: 'Nom du rôle',
    nameRequired: 'Le nom est requis',
    descriptionRequired: 'La description est requise',
    descriptionLabel: 'Description (max 240 caractères)',
    iconLabel: "Sélectionnez l'icône que vous souhaitez pour le rôle utilisateur",
    selectRoleTitle: "Sélectionner le droit d'accès pour le rôle",
    emptyState: 'Aucun rôle trouvé pour la phrase recherchée',
    deleteConfirm: 'Êtes-vous sûr de vouloir supprimer {{name}}?',
    deleteSuccess: 'Le rôle {{name}} a été supprimé avec succès',
    settings: 'Paramètres',
    accessRights: "Droits d'accès",
    settingsTitle: 'Paramètres du rôle',
    settingsDescription:
      "L'anglais est requis. Si aucune autre langue n'est sélectionnée, l'anglais sera la langue de secours.",
    additionalLanguagesTitle: 'Ajouter des langues supplémentaires',
    customerPortalAccess: 'Accès au portail client',
    defaultForUserRole: 'Utiliser comme rôle par défaut (facultatif)',
    event: 'Événement',
    financialAccess: 'Droits financiers',
    invoiceActions: 'Actions de facture',
    orderAttributes: 'Attributs de commande',
    findApiOrder: 'Trouver une commande API',
    optimus: 'Optimus',
    noAccessToApiOrder: "Pas d'accès à Trouver une commande API",
    noAccessToOptimus: "Pas d'accès à Optimus",
    optimusViewDescription:
      "L'utilisateur peut afficher les services des départements et des clients, mais ne peut pas apporter de modifications",
    optimusFullDescription: "L'utilisateur peut ajouter et modifier des services pour les départements et les clients",
    ordersApiSearchRestrictedDescription:
      "L'utilisateur peut rechercher des commandes des départements ou des clients auxquels il a accès",
    ordersApiSearchFullDescription: "L'utilisateur peut rechercher des commandes de tous les départements et clients",
    addRole: 'Ajouter un rôle',
    addedRole: 'Rôle ajouté',
    removeRole: 'Supprimer un rôle',
    importExport: 'Importer / Exporter',
    importExportExtendedDescription: "Inclut l'accès au rapport étendu des coursiers.",
    importExportExtendedSaaSDescription: "Inclut l'accès au rapport de fin de journée."
  },
  validationError: {
    provideEmail: 'Vous devez fournir un e-mail',
    provideValidEmail: 'Vous devez fournir un e-mail valide',
    youMustSelectCustomer: 'Vous devez sélectionner un client'
  },
  manageReturns: {
    title: 'Gérer les retours',
    typesOfReturns: 'Types de retours',
    ordersOverAPeriod: 'Affichage des commandes du mois dernier.',
    pickedUpByCustomer: 'Retiré par le client',
    pickedUpByCustomerDescription: 'Retours à retirer par le client',
    transferToDifferentTerminal: 'Transfert vers un autre terminal',
    transferToDifferentTerminalDescription: 'Retours à envoyer vers un autre terminal',
    environmentalReturns: 'Retours environnementaux',
    environmentalReturnsDescription: 'Retours pour recyclage, collectés dans un conteneur',
    returnForDelivery: 'Retour pour livraison',
    returnForDeliveryDescription: 'Retours à acheminer et livrer',
    MANUAL_RETURNS: 'Retours manuels',
    manualReturnsDescription: "Commande dont la phase de retour n'est pas encore créée",
    returnToSenderTitle: "Souhaitez-vous retourner les envois à l'expéditeur",
    manuallyMarkAsHandled: 'Marquer manuellement comme traité',
    manuallyMarkAsHandledTooltip: "Lorsque l'envoi est marqué comme traité, il disparaît de la liste",
    ordersFoundCount: '{{count}} commande trouvée',
    ordersFoundCount_plural: '{{count}} commandes trouvées',
    PICKED_UP_BY_CUSTOMER: 'Retiré par le client',
    TRANSFER_TO_DIFFERENT_TERMINAL: 'Transfert vers un autre terminal',
    ENVIRONMENTAL_RETURNS: 'Retours environnementaux',
    RETURN_FOR_DELIVERY: 'Retour pour livraison',
    ordersAlreadyHandled: 'Toutes les commandes sont traitées',
    ordersAlreadyHandledDescription: "Nous n'avons trouvé aucune commande non traitée.",
    manuallyMarkAsHandledModal: {
      title: 'Marquer manuellement comme traité',
      shipmentsCount: '{{count}} envoi',
      shipmentsCount_plural: '{{count}} envois',
      description:
        'Lorsque les envois sont marqués comme traités, ils disparaîtront de la liste. Cette action est irréversible. Les envois peuvent être trouvés sur la page de recherche.',
      confirm: 'Marquer comme traité'
    },
    manuallyMarkAsHandledSuccess: '{{count}} envoi a été marqué comme traité',
    manuallyMarkAsHandledSuccess_plural: '{{count}} envois ont été marqués comme traités',
    returnToSenderSuccess: "{{count}} envoi a été retourné à l'expéditeur",
    returnToSenderSuccess_plural: "{{count}} envois ont été retournés à l'expéditeur",
    selectCustomer: 'Sélectionner le client',
    customer: 'Client',
    customer_plural: 'Clients',
    allCustomers: 'Tous les clients',
    selectDestination: 'Sélectionner la destination',
    destination: 'Destination finale',
    destinationDIP: 'Destination DIP',
    returnShipmentColumns: 'Informations de retour',
    allDestinations: 'Toutes les destinations',
    noDate: 'Tous',
    zeroDays: '0 jours',
    oneTo6Days: '1–6 jours',
    moreThan7Days: '> 7 jours',
    moreThan15Days: '> 15 jours',
    moreThan30Days: '> 30 jours',
    customerDate: 'Personnalisé',
    daysOnTerminal: 'Jours en terminal',
    printList: 'Imprimer la liste',
    returnToSender: "Retourner à l'expéditeur",
    returnToSenderQueued: "Retour à l'expéditeur en file d'attente",
    returnToSenderConfirmation: "Voulez-vous retourner cet envoi à l'expéditeur ?",
    returnToSenderFailed: "Impossible de retourner l'envoi à l'expéditeur",
    returnToSenderDisabledTooltip: "L'envoi ne peut pas être retourné s'il fait déjà partie d'un groupe multi-étapes",
    senderDIP: 'DIP expéditeur',
    createReturnLabel: 'Créer une étiquette de retour'
  },
  apolloAdmin: {
    title: 'Integrations',
    emptyTitle: 'Aucune demande affichée',
    emptyDescription: 'Choisissez la date et appuyer sur rechercher.',
    reqType: 'Type de demande',
    routeId: 'Identifiant de la tournée de livraison',
    routeName: 'Nom de la tournée de livraison'
  },
  preAdviceFollowup: {
    title: 'Gérer les préavis',
    toBeHandled: 'À traiter',
    failedOrders: 'Commandes échouées',
    failedOrdersDescription: "Le destinataire n'a reçu aucune notification",
    manualPreAdvice: 'Préavis manuel',
    manualPreAdviceDescription: "Commandes avec un déclencheur manuel sur la page d'administration",
    preAdviceLocked: 'Préavis verrouillé',
    preAdviceLockedDescription: "Commandes en attente d'action",
    missingRules: 'Règles manquantes',
    missingRulesDescription: "Les commandes manquent d'une règle de préavis.",
    inProgress: 'En cours',
    inProgressDescription: 'Commandes avec préavis en cours',
    ordersFoundCount: '{{count}} commande trouvée',
    ordersFoundCount_plural: '{{count}} commandes trouvées',
    FAILED_ORDERS: 'Commandes échouées',
    MANUAL_PRE_ADVICE: 'Préavis manuel',
    PRE_ADVICE_LOCKED: 'Préavis verrouillé',
    MISSING_RULES: 'Règles manquantes',
    IN_PROGRESS: 'En cours',
    ordersAlreadyHandled: 'Toutes les commandes sont traitées',
    ordersAlreadyHandledDescription: "Nous n'avons trouvé aucune commande non traitée.",
    updatePhoneNumber: 'Changer ou ajouter un numéro de téléphone',
    updateEmailAddress: 'Changer ou ajouter une adresse e-mail',
    removeShipmentFromPreAdvice: "Retirer l'envoi du préavis",
    bookDateAndTime: "Réserver la date et l'heure",
    manuallyPreAdvice: 'Préavis manuel',
    unlockPreAdvice: 'Déverrouiller le préavis',
    lockPreAdvice: 'Verrouiller le préavis',
    triggerPreAdvice: 'Déclencher le préavis',
    selectedCustomers: '{{count}} client',
    selectedCustomers_plural: '{{count}} clients',
    allCustomers: 'Tous les clients',
    selectRowWithOrderId: "Sélectionner la ligne avec l'identifiant de commande {{id}}",
    updateRecipientPhoneNumber: 'Mettre à jour le numéro de téléphone',
    phoneNumberUpdated: "Le numéro de téléphone a été mis à jour pour l'identifiant d'envoi : {{shipmentId}}",
    phoneNumberUpdatedAndPreAdviceSent:
      "Numéro de téléphone mis à jour et préavis envoyé pour l'identifiant d'envoi : {{shipmentId}}",
    samePhoneNumberAlreadySet: "Le même numéro de téléphone est déjà défini pour l'envoi.",
    updateRecipientEmailAddress: "Mettre à jour l'adresse e-mail",
    emailAddressUpdated: "L'adresse e-mail a été mise à jour pour l'identifiant d'envoi : {{shipmentId}}",
    emailAddressUpdatedAndPreAdviceSent:
      "Adresse e-mail mise à jour et préavis envoyé pour l'identifiant d'envoi : {{shipmentId}}",
    sameEmailAddressAlreadySet: "La même adresse e-mail est déjà définie pour l'envoi.",
    manuallyPreAdvicedSuccess: 'Préavis déclenché avec succès pour {{count}} envois',
    nrOfSelected: '{{count}} sélectionné',
    sendPreAdvice: 'Envoyer le préavis',
    unlockPreAdviceSuccess: 'Préavis déverrouillé avec succès pour {{count}} envois',
    unlockPreAdviceAndPreAdviceSent: 'Préavis déverrouillé et envoyé avec succès pour {{count}} envois',
    lockPreAdviceSuccess: 'Préavis verrouillé avec succès pour {{count}} envois',
    saveAndSendPreAdvice: 'Enregistrer et envoyer le préavis',
    finishPreAdviceFollowupSuccess: 'Envois retirés avec succès du préavis : {{count}}'
  },
  coordinates: {
    setCoordinates: 'Définir les coordonnées',
    title: 'Définir les coordonnées des adresses',
    setCoordinatesCount: 'Définir les coordonnées ({{count}})',
    zipCodeRange: 'Intervalle de codes postaux',
    goodJob: 'Bravo! Bien joué!',
    noUnresolvedShipments: 'Aucun envoi non résolu trouvé pour ce département ou ce groupe de départements',
    coordinateSaved: 'Coordonnées enregistrées pour {{address}}',
    checkMapProvider: 'Vérifier sur {{name}}',
    noDate: 'Pas de date',
    applyFilter: 'Appliquer le filtre',
    thereAreUnsetCoordinates: 'Vous avez {{count}} coordonnée à définir.',
    thereAreUnsetCoordinates_plural: 'Vous avez {{count}} coordonnées à définir.',
    noCoordinateFound:
      "Nous n'avons pas pu trouver les coordonnées pour cette adresse. Recherchez l'adresse ou saisissez les coordonnées (par ex. 59.289976807684084, 18.010318840107406) dans le champ de recherche."
  },
  relatedOrders: {
    NONE: 'Aucune commande liée',
    MULTILEG: 'Multileg',
    PICKUP: 'Ramassage',
    TRANSPORT: 'Transport',
    DELIVERY: 'Livraison',
    CUSTOMER: 'Commande client'
  },
  vendors: {
    title: 'Fournisseurs',
    viewDetails: 'Voir les détails',
    searchLabel: 'Rechercher le nom du fournisseur, l’ID du fournisseur ou l’ID de l’unité',
    searchVendorPlaceholder: 'Rechercher le nom du fournisseur ou l’ID du fournisseur',
    searchUnitPlaceholder: 'Rechercher l’ID de l’unité',
    vendorOption: 'Nom/ID du fournisseur',
    unitOption: 'ID de l’unité',
    notFound: 'Aucun fournisseur trouvé avec cette phrase de recherche',
    initialSearch: 'Recherchez pour trouver des fournisseurs',
    informationAboutTheVendor: 'Informations sur le fournisseur',
    connectUnit: 'Connecter une unité',
    connectUnits: 'Connecter des unités',
    connectedUnits: 'Unité connectée ({{count}})',
    connectedUnits_plural: 'Unités connectées ({{count}})',
    paymentTerms: 'Conditions de paiement',
    connected: 'Connecté',
    searchForUnitDescription:
      'Sélectionner une unité signifie que le département auquel l’unité est ajoutée assumera le coût.',
    searchForUnitTitle: '{{vendorSite}} pour {{operatingUnitName}}',
    successToast: 'Unités connectées mises à jour pour le fournisseur {{name}}',
    showConnectedUnits: 'Afficher les unités connectées',
    editConnectedUnits: 'Modifier les unités connectées',
    searchForUnit: 'Rechercher une unité',
    searchPage: 'Page de recherche',
    currencyCode: 'Code de devise',
    connectedToCompany: 'Connecté à cette entreprise',
    noConnection: 'Aucune connexion',
    connectedTo: 'Connecté à',
    nothingHereYet: 'Rien ici pour le moment',
    emptyStateSelectedVendor: 'Sélectionnez un code de site pour lier des unités ou voir les connexions existantes.',
    selfBilling: "L'autofacturation",
    setUnitSettingsTitle: 'Mettre à jour les paramètres de l’unité pour {{name}}',
    setUnitSettingsSelfBillingInternalTrade: "L'autofacturation et la transaction interne sont actifs.",
    setUnitSettingsNone: "L'autofacturation et la transaction interne ne sont pas actifs.",
    setUnitSettingsInternalTrade: "L'autofacturation n’est pas active, tandis que la transaction interne est active."
  },
  shdAdmin: {
    successfullyDeleted: 'La matrice horaire a été supprimée avec succès',
    successfullyUploaded: 'La matrice horaire a été téléchargée avec succès',
    timeWindowForHomeDelivery: 'Plage horaire pour la livraison à domicile',
    filterByCustomerData: 'Filtrer par nom de client, sous-client ou numéro de client',
    uploadTimeMatrixFile: 'Télécharger le fichier de la matrice horaire',
    downloadExcelTemplate: 'Télécharger le modèle Excel',
    clickHereOrDragAndDrop: 'Cliquez ici ou faites glisser et déposez',
    allowedFormats: 'Les formats autorisés sont XLS et XLSX',
    existingCustomers: 'Clients existants avec SHD',
    customerPriceTooltip: 'Si le prix du client final doit être affiché, activez le commutateur',
    deleteModalTitle: 'Supprimer les fenêtres horaires',
    deleteModalDescription:
      'Si vous supprimez cette fenêtre horaire, toutes les fenêtres horaires SHD pour ce client seront supprimées. Vous ne pourrez pas récupérer cette action.',
    exportToExcel: 'Exporter vers Excel',
    toggleLabel: 'Activer/désactiver le prix du client',
    customerNumber: 'Numéro de client',
    subCustomer: 'Sous-client',
    customerName: 'Client',
    amountOfPostalCodes: 'Nombre de codes postaux',
    lastUpdatedAt: 'Dernière mise à jour',
    showCustomerPrice: 'Afficher le prix du client',
    uploadMaximumFileSizeLimit: 'La taille du fichier ne peut pas être supérieure à {{size}} Ko'
  },
  matrix: {
    title: 'Matrix',
    pageTitle: 'Admin Matrix',
    name: 'Nom',
    filename: 'Nom du fichier',
    uploaded: 'Téléchargé',
    uploadedBy: 'Téléchargé par',
    downloadTemplate: 'Télécharger le modèle',
    byUser: 'Par utilisateur',
    country: 'Pays',
    ROUTING: 'Routage',
    LINEHAUL: 'Transport principal',
    HUB: 'Hub',
    DELIVERY: 'Livraison',
    VAS: 'VAS',
    'booking-limits': 'Limites de réservation',
    searchLabel: 'Rechercher dans la matrice par nom ou numéro de client',
    'baggage-delivery': 'Bagages',
    baggage: {
      title: 'Matrices de bagages',
      name: 'Bagages {{country}}',
      legend:
        'Si vous souhaitez mettre à jour des matrices de bagages existantes, faites-le en sélectionnant « télécharger les données de la matrice ». ',
      uploadTitle: 'pour {{country}}',
      custom: 'Matrice de code DS personnalisé',
      uploadCustomTitle: 'pour des codes DS personnalisés',
      status: 'Statut',
      uploadFail:
        "{{label}} n'a pas été accepté en raison d'informations incorrectes. Modifiez les informations et téléchargez à nouveau la matrice.",
      statuses: { accepted: 'Accepté', pending: 'En attente', rejected: 'Rejeté' }
    },
    bookingLimits: {
      header: '{{name}} - Modifier les limites de réservation',
      rules: '#Règles',
      total: 'Total',
      rank: 'Priorité',
      customer: 'Client',
      customerNo: 'Numéro de client',
      serviceCode: 'Service',
      timeWindow: 'Plage horaire',
      selectTimeWindow: 'Sélectionner la plage horaire',
      noTimeWindow: 'Pas de plage horaire',
      noCustomer: 'Pas de client',
      bookingLimit: 'Limite de réservation',
      removeLimit: 'Supprimer la limite',
      addMoreLimits: 'Ajouter plus de limites',
      delete: 'Supprimer les limites de réservation',
      deleteModalDescription: 'Vous ne pourrez pas annuler cette action',
      deleted: 'Les limites de réservation ont été supprimées avec succès',
      updated: 'Les limites de réservation ont été modifiées avec succès'
    },
    master: 'Principal',
    select: 'Sélectionner la matrice {{matrix}}...',
    overflow: '+{{overflow}} de plus..',
    vasCodes: 'Codes VAS',
    connectedCustomers: {
      title: 'Clients connectés',
      button: 'Voir {{connectedCustomers}} clients connectés',
      search: 'Rechercher par nom ou numéro de client',
      subcustomer: 'Sous-client'
    },
    upload: {
      success: 'Les données de la matrice ont été téléchargées avec succès',
      question: 'Télécharger les données de la matrice',
      text: 'Télécharger Excel',
      description: 'Téléchargez la feuille de calcul Excel contenant les données pour cette matrice',
      error: 'Erreur lors du téléchargement des données sur {{name}}. {{error}}'
    },
    delete: {
      question: 'Supprimer la matrice {{name}}',
      description: 'Cela ne peut pas être annulé',
      success: 'La matrice {{name}} a été supprimée avec succès',
      error: 'Erreur lors de la suppression de {{name}}. {{error}}'
    },
    new: {
      question: 'Créer une nouvelle matrice',
      description: 'Crée une nouvelle matrice',
      success: 'La matrice {{name}} a été créée avec succès',
      error: 'Erreur lors de la création de la matrice. {{error}}'
    },
    edit: {
      question: 'Modifier la matrice {{name}}',
      error: 'Erreur lors de la mise à jour de {{name}}. {{error}}',
      success: 'La matrice {{name}} a été enregistrée avec succès'
    },
    type: 'Sélectionner le type',
    day: { '1': 'Lun', '2': 'Mar', '3': 'Mer', '4': 'Jeu', '5': 'Ven', '6': 'Sam', '7': 'Dim' },
    leadTime: 'Délai de livraison',
    terminal: 'Terminal',
    terminalFrom: 'Terminal de départ',
    terminalTo: "Terminal d'arrivée",
    postalCode: 'Code postal',
    postalCodeFrom: 'Code postal de départ',
    postalCodeTo: 'Code postal de destination',
    offsetDays: 'Jours de décalage',
    city: 'Ville',
    error: {
      duplicateName: 'Nom en double',
      duplicateServiceCode:
        'Les limites de réservation sont déjà définies pour cette combinaison de client et de code service',
      duplicateTimeWindow: 'Créneau horaire en double'
    }
  },
  serviceMapping: {
    title: 'Services',
    serviceName: 'Nom du service',
    pickupTime: 'Heure de collecte',
    deliveryTime: 'Heure de livraison',
    internalName: 'Nom interne',
    internalDescription: 'Description interne',
    externalName: 'Nom externe',
    externalDescription: 'Description externe',
    alystraId: 'ID Alystra',
    allowFlexDelivery: 'Autoriser la livraison flexible',
    timeWindow: 'Plage horaire',
    serviceMappingType: 'Type',
    service: 'Service',
    vas: 'VAS',
    stopsBeforeShowEta: 'Arrêts ETA',
    minutesUntilDelivery: 'ETA en minutes',
    sendAutomaticEtaSmsHeading: 'SMS ETA',
    createNew: 'Créer un nouveau',
    serviceAndVasCodes: 'Services et codes VAS',
    successfullyAdded: 'Le mappage de service a été ajouté avec succès à la liste',
    successfullyUpdated: 'Le mappage de service a été mis à jour avec succès',
    successfullyDeleted: 'Le mappage de service a été supprimé avec succès',
    editService: 'Modifier le service',
    createNewService: 'Créer un nouveau service',
    settingsHeading: 'Paramètres du service ou VAS',
    selectLevel: 'Sélectionner le niveau',
    standardLevel: 'Niveau standard',
    customerLevel: 'Niveau client',
    customerSpecific: 'Spécifique au client',
    selectServiceType: 'Sélectionner le type de service',
    vasToolTip: "Service à valeur ajoutée, par exemple, contrôle d'identité",
    customerNumber: 'Numéro client',
    pickupTimeInSeconds: 'Temps de collecte (en secondes)',
    pickupTimeSubsequentShipments: 'Temps de collecte pour les envois suivants (en secondes)',
    deliveryTimeInSeconds: 'Temps de livraison (en secondes)',
    deliveryTimeSubsequentShipments: 'Temps de livraison pour les envois suivants (en secondes)',
    pickupDurationSecondsApartment: 'Temps de collecte pour les appartements (en secondes)',
    deliveryDurationSecondsApartment: 'Temps de livraison pour les appartements (en secondes)',
    deliveryDurationApartmentHeading: 'Durée de livraison pour les appartements (actuellement uniquement pour H2)',
    timeWindowInMinutes: 'Plage horaire (en minutes)',
    stopsLeftHeading: "Quand souhaitez-vous que l'ETA exact soit affiché au destinataire ?",
    stopsLeftDescription: 'Si ce champ est laissé vide, la plage horaire sera affichée',
    amountOfStops: "Nombre d'arrêts",
    flexibleDelivery: 'Livraison flexible',
    flexibleDeliveryDescription: "Permet au destinataire de choisir de laisser le colis à l'extérieur de la porte",
    flexibleDeliveryCheckboxText: 'Oui, le destinataire doit être autorisé',
    linkTextToService: 'Lier le texte au service',
    linkTextToServiceDescription: "Si aucun texte n'est sélectionné, un texte par défaut sera utilisé",
    defaultText: 'Texte par défaut',
    listOfServicesAndVases: 'Liste des services et des codes VAS',
    searchService: 'Filtrer par service, VAS ou numéro client',
    customizedText: 'Texte personnalisé',
    deleteModalTitle: 'Supprimer le mappage de service',
    deleteModalDescription: 'Vous ne pourrez pas récupérer cette action',
    infoModal: {
      title: 'Ce qui est affiché où dans Glow',
      subHeading1: 'Planificateurs et conducteurs',
      description1: 'Nom interne : Le nom du service, par exemple "Livraison à domicile"',
      description2:
        'Description interne : Informations détaillées sur le service, par exemple "Cela doit être livré à la pièce choisie par le destinataire."',
      subHeading2: 'Clients et destinataires',
      description3: 'Nom externe : Le nom du service, par exemple "Livraison à domicile"',
      description4:
        'Description externe : Informations détaillées sur le service, par exemple "Vos produits seront livrés dans la pièce de votre choix".'
    },
    routeOptimizing: "Optimisation de l'itinéraire",
    routeOptimizingDescription:
      "Les informations que vous fournissez ici remplaceront les paramètres par défaut et affecteront l'optimisation de l'itinéraire. Le paramètre par défaut pour les collectes est de 15 secondes et pour les livraisons 180 secondes.",
    settingsRecipientTrackingPage: 'Page de suivi du destinataire',
    etaComponentHeading: "Afficher l'ETA exact sur la page du destinataire",
    etaComponentDescription:
      "Activer cette option vous permettra de décider quand afficher l'ETA exact au destinataire au lieu de la plage horaire de l'ETA.",
    displayExactEta: "Oui, afficher l'ETA exact",
    stopsLeftSelected: "Nombre d'arrêts restants avant la livraison",
    minutesLeftSelected: 'Nombre de minutes restantes avant la livraison',
    numberOfMinutes: 'Nombre de minutes',
    numberOfStops: "Nombre d'arrêts",
    sendAutomaticEtaSmsTitle: 'Envoyer un SMS automatique au destinataire',
    sendAutomaticEtaSmsDescription:
      "Activer cette option désactivera également la possibilité pour le conducteur d'envoyer un SMS depuis l'application du conducteur.",
    sendAutomaticEtaSms: 'Envoyer un SMS automatiquement',
    missingNumberOfStops:
      'Veuillez entrer un nombre valide d\'arrêts lorsque "Envoyer un SMS automatique" est sélectionné',
    missingNumberOfMinutes:
      'Veuillez entrer un nombre valide de minutes lorsque "Envoyer un SMS automatique" est sélectionné',
    minutesNotInRange: 'Veuillez définir le nombre de minutes entre {{from}} et {{to}}',
    serviceDeliveryType: 'Type de livraison de service',
    noServiceDeliveryTypeSelected: 'Non défini',
    serviceDeliveryTypes: { Delivery: 'Livraison', Return: 'Retour', PickupPoint: 'Point de collecte' },
    Delivery: 'Livraison',
    Return: 'Retour',
    PickupPoint: 'Point de collecte',
    courierApp: 'Application du coursier',
    allowCourierDescription: "Permet au coursier d'accepter ou de refuser les envois avec ce code de service",
    allowCourierCheckbox: 'Oui, le coursier doit être autorisé'
  },
  shipmentDetailsBilling: {
    finanicalStakeholder: 'Parties prenantes financières',
    item: 'Article',
    customerTotal: 'Total client',
    unitTotal: 'Total unitaire',
    orderTotal: 'Total de la commande',
    disconnectToOrginalOrder: "Déconnecter du numéro d'expédition d'origine"
  },
  upsalesRulesAdmin: {
    title: 'Règles de vente incitative',
    titleSingular: 'Règle de vente additionnelle',
    name: 'Nom',
    type: 'Type',
    searchPlaceholder: 'Rechercher des règles de ventes incitatives',
    settings: 'Paramètres',
    successfullyAdded: '{{name}} a été ajoutée avec succès à la liste',
    successfullyUpdated: '{{name}} a été mise à jour avec succès',
    createNewRule: 'Créer une nouvelle règle de ventes additionnelles',
    edit: 'Modifier',
    serviceUpgrade: 'Mise à niveau du service',
    shorterTimeWindow: 'Fenêtre de temps plus courte',
    ruleName: 'Nom de la règle de ventes additionnelles',
    serviceUpgradeDescription1: 'Le service sera mis à niveau de 3123 à 2870.',
    serviceUpgradeDescription2:
      'VAS 1381 sera automatiquement ajouté pour garantir que le chauffeur reçoive le bon paiement.',
    zipCodeRangeTitle: 'Code postal',
    zipCodeRangeDescription:
      "Sélectionnez les codes postaux que vous souhaitez inclure ou exclure dans la règle. Si vous devez retirer un code postal spécifique ou une plage d'une zone incluse, utilisez l'option d'exclusion pour affiner votre sélection.",
    fromZipCode: 'Code postal de départ',
    toZipCode: 'Code postal de destination',
    addRange: 'Ajouter une plage de codes postaux',
    addZipCode: 'Ajouter un code postal',
    noZipCodeRangesAdded: 'Vous devez fournir une plage de codes postaux',
    zipCodeRangeError: '"Code postal de départ" ne doit pas être supérieur à "Code postal de destination"',
    deleteModalTitle: 'Supprimer la règle de ventes additionnelles',
    deleteModalDescription: 'Vous ne pourrez pas annuler cette action',
    successfullyDeleted: 'La règle de ventes additionnelles a été supprimée avec succès',
    shorterTimeWindowDescription1: 'Une plage horaire plus courte est disponible pour 3332',
    windowOnlyInEvening: 'La fenêtre de temps plus courte doit être disponible uniquement en soirée',
    timeWindowOffer: 'Quelle fenêtre de temps doit être proposée au destinataire ?',
    oneHour: '1 heure',
    twoHours: '2 heures',
    threeHours: '3 heures',
    mustSelectTimeWindow: 'Vous devez indiquer la fenêtre de temps offerte au destinataire',
    include: 'Inclure',
    exclude: 'Exclure',
    zipCodeRangeType: 'Type',
    exactZipCode: 'Exact',
    zipCodeRange: 'Plage',
    zipCode: 'Code postal',
    mustSelectCountry: "Vous devez d'abord sélectionner un pays",
    includedZipCodes: 'Codes postaux inclus ({{count}})',
    excludedZipCodes: 'Codes postaux exclus ({{count}})',
    noZipCodesAddedYet: 'Aucun code postal ajouté pour le moment',
    noMatchOnZipCodeSearch: 'Aucun code postal trouvé. Essayez de filtrer avec une autre plage de codes postaux.',
    zipCodesFound: '{{count}} code postal trouvé',
    zipCodesFound_plural: '{{count}} codes postaux trouvés',
    filterOnDepartments: 'Filtrer par départements',
    UPSALES_RULE_COLLISION_OCCURRED:
      'La règle existe déjà! Veuillez supprimer soit les départements suivants: {{departments}}, soit les clients suivants: {{customers}}',
    status: 'Statut',
    active: 'Actif',
    paused: 'En pause',
    pauseRule: 'Mettre la règle en pause',
    activateRule: 'Activer la règle',
    activateRuleModalDescription:
      "Vous êtes sur le point d'activer la règle. Cela signifie que la vente additionnelle sera disponible pour les destinataires. Souhaitez-vous continuer?",
    pauseRuleModalDescription:
      'Vous êtes sur le point de mettre la règle en pause. Cela signifie que la vente additionnelle ne sera pas disponible pour les destinataires. Souhaitez-vous continuer?',
    successfullyActivated: 'La règle de vente additionnelle a été activée avec succès',
    successfullyPaused: 'La règle de vente additionnelle a été mise en pause avec succès'
  },
  billingOrder: {
    standaloneInvoice: 'Facture distincte',
    standaloneInvoiceLabel:
      "Je souhaite que cette commande de facture soit traitée individuellement et ne soit pas regroupée avec d'autres commandes pour la facturation.",
    noUnitNotSelfBilled: 'Les commandes sans unité ne seront pas compensées.'
  },
  welcomeToGlow: 'Bienvenue sur Glow',
  welcomeToGlowDescription: 'Utilisez la navigation à gauche pour commencer votre travail !',
  formValidation: {
    mustProvideName: 'Vous devez fournir un nom',
    mustProvideDescription: 'Vous devez fournir une description',
    mustProvideServiceCode: 'Vous devez fournir un code de service',
    mustProvideCalendarDays: 'Vous devez fournir des jours de calendrier',
    mustProvideCutOffTime: 'Vous devez fournir une heure limite',
    mustProvideCutOffMinutes: 'Vous devez fournir des minutes de coupure',
    mustProvideDeliveryAttempts: 'Vous devez fournir des tentatives de livraison',
    mustProvideCustomer: 'Vous devez fournir un client',
    mustProvideQuantity: 'Vous devez fournir une quantité',
    mustProvideArticle: 'Vous devez fournir un article',
    mustProvideDate: 'Vous devez fournir une date',
    mustProvideCustomerOrUnitAmount: 'Vous devez fournir un client ou un montant unitaire'
  },
  units: {
    title: 'Unités',
    new: 'Créer une nouvelle unité',
    connectFromOtherDepartments: "Connecter depuis d'autres services",
    connect: {
      title: 'Connecter des unités au service actuel',
      description: 'Vous ne pouvez rechercher des unités que dans les services auxquels vous avez accès.',
      selected: 'Unités sélectionnées',
      noneSelected: 'Aucune unité',
      submit: 'Connecter'
    },
    assigned: "L'unité (les unités) a (ont) été connectée(s) avec succès."
  }
}
export default fr
